import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '../../constants';
import { SettingsSecurity2faDisabled } from './settings-security-2fa-disabled';
import { SettingsSecurity2FaEnabled } from './settings-security-2fa-enabled';
import { SettingsSecurity2faOverview } from './settings-security-2fa-overview';

export const SettingsSecurity2faWizard: React.FC = () => {
  return (
    <StepWizard {...defaultStepWizardProps}>
      <SettingsSecurity2faOverview />
      <SettingsSecurity2FaEnabled />
      <SettingsSecurity2faDisabled />
    </StepWizard>
  );
};
