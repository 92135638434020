import { FC } from 'react';

import { Field, FieldProps } from './field';
import { DebounceInput, DebounceInputProps } from './debounce-input';
import { InputProps, getInputStyle } from 'common';

type Props = FieldProps &
  Partial<InputProps> &
  Omit<DebounceInputProps, 'onChange'> &
  Partial<Pick<HTMLInputElement, 'spellcheck'>>;

export const DebounceField: FC<Props> = ({
  label,
  name,
  validator,
  labelCls,
  ...rest
}) => {
  return (
    <Field
      name={name}
      label={label}
      validator={validator}
      labelCls={labelCls}
      {...rest}
    >
      {(field, meta, helpers) => {
        const errored = Boolean(meta.error && meta.touched);
        const cls = getInputStyle(errored);
        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          helpers.setValue(e.target.value);
          helpers.setTouched(true);
        };
        return (
          <DebounceInput
            errored={errored}
            className={cls}
            {...field}
            {...rest}
            onChange={handleOnChange}
          />
        );
      }}
    </Field>
  );
};
