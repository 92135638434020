import React, { FC, ReactNode } from 'react'
import { AppIcon, AppIconType, BaseComponentProps, useUrls } from 'common'
import { AppLogo } from '../app-logo'
import { AppFooter } from '~/components/app-footer'

type Props = {
	icon: AppIconType;
	title: string;
	cta?: ReactNode;
	withAppWrapper?: boolean
} & BaseComponentProps;

export const OpenLayout: FC<Props> = ({ icon, title, children, cta, withAppWrapper }) => {
	const { webAppUrl } = useUrls()

	if (withAppWrapper) {
		return (
			<div className="flex flex-col h-screen">
				<div className="flex-1">
					<OpenLayout icon={icon} title={title} children={children} cta={cta} />
				</div>
				<AppFooter />
			</div>
		)
	}

	return (
		<div className="h-screen flex-1 flex flex-col justify-center items-center">
			<div className="h-full w-full flex flex-col items-center text-secondary text-center">
				<div className="py-5 border-b w-full">
					<a href={webAppUrl} className="inline-flex"><AppLogo variant="dark" logoType="error" /></a>
				</div>
				<div className="h-full flex flex-col justify-center items-center w-484 gap-6">
					<AppIcon
						icon={icon}
						size={48}
						bg="bg-accent-active"
						cls="fill-current-color p-2 justify-center items-center"
					/>

					<div>
						<p className="text-xl font-bold">{title}</p>
						<p className="mt-1 text-grey-darker text-sm">{children}</p>
					</div>

					{cta && cta}
				</div>
			</div>
		</div>
	)
}
