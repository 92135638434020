import { API } from 'api';
import {
  EnrichedAssetHolding,
  EnrichedAssetHoldings,
  EnrichedCurrencyInformation,
} from '../../types/enriched';
import parseNumber from 'multi-number-parse';
import { DEFAULTS } from '../../constants';
import { formatters } from '../formatters';
import { formatAmount } from '../format-amount';

const listOfAccountTypes = Object.values(API.AccountType);
const listOfAccountTypesExcludingFund: API.AccountType[] =
  listOfAccountTypes.filter(t => t !== API.AccountType.Fund);
const mapOfAllowedAccountsForAssetType = {
  [API.CurrencyType.Digital]: listOfAccountTypesExcludingFund,
  [API.CurrencyType.Fiat]: listOfAccountTypesExcludingFund,
  [API.CurrencyType.Fund]: [API.AccountType.Fund],
};

export const enrichAssetHoldings = (
  assetHoldings: API.GetAssetHoldingsResponse | null,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[]
) => {
  if (!assetHoldings || !currencies) {
    return null;
  }

  const enrichedCcy = currencies.find(
    c => c.code === assetHoldings.currency?.code
  );
  if (!enrichedCcy) {
    return null;
  }

  const enrichedAccounts = (assetHoldings.accounts || [])
    ?.map(acc => {
      const balance = parseNumber(acc.quantity || 0);
      const value = enrichedCcy.fxRate.rate * balance;
      const result: EnrichedAssetHolding = {
        ...acc,
        isAccountOfTypeProTrading:
          acc.account?.accountType === API.AccountType.Trading ||
          acc.account?.accountType === API.AccountType.ProTrading,
        isAccountOfTypeVault:
          acc.account?.accountType === API.AccountType.Custody ||
          acc.account?.accountType === API.AccountType.Vault,
        isAccountOfTypeFund: acc.account?.accountType === API.AccountType.Fund,
        currency: enrichedCcy,
        balance,
        balanceUsd: parseNumber(acc.quantityUsd || 0),
        hasBalance: !!balance,
        canStabletagTransfer: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
        formatted: {
          earnedInterestThisWeekUsd: formatters.getCurrency(
            acc.earnedInterestThisWeekUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          earnedInterestTotalUsd: formatters.getCurrency(
            acc.earnedInterestTotalUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          balance: formatAmount(acc.quantity, enrichedCcy?.decimals),
          balanceWithCurrencyCode: formatters.getCurrency(
            acc.quantity,
            enrichedCcy?.decimals,
            enrichedCcy.displayCode ?? enrichedCcy.code,
            fiatCurrencyCodes
          ),
          balanceUsd: formatAmount(acc.quantityUsd, 2),
          balanceUsdWithCurrencyCode: formatters.getCurrency(
            acc.quantityUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          pendingBalance: formatAmount(
            acc.pendingQuantity,
            enrichedCcy?.decimals
          ),
          pendingBalanceUsd: formatAmount(
            acc.pendingQuantityUsd,
            enrichedCcy?.decimals
          ),
          balanceIncludingPending: formatters.getAmount(
            acc.quantityIncludingPending,
            enrichedCcy.decimals
          ),
          balanceIncludingPendingWithCurrencyCode: formatters.getCurrency(
            acc.quantityIncludingPending,
            enrichedCcy.decimals,
            enrichedCcy.displayCode ?? enrichedCcy.code,
            fiatCurrencyCodes
          ),
          balanceIncludingPendingUsdWithCurrencyCode: formatters.getCurrency(
            acc.quantityIncludingPendingUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          tradableQuantityUsd: formatAmount(acc.tradableQuantityUsd, 2),
          withdrawableQuantityUsd: formatAmount(acc.withdrawableQuantityUsd, 2),
          value: formatters.getCurrency(
            value,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          rateOfReturnPercentMTD: 0,
          rateOfReturnPercentYTD: 0,
          totalReturnPercent: 0,
          totalReturn: '0',
          mtdReturn: '0',
          ytdReturn: '0',
        },
      };
      return result;
    })
    .filter(acc => {
      const allowedList = mapOfAllowedAccountsForAssetType[enrichedCcy.type];
      return (
        acc.account?.accountType &&
        allowedList.includes(acc.account?.accountType)
      );
    });
  const _qty = parseNumber(assetHoldings.totalQty ?? '0');
  const _value = enrichedCcy.fxRate.rate * _qty;

  const enriched: EnrichedAssetHoldings = {
    hasBalance: _qty > 0,
    currency: enrichedCcy,
    accounts: enrichedAccounts,
    formatted: {
      totalQuantity: formatters.getCurrency(
        _qty,
        enrichedCcy.decimals,
        enrichedCcy.displayCode ?? enrichedCcy.code,
        fiatCurrencyCodes
      ),
      value: formatters.getCurrency(
        _value,
        DEFAULTS.DECIMAL_SCALE,
        'USD',
        fiatCurrencyCodes
      ),
    },
  };
  return enriched;
};
