import { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { BaseComponentProps, useComputedConfig } from 'common';
import { LayoutSplashWrapper } from './layout-splash-wrapper';

interface Props extends BaseComponentProps {
  title: string;
  onScrollEl?: (el: HTMLDivElement) => void;
}

export const ExternalSplashLayout: FC<Props> = ({
  title,
  children,
  onScrollEl,
}) => {
  /**
   * Vars
   */
  const scrollRef = useRef<HTMLDivElement>(null);
  const { tenant } = useComputedConfig();

  /**
   * Hooks
   */
  useEffect(() => {
    if (scrollRef.current && onScrollEl) {
      onScrollEl(scrollRef.current);
    }
  }, [scrollRef.current]);

  /**
   * DOM
   */
  return (
    <LayoutSplashWrapper>
      <div
        className={cx(
          'flex max-w-400 flex-col self-center w-full bg-white p-6 sm:p-9 rounded-md',
          {
            'border shadow-sm': tenant === 'Apex', //this could be better
          }
        )}
      >
        {/* header  */}
        <p className="text-center font-bold text-2xl md:text-1xl text-secondary">
          {title}
        </p>
        {/* content  */}
        <>{children}</>
      </div>
    </LayoutSplashWrapper>
  );
};
