import { FC } from 'react';
import { SettingsRewards } from '~/components/settings/rewards';
import { SettingsLayout, SettingsMenuIds } from './layout';

export const Rewards: FC = () => {
  /**
   * DOM
   */
  return (
    <SettingsLayout menuId={SettingsMenuIds.REWARDS}>
      <SettingsRewards />
    </SettingsLayout>
  );
};
