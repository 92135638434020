import { Note, useEnvironmentConfig, useUrls } from 'common';
import { FC } from 'react';
import { DataStore } from '../../store';
import { AppIcon } from 'common';
import { CopyableText } from '../copyable-text';
import { IconContent } from '../icon-content';

export const ReferralCode: FC = () => {
  /**
   * Hooks
   */
  const { registerUrl } = useUrls();
  const { REACT_APP_SHOW_WAITING_LIST } = useEnvironmentConfig();
  const showWaitlist = !!(REACT_APP_SHOW_WAITING_LIST === 'true');
  /**
   * Store
   */
  const kycStatus = DataStore.useStoreState(state => state.kyc.kycStatus);
  const currentKycTier = kycStatus?.currentKycTier;
  const isKycTier1 = currentKycTier === 1;
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const referralCode = decodedToken?.referralCode;

  /**
   * DOM
   */
  if (!referralCode || !isKycTier1) {
    return (
      <Note bgColor={'bg-white'} cls="py-4 pl-4 mt-4">
        <IconContent
          icon={
            <AppIcon
              icon="exclamation-circle"
              cls="fill-failure"
              size="sm"
              bg="bg-transparent"
            />
          }
          content={
            <div className="flex flex-col text-primary ml-4 typo-20">
              <span className="font-bold">Your identity is not verified.</span>
              <span className="font-normal">
                You are eligible to refer your friend after you are verified.
              </span>
            </div>
          }
        />
      </Note>
    );
  }
  return (
    <>
      <h5 className="text-gray-400 w-full text-left typo-b3">
        Share this link to refer your friend
      </h5>
      <div className="mt-2 flex flex-col w-full sm:flex-row gap-4 items-center">
        <input
          className="block flex-1 rounded-4 app-input font-bold text-center text-lg focus:ring-0 focus:border-black"
          style={{
            cursor: 'text',
            backgroundColor: 'white',
            textAlign: 'left',
            paddingLeft: 16,
          }}
          value={`${registerUrl}?ref=${referralCode}`}
          disabled
        />
        <CopyableText
          text={
            showWaitlist ? referralCode : `${registerUrl}?ref=${referralCode}`
          }
          showText={false}
          cls="inline-block"
          textCls="flex-col items-center font-bold"
          btnCls={'app-button-primary text-gray-400'}
          btnStyle={{ minHeight: 50 }}
          beforeCopyText="Copy"
        />
      </div>
    </>
  );
};
