import { useState, useEffect } from 'react';

export const useToast = (time: number) => {
  let timeout: any;
  const [isOn, setIsOn] = useState(false);

  const showToast = () => {
    setIsOn(true);
    timeout = setTimeout(() => {
      if (timeout) {
        setIsOn(false);
        clearTimeout(timeout);
      }
    }, time);
  };

  useEffect(() => {
    return () => {
      if (!timeout) {
        return;
      }
      clearTimeout(timeout);
    };
  }, [timeout]);

  return [isOn, showToast] as const;
};
