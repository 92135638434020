export enum FilterFormikField {
  filterInfo = 'filterInfo',
  filterDateRange = 'filterDateRange',
}

export enum DateFilterFormikField {
  filterStartDate = 'filterStartDate',
  filterEndDate = 'filterEndDate',
}

export interface FilterFormikProps {
  [FilterFormikField.filterDateRange]: string;
  [DateFilterFormikField.filterStartDate]: Date | null;
  [DateFilterFormikField.filterEndDate]: Date | null;
  [FilterFormikField.filterInfo]: string;
}
