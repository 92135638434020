import { API } from 'api';
import { EnrichedCurrencyInformation } from '../../types';

export function createSendDestination(
  data:
    | API.WithdrawalSimulation
    | API.Withdrawal
    | API.TransferSimulation
    | API.Transfer,
  currency: EnrichedCurrencyInformation
) {
  if ('destinationTag' in data) {
    return {
      address: data.destinationTag ?? 'UNKNOWN',
      label: 'Stablehouse user',
    };
  }

  return {
    address: data.withdrawalAddressId
      ? data.withdrawalAddress ?? 'UNKNOWN'
      : data.destinationAddress ?? 'UNKNOWN',
    label: data.withdrawalAddressId
      ? data.withdrawalAddressLabel ?? 'UNKNOWN'
      : `${currency.displayCode} address`,
  };
}
