import { API } from 'api';
import { FC } from 'react';
import { PayInvoiceSteps } from './steps';
import StepWizard from 'react-step-wizard';
import { PayInvoices } from './pay-invoices';
import { Enriched, EnrichedAccountDetailAsset, EnrichedInvoice } from 'common';
import { defaultStepWizardProps } from '~/constants';
import { PayInvoicesProps } from './list-of-invoices';
import { ConfirmInvoicesPayment } from './confirm-invoices-payment';
import { CompleteInvoicesPayment } from './complete-invoices-payment';

export interface PayInvoicesNavigationProps {
  error: string | null;
  onClose?: () => void;
  handlePreview: () => void;
  handlePdfExport: () => void;
  disableAssetSelection: boolean;
  accounts: Enriched.ListAccountItem[];
  invoiceToPay: EnrichedInvoice | null;
  onAssetChange: (value: any) => void;
  payInvoiceResult: EnrichedInvoice | null;
  payInvoiceOptions: PayInvoicesProps | null;
  account: API.AccountProperties | null | undefined;
  assetOfKind: EnrichedAccountDetailAsset[] | undefined;
  onAccountChange: (value: Enriched.ListAccountItem) => void;
}

export const PayInvoiceNavigation: FC<PayInvoicesNavigationProps> = ({
  ...rest
}) => (
  <div className="relative text-primary h-full">
    {/* wizard  */}
    <StepWizard {...defaultStepWizardProps} className="h-full">
      <PayInvoices {...rest} stepName={PayInvoiceSteps.PayInvoices} />
      <ConfirmInvoicesPayment
        {...rest}
        stepName={PayInvoiceSteps.ConfirmInvoicesPayment}
      />
      <CompleteInvoicesPayment
        {...rest}
        stepName={PayInvoiceSteps.CompleteInvoicesPayment}
      />
    </StepWizard>
  </div>
);
