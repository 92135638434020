import { FC, SVGProps, useMemo } from 'react';
import cx from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES, errorRoutes } from '../../routes';
import { DataStore } from '../../store/';
import { CopyableText } from '../copyable-text';
import { AppLogo } from '../app-logo';
import { Menu } from '@headlessui/react';
import {
  AppIcon,
  AppIconType,
  BaseComponentProps,
  dataTestIds,
  useComputedConfig,
  useUrls,
} from 'common';
import { useLogout } from '../../hooks/use-authentication-hooks';
import { WhitelabelBanner } from './whitelabel-banner';
import { useLeftNav } from '~/hooks/use-left-nav';

interface Props {
  splashHeader?: boolean;
}

const Username = () => {
  /**
   * Store
   */
  const username = DataStore.useStoreState(s => s.user.decodedToken?.username);
  const globalAppSettings = DataStore.useStoreState(
    s => s.settings.globalAppSettings
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * DOM
   */
  if (!username || !clientUserType) {
    return null;
  }
  return (
    <div className="text-sm text-right">
      <div className="block md:hidden gap-x-1 flex flex-row items-center">
        <AppIcon icon="user-placeholder" bg="" size={24} />
        <UserMenu
          asProfileMenu
          bgMask={null}
          showIcon={false}
          showDivider={false}
          menuLinkCls="py-4 px-4 hover:underline text-primary font-bold"
          menuIcon={
            <AppIcon
              size={24}
              bg="bg-transparent"
              icon="chevron-icon-drop-down"
              fill="fill-current-color-dark"
            />
          }
          menuIconOpened={
            <AppIcon
              size={24}
              bg="bg-transparent"
              icon="chevron-icon-drop-down"
              fill="fill-current-color-dark"
            />
          }
          menuContainerCls="right-0 origin-top-left rounded-none shadow-2xl border"
        />
      </div>
      <div className="hidden md:block">
        <>Welcome,</> <span className="font-bold">{username}</span>
        {globalAppSettings?.organizationIsCorporate && (
          <div className="font-bold">{globalAppSettings?.organizationName}</div>
        )}
        {clientUserType === 'fund-manager' && (
          <div className="font-bold">{globalAppSettings?.accountName}</div>
        )}
      </div>
    </div>
  );
};

type MenuAndMenuLinkProps = {
  showIcon?: boolean;
  menuLinkCls?: string;
};

type MenuLinkProps = {
  title: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  to?: string;
  target?: string;
} & MenuAndMenuLinkProps;

const MenuLink: FC<MenuLinkProps> = ({
  Icon,
  title,
  to,
  showIcon,
  menuLinkCls = 'w-full py-2.5 px-3.5 text-neutral-700 font-bold hover:bg-white hover:text-secondary hover-fill-black',
  ...rest
}) => {
  const content = (
    <>
      {showIcon && <Icon className="w-5 h-5 mr-3" />}
      {title}
    </>
  );
  /**
   * DOM
   */
  if (to) {
    return (
      <Link
        {...rest}
        to={to}
        className={cx('flex flex-row items-center w-full', menuLinkCls)}
      >
        {content}
      </Link>
    );
  }
  return (
    <button
      type="button"
      {...rest}
      className={cx('flex flex-row items-center w-full', menuLinkCls)}
    >
      {content}
    </button>
  );
};

type UserMenuProps = {
  bgMask?: JSX.Element | null;
  menuIcon?: JSX.Element;
  menuIconOpened?: JSX.Element;
  menuContainerCls?: string;
  showDivider?: boolean;
  asProfileMenu?: boolean;
  isLeftNav?: boolean;
  isLeftNavCollapsed?: boolean;
} & MenuAndMenuLinkProps;

type HeaderLogoProps = {
  variant?: 'light' | 'dark';
};

export const UserMenu: FC<UserMenuProps> = ({
  bgMask = (
    <div className="fixed w-full h-full inset-0 bg-black opacity-80 z-10" />
  ),
  menuIcon = (
    <AppIcon
      icon="hamburger-menu"
      size={15}
      cls="fill-current-color"
      bg="bg-transparent"
    />
  ),
  menuIconOpened = (
    <AppIcon
      icon="hamburger-menu-close"
      size={15}
      cls="fill-current-color"
      bg="bg-transparent"
    />
  ),
  menuContainerCls = 'right-0 origin-top-right rounded-md shadow-lg',
  showIcon = true,
  showDivider = true,
  menuLinkCls,
  asProfileMenu = false,
  isLeftNav = false,
  isLeftNavCollapsed = false,
}) => {
  /**
   * Store
   */
  const logout = useLogout();
  const tag = DataStore.useStoreState(s => s.settings.tag);
  const allowStabletag = DataStore.useStoreState(
    s => !!s.portfolio.accountDetail?.settings?.allowStabletag
  );
  const allowReferralRewards = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.allowReferralRewards
  );
  const kycTier = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.kycTier || 0
  );
  const hasAccountOfTypeCustody = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeCustody
  );
  const hasAccountOfTypeProTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeProTrading
  );
  const hasAccountOfTypeFund = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeFund
  );
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const isEmailVerified = DataStore.useStoreState(s => s.user.isEmailVerified);
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const username = DataStore.useStoreState(s => s.user.decodedToken?.username);

  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();
  const pathname = location?.pathname;

  const menuItems = useMemo(
    () =>
      [
        {
          id: 'portfolio',
          title: 'Portfolio',
          icon: 'institutions' as const,
          show: clientUserType !== 'admin',
          isActive: pathname.includes(APP_ROUTES.AUTH_PORTFOLIO),
          to: APP_ROUTES.AUTH_PORTFOLIO,
        },
        {
          id: 'markets',
          title: 'Markets',
          icon: 'graph-line' as const,
          show: clientUserType !== 'admin',
          isActive: pathname.includes(APP_ROUTES.AUTH_MARKETS),
          to: APP_ROUTES.AUTH_MARKETS,
        },
        {
          id: 'statements',
          title: 'Statements',
          icon: 'statement' as const,
          show:
            clientUserType !== 'admin' &&
            (hasAccountOfTypeCustody || hasAccountOfTypeProTrading || hasAccountOfTypeFund),
          isActive: pathname.includes(APP_ROUTES.AUTH_STATEMENTS),
          to: APP_ROUTES.AUTH_STATEMENTS,
        },
        {
          id: 'invoices',
          title: 'Invoices',
          icon: 'invoice' as const,
          show:
            clientUserType !== 'admin' &&
            (hasAccountOfTypeCustody || hasAccountOfTypeProTrading || hasAccountOfTypeFund),
          isActive: pathname.includes(APP_ROUTES.AUTH_INVOICES),
          to: APP_ROUTES.AUTH_INVOICES,
        },
        {
          id: 'settings',
          title: 'Settings',
          icon: 'settings' as const,
          show: true,
          isActive: pathname.includes(APP_ROUTES.AUTH_SETTINGS),
          to: APP_ROUTES.AUTH_SETTINGS,
        },
        {
          id: 'referral',
          title: 'Refer a friend',
          icon: 'friend-referral' as const,
          show:
            tenant === 'Stablehouse' &&
            clientUserType !== 'admin' &&
            allowReferralRewards,
          isActive: pathname.includes(APP_ROUTES.AUTH_REFER_A_FRIEND),
          to: APP_ROUTES.AUTH_REFER_A_FRIEND,
        },
        {
          id: 'approvals',
          title: 'Approvals',
          icon: 'approvals' as const,
          show: clientUserType === 'admin',
          isActive: pathname.includes(APP_ROUTES.AUTH_ADMIN_PENDING_APPROVALS),
          to: APP_ROUTES.AUTH_ADMIN_PENDING_APPROVALS,
        },
        {
          id: 'sign-out',
          title: 'Sign out',
          icon: 'sign-out' as const,
          'data-testid': 'sign-out-button',
          show: true,
          isActive: pathname.includes(APP_ROUTES.AUTH_ADMIN_PENDING_APPROVALS),
          onClick: () => logout(),
        },
      ].filter(it => it.show),
    [
      pathname,
      clientUserType,
      tenant,
      allowReferralRewards,
      hasAccountOfTypeCustody,
      hasAccountOfTypeProTrading,
    ]
  );

  /**
   * DOM
   */
  const showMenu = isAuthenticated && isEmailVerified;
  if (!showMenu) {
    return null;
  }
  const showStableTag = allowStabletag && kycTier !== 0;
  const dividerCls = showDivider ? 'divide-y divide-gray-100' : '';

  return (
    <div className="relative flex items-center -mb-0.5">
      <Menu>
        {({ open }: { open: boolean }) => (
          <>
            {open && <>{bgMask}</>}
            {!isLeftNav && (
              <Menu.Button
                className={cx('relative z-20', {
                  'text-accent': open,
                })}
                data-testid={
                  asProfileMenu ? 'user-placeholder' : 'header-hamburger-menu'
                }
              >
                {!open && <>{menuIcon}</>}
                {open && <>{menuIconOpened}</>}
              </Menu.Button>
            )}
            <Menu.Items
              className={cx(
                isLeftNav
                  ? 'w-full'
                  : 'absolute top-8 w-56 mt-3 bg-white outline-none overflow-hidden z-20 p-4',
                dividerCls,
                menuContainerCls
              )}
              static={isLeftNav}
            >
              {asProfileMenu && (
                <Menu.Item>
                  <div>
                    Welcome, <span className="font-bold">{username}</span>
                  </div>
                </Menu.Item>
              )}
              {!asProfileMenu && (
                <>
                  {menuItems.map(item => (
                    <Menu.Item key={item.id}>
                      <MenuLink
                        Icon={() =>
                          isLeftNav ? (
                            <LeftMenuIcon
                              icon={item.icon}
                              isActive={item.isActive}
                            />
                          ) : null
                        }
                        title={isLeftNavCollapsed ? '' : item.title}
                        data-testid={item['data-testid']}
                        to={item.to}
                        onClick={item.onClick}
                        showIcon={showIcon}
                        menuLinkCls={cx(
                          item.isActive
                            ? 'text-secondary bg-white'
                            : 'text-neutral-700',
                          menuLinkCls
                        )}
                      />
                    </Menu.Item>
                  ))}
                  {showStableTag && (
                    <Menu.Item disabled>
                      <CopyableText
                        text={tag}
                        showTextAfterCopy={false}
                        showCopyText={false}
                        cls="py-2.5 px-5 bg-gray-100 text-base"
                        textCls="text-gray-400 hover:text-primary"
                      />
                    </Menu.Item>
                  )}
                </>
              )}
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};

const LeftMenuIcon = ({
  isActive,
  icon,
}: {
  isActive?: boolean;
  icon: AppIconType;
}) => (
  <AppIcon
    icon={icon}
    bg="bg-transparent"
    size={20}
    cls={cx('mr-3', isActive ? 'text-secondary' : 'text-neutral-700')}
  />
);

const HeaderLogo: FC<HeaderLogoProps & Props> = ({
  variant = 'light',
  splashHeader,
}) => {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const location = useLocation();
  const { showLeftNav } = useLeftNav();
  const showBack = !splashHeader &&
    (
      location?.pathname !== APP_ROUTES.AUTH_404 &&
      location?.pathname !== APP_ROUTES.AUTH_ADMIN &&
      !location?.pathname.includes(`${APP_ROUTES.AUTH_PORTFOLIO}/overview`) &&
      location.key !== 'default'
    )

  /**
   * DOM
   */
  return (
    <div className="flex items-center gap-8">
      {(!showLeftNav || splashHeader) && (
        <div className="flex-shrink-0">
          <Link
            to={APP_ROUTES.AUTH_PORTFOLIO}
            className="focus:ring-transparent focus:outline-none"
          >
            <AppLogo variant={variant} logoType="header" />
          </Link>
        </div>
      )}
      {showBack && (
          <span
            onClick={() => {
              // mostly works, so going to keep this around for now
              const url =
                window.location.pathname !==
                window.location.href.split(window.location.origin)[1]
                  ? APP_ROUTES.AUTH_PORTFOLIO
                  : -1;
              navigate(url as APP_ROUTES);
            }}
            className="focus:ring-transparent focus:outline-none flex flex-row items-center cursor-pointer hidden sm:inline-flex gap-x-2.5"
          >
            <AppIcon
              size={12}
              bg="bg-transparent"
              icon="chevron-back"
              fill="fill-current-color-dark"
            />
            <span className="text-sm font-bold">Back</span>
          </span>
        )}
    </div>
  );
};

const Wrapper: FC<{ navCls: string } & BaseComponentProps> = ({
  navCls,
  children,
}) => {
  /**
   * DOM
   */
  return (
    <nav
      className={cx(navCls, 'border-b-1 border-bright-gray')}
      data-testid={dataTestIds.components.appHeader.root}
    >
      <div className="px-4 sm:px-6 py-5">
        <div className="flex flex-row items-center justify-between">
          {children}
        </div>
      </div>
    </nav>
  );
};

export const AppHeader: FC<Props> = props => {
  const isErrorRoute =
    location?.pathname && errorRoutes.includes(location.pathname as APP_ROUTES);

  if (!isErrorRoute) return <StablehouseHeader {...props} />;
  else return null;
};

export const StablehouseHeader: FC<Props> = ({ splashHeader = false }) => {
  /**
   * Store
   */
  const hasAccountOfTypeProTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeProTrading
  );
  const globalAppSettings = DataStore.useStoreState(
    s => s.settings.globalAppSettings
  );

  /**
   * Hooks
   */
  const { proTradingUrl } = useUrls();
  const { tenant } = useComputedConfig();

  const { showLeftNav } = useLeftNav();

  /**
   * DOM
   */
  return (
    <div className="sticky top-0 left-0 right-0 bg-white z-40">
      <WhitelabelBanner settings={globalAppSettings?.whiteLabelLiteSettings} />
      <Wrapper
        navCls={cx({
          'bg-grey-brighter text-primary': tenant !== 'Apex',
          'text-primary bg-white border-b shadow-md z-10': tenant === 'Apex',
        })}
      >
        <div className="flex flex-row gap-x-2 sm:gap-x-4 items-center">
          {!showLeftNav &&  !splashHeader && (
            <UserMenu
              showDivider={false}
              menuContainerCls="left-0 origin-top-left rounded-none shadow-2xl border"
              menuIcon={
                <AppIcon
                  icon="hamburger-menu"
                  size={24}
                  cls="fill-current-color hover-fill-accent"
                  bg="bg-transparent"
                />
              }
              menuIconOpened={
                <AppIcon
                  icon="hamburger-menu-close"
                  size={24}
                  cls="fill-current-color hover-fill-accent"
                  bg="bg-transparent"
                />
              }
              bgMask={null}
              showIcon={false}
              menuLinkCls={'py-4 px-4 hover:underline text-primary font-bold'}
            />
          )}
          <HeaderLogo variant="dark" splashHeader={splashHeader} />
        </div>
        <div className="flex flex-row items-center gap-3">
          <Username />
          {hasAccountOfTypeProTrading && !!proTradingUrl && (
            <a
              target="_blank"
              rel="noreferrer"
              href={proTradingUrl}
              className="app-button-primary text-xs button-md"
            >
              Active Trader
              <AppIcon
                cls="ml-1 sm:ml-2"
                icon="arrow-external"
                bg=""
                size={16}
              />
            </a>
          )}
        </div>
      </Wrapper>
    </div>
  );
};
