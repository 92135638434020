import { Action, action, Thunk, thunk } from 'easy-peasy';
import { API } from 'api';
import { Injections, AdditionalOptionsXHR } from './types';
import { DataModel } from './data-store';
import { getApiErrorMessage } from '../utils/get-api-error-message';

export interface KycModel {
  // state
  kycStatus: API.KycStatus | null;
  kycStatusLoading: boolean;
  kycBannerDismissed: boolean;
  kycError: string | null;
  lastSubmissionId: string | null;
  setLastSubmissionId: Action<KycModel, string | null>;
  address: API.Address | null;
  addressIsLoading: boolean;
  addressError: string | null;
  dateOfBirth: API.DateOfBirthResponse | null;
  dateOfBirthIsLoading: boolean;
  dateOfBirthError: string | null;
  widgetToken: string | null;
  widgetTokenIsLoading: boolean;
  widgetTokenError: string | null;
  submissionStatus: API.KycSubmission | null;
  submissionStatusIsLoading: boolean;
  submissionStatusError: string | null;
  kycData: API.KycDataResponse | null;
  kycDataIsLoading: boolean;
  kycDataError: string | null;
  completeKycTier1IsLoading: boolean;
  // action
  setKycStatus: Action<KycModel, API.KycStatus | null>;
  setKycStatusLoadingState: Action<KycModel, boolean>;
  setKycError: Action<KycModel, string | null>;
  setAddress: Action<KycModel, API.Address | null>;
  setAddressLoading: Action<KycModel, boolean>;
  setAddressError: Action<KycModel, string | null>;
  setDateOfBirth: Action<KycModel, API.DateOfBirthResponse | null>;
  setDateOfBirthLoading: Action<KycModel, boolean>;
  setDateOfBirthError: Action<KycModel, string | null>;
  setWidgetToken: Action<KycModel, string | null>;
  setWidgetTokenLoading: Action<KycModel, boolean>;
  setWidgetTokenError: Action<KycModel, string | null>;
  setSubmissionStatus: Action<KycModel, API.KycSubmission | null>;
  setSubmissionStatusLoading: Action<KycModel, boolean>;
  setSubmissionStatusError: Action<KycModel, string | null>;
  setKycData: Action<KycModel, API.KycDataResponse | null>;
  setKycDataLoading: Action<KycModel, boolean>;
  setKycBannerDismissed: Action<KycModel, boolean>;
  setKycDataError: Action<KycModel, string | null>;
  setCompleteKycTier1Loading: Action<KycModel, boolean>;
  // thunk
  getKycStatus: Thunk<
    KycModel,
    AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<API.KycStatus | null>
  >;
  clearKycStatus: Thunk<KycModel, undefined, Injections, DataModel>;
  getAddress: Thunk<KycModel, undefined, Injections, DataModel>;
  getDateOfBirth: Thunk<KycModel, undefined, Injections, DataModel>;
  saveAddress: Thunk<KycModel, API.Address, Injections, DataModel>;
  saveDateOfBirth: Thunk<KycModel, API.SetDateOfBirth, Injections, DataModel>;
  saveCountry: Thunk<KycModel, API.CountryCode, Injections, DataModel>;
  saveIsUsCitizen: Thunk<KycModel, boolean, Injections, DataModel>;
  getWidgetToken: Thunk<KycModel, undefined, Injections, DataModel>;
  getSubmissionStatus: Thunk<
    KycModel,
    string,
    Injections,
    DataModel,
    Promise<API.KycSubmission>
  >;
  saveSourceOfWealth: Thunk<
    KycModel,
    API.UpdateSourceOfWealthRequest,
    Injections,
    DataModel
  >;
  saveEmploymentStatus: Thunk<
    KycModel,
    API.UpdateEmploymentStatusRequest,
    Injections,
    DataModel
  >;
  saveIsPep: Thunk<KycModel, API.UpdateIsPep, Injections, DataModel>;
  saveWeeklyTradingVolume: Thunk<
    KycModel,
    API.UpdateWeeklyTradingVolume,
    Injections,
    DataModel
  >;
  saveWeeklyTradingFrequency: Thunk<
    KycModel,
    API.UpdateWeeklyTradingFrequency,
    Injections,
    DataModel
  >;
  saveEmployerInformation: Thunk<
    KycModel,
    API.SaveEmployerInformations,
    Injections,
    DataModel
  >;
  uploadProofOfAddress: Thunk<
    KycModel,
    API.FileParameter,
    Injections,
    DataModel
  >;
  getKycData: Thunk<KycModel, undefined, Injections, DataModel>;
  completeKycTier1: Thunk<KycModel, undefined, Injections, DataModel>;
  completeEnhancedDueDiligence: Thunk<
    KycModel,
    undefined,
    Injections,
    DataModel
  >;
}

export const kycModel: KycModel = {
  // state
  kycStatus: null,
  kycStatusLoading: false,
  kycBannerDismissed: false,
  kycError: null,
  address: null,
  addressIsLoading: false,
  addressError: null,
  dateOfBirth: null,
  dateOfBirthIsLoading: false,
  dateOfBirthError: null,

  widgetToken: null,
  widgetTokenIsLoading: false,
  widgetTokenError: null,

  submissionStatus: null,
  submissionStatusIsLoading: false,
  submissionStatusError: null,

  kycData: null,
  kycDataIsLoading: false,
  kycDataError: null,
  completeKycTier1IsLoading: false,
  lastSubmissionId: null,

  // action
  setKycStatus: action((state, payload) => {
    state.kycStatus = payload;
  }),
  setKycStatusLoadingState: action((state, payload) => {
    state.kycStatusLoading = payload;
  }),
  setKycError: action((state, payload) => {
    state.kycError = payload;
  }),
  setAddress: action((state, payload) => {
    state.address = payload;
  }),
  setAddressLoading: action((state, payload) => {
    state.addressIsLoading = payload;
  }),
  setAddressError: action((state, payload) => {
    state.addressError = payload;
  }),
  setDateOfBirth: action((state, payload) => {
    state.dateOfBirth = payload;
  }),
  setDateOfBirthLoading: action((state, payload) => {
    state.dateOfBirthIsLoading = payload;
  }),
  setDateOfBirthError: action((state, payload) => {
    state.dateOfBirthError = payload;
  }),
  setWidgetToken: action((state, payload) => {
    state.widgetToken = payload;
  }),
  setWidgetTokenLoading: action((state, payload) => {
    state.widgetTokenIsLoading = payload;
  }),
  setWidgetTokenError: action((state, payload) => {
    state.widgetTokenError = payload;
  }),
  setSubmissionStatus: action((state, payload) => {
    state.submissionStatus = payload;
  }),
  setSubmissionStatusLoading: action((state, payload) => {
    state.submissionStatusIsLoading = payload;
  }),
  setSubmissionStatusError: action((state, payload) => {
    state.submissionStatusError = payload;
  }),
  setKycData: action((state, payload) => {
    state.kycData = payload;
  }),
  setKycDataLoading: action((state, payload) => {
    state.kycDataIsLoading = payload;
  }),
  setKycBannerDismissed: action((state, payload) => {
    state.kycBannerDismissed = payload;
  }),
  setKycDataError: action((state, payload) => {
    state.kycDataError = payload;
  }),
  setCompleteKycTier1Loading: action((state, loading) => {
    state.completeKycTier1IsLoading = loading;
  }),
  setLastSubmissionId: action((state, payload) => {
    state.lastSubmissionId = payload;
  }),
  // thunk
  getKycStatus: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      if (!payload.isBackgroundXHR) {
        storeActions.setBusy(true);
      }
      storeActions.setError(null);
      try {
        const { result: status } = await injections.apiClient.getKycStatus();
        actions.setKycStatus(status!);
        return status;
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        storeActions.setError(errorMessage);
        if (payload.throwOnError) {
          throw error;
        }
      } finally {
        storeActions.setBusy(false);
      }
      return null;
    }
  ),
  clearKycStatus: thunk(async actions => {
    actions.setKycStatus(null);
  }),
  getAddress: thunk(async (actions, _payload, { injections }) => {
    actions.setAddressLoading(true);
    actions.setAddressError(null);
    try {
      const status = await injections.apiClient
        .getAddress()
        .then(i => i.result!);
      actions.setAddress(status);
      return status;
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      actions.setAddressError(errorMessage);
      throw error;
    } finally {
      actions.setAddressLoading(false);
    }
  }),
  getDateOfBirth: thunk(async (actions, _payload, { injections }) => {
    actions.setDateOfBirthLoading(true);
    actions.setDateOfBirthError(null);
    try {
      const status = await injections.apiClient
        .getDateOfBirth()
        .then(i => i.result!);
      actions.setDateOfBirth(status);
      return status;
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      actions.setDateOfBirthError(errorMessage);
      throw error;
    } finally {
      actions.setDateOfBirthLoading(false);
    }
  }),
  saveAddress: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveAddress(payload);
  }),
  saveDateOfBirth: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveDateOfBirth(payload);
  }),
  saveCountry: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveCountry(payload);
  }),
  saveIsUsCitizen: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveIsUsCitizen({
      isUsCitizen: payload,
    });
  }),

  getWidgetToken: thunk(async (actions, _payload, { injections }) => {
    actions.setWidgetTokenLoading(true);
    actions.setWidgetTokenError(null);
    try {
      const status = await injections.apiClient
        .getOnfidoWidgetToken()
        .then(i => i.result!.token!);
      actions.setWidgetToken(status);
      return status;
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      actions.setWidgetTokenError(errorMessage);
      throw error;
    } finally {
      actions.setWidgetTokenLoading(false);
    }
  }),

  getSubmissionStatus: thunk(async (actions, payload, { injections }) => {
    actions.setSubmissionStatusLoading(true);
    actions.setSubmissionStatusError(null);
    try {
      const response = await injections.apiClient.getSubmissionStatus(payload);
      const status = response.result!;
      actions.setSubmissionStatus(status);
      return status;
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      actions.setSubmissionStatusError(errorMessage);
      throw error;
    } finally {
      actions.setSubmissionStatusLoading(false);
    }
  }),
  saveSourceOfWealth: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveSourceOfWealth(payload);
  }),
  saveEmploymentStatus: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveEmploymentStatus(payload);
  }),
  saveIsPep: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveIsPep(payload);
  }),
  saveWeeklyTradingVolume: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveWeeklyTradingVolume(payload);
  }),
  saveWeeklyTradingFrequency: thunk(
    async (_actions, payload, { injections }) => {
      await injections.apiClient.saveWeeklyTradingFrequency(payload);
    }
  ),
  saveEmployerInformation: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.saveEmployerInformation(payload);
  }),
  uploadProofOfAddress: thunk(async (_actions, payload, { injections }) => {
    await injections.apiClient.uploadProofOfAddress(payload);
  }),
  getKycData: thunk(async (actions, _payload, { injections }) => {
    actions.setKycDataLoading(true);
    actions.setKycDataError(null);
    try {
      const response = await injections.apiClient.getKycData();
      const status = response.result!;
      actions.setKycData(status);
      return status;
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      actions.setKycDataError(errorMessage);
      throw error;
    } finally {
      actions.setKycDataLoading(false);
    }
  }),
  completeKycTier1: thunk(async (actions, _payload, { injections }) => {
    try {
      actions.setCompleteKycTier1Loading(true);
      const response = await injections.apiClient.completeKycTier1();
      const submissionId = response.result!.submissionId;
      actions.setLastSubmissionId(submissionId);
      actions.getSubmissionStatus(submissionId);
    } finally {
      actions.setCompleteKycTier1Loading(false);
    }
  }),
  completeEnhancedDueDiligence: thunk(
    async (_actions, _payload, { injections }) => {
      await injections.apiClient.completeEnhancedDueDiligence();
    }
  ),
};
