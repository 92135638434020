import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormFooter } from '~/components/workflows/shared/form-footer';
import { AppIcon, useEnvironmentConfig } from 'common';
import { Auth0 } from '~/utils/auth0';
import { OnboardingLayout } from '~/components/layouts/onboarding';
import { APP_ROUTES } from '~/routes';

export const LoggedOut: FC = () => {
  /**
   * Hooks
   */
  const { REACT_APP_AUTH0_AUDIENCE } = useEnvironmentConfig();

  /**
   * DOM
   */
  return (
    <OnboardingLayout testid="login-2fa-page" userIcon={false}>
      <div className="max-w-3xl mx-auto bg-white rounded-4 text-primary min-h-350 flex flex-col justify-between pt-10">
        <div className="px-10">
          {/* content  */}
          <div className="flex flex-col items-center py-8 pt-0">
            <AppIcon icon="circle-shield-check" size={48} />
            <h4 className="flex mt-1 text-xl items-center mb-3">
              <span className="font-bold mt-5">You have been logged out.</span>
            </h4>
          </div>
        </div>
        <FormFooter
          onSubmit={async () => {
            await Auth0.login(REACT_APP_AUTH0_AUDIENCE!);
          }}
          isSubmitDisabled={false}
          submitText="Sign in"
          submitCls="app-button-accent"
        />
      </div>
      <Link to={APP_ROUTES.NON_AUTH_HOME} className="text-white mt-8">
        Are you having trouble logging in?{' '}
        <span className="underline">Contact support</span>
      </Link>
    </OnboardingLayout>
  );
};
