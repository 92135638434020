import React from 'react';
import { AppLoader } from '../app-loader';
import cx from 'classnames';

interface KycWizardLayoutProps {
  title?: string;
  loading?: boolean;
  loadingText?: string;
  error?: string | null;
  children?: React.ReactNode;
  preContent?: React.ReactNode;
  noMobilePadding?: boolean;
}

export const KycWizardLayout: React.FC<KycWizardLayoutProps> = ({
  title,
  error,
  loading,
  loadingText,
  children,
  preContent,
  noMobilePadding,
}: KycWizardLayoutProps) => {
  return (
    <div
      className={cx(
        'bg-white rounded-4 w-full text-primary flex flex-col items-center justify-center md:w-45 min-h-450',
        {
          ['p-5 md:p-10 pt-12']: !noMobilePadding,
          ['p-0 md:p-4  md:pt-12']: noMobilePadding,
        }
      )}
    >
      {/* busy  */}
      {loading && <AppLoader />}

      {preContent}

      {/* title  */}
      {title && (
        <h3 className="mb-6 mx-auto text-center text-3xl font-bold">{title}</h3>
      )}

      {/* content  */}
      <div className="mx-auto w-full md:w-37.5">
        {loading && (
          <div data-testid="loading" className="w-full text-center">
            {loadingText || 'Loading...'}
          </div>
        )}
        {error && <div className="text-red-600 mb-4 text-center">{error}</div>}
        {!loading && children}
      </div>
    </div>
  );
};
