import { FC } from 'react';
import { StatusNote } from 'common';
import { DataStore } from '../../store';
import { API } from 'api';

export const SecurityVerificationDetails: FC = () => {
  /**
   * Store
   */
  const kycStatus = DataStore.useStoreState(state => state.kyc.kycStatus);
  const isKycVerified = DataStore.useStoreState(s => s.user.isKycVerified);
  const isUserKycVerifying =
    kycStatus?.kycState === API.KycState.ManualReviewInProgress ||
    kycStatus?.kycState === API.KycState.WaitingForOnfido;

  /**
   * DOM
   */
  return (
    <div className="text-base">
      {/* content  */}
      {isKycVerified ? (
        <StatusNote level={'success'} className="font-semibold">
          Verified!
        </StatusNote>
      ) : isUserKycVerifying ? (
        <StatusNote level={'warning'} className="font-semibold">
          Your identity is being verified
        </StatusNote>
      ) : (
        <StatusNote level={'failure'} className="font-semibold">
          Your identity is not verified
        </StatusNote>
      )}
    </div>
  );
};
