import { API } from 'api';
import { getCurrencyInfoForFund } from './get-currency-info-for-fund';

export const isDigitalFund = (
  fund: API.SingleFund | API.FiatFund | null | undefined,
  currencies: API.CurrencyInformation[] | null
) => {
  if (!currencies || !fund) {
    return false;
  }
  const ccyInfo = getCurrencyInfoForFund(fund, currencies);
  return !!(ccyInfo?.type === API.CurrencyType.Digital);
};
