import { FC } from 'react'
import { Link } from 'react-router-dom'

import { useFooterLinks } from '~/hooks/use-footer-links'
import cx from 'classnames'

export const AuthFooter: FC<{ cls?: string }> = ({ cls }) => {
	const footerLinks = useFooterLinks()

	return (
		<div
			data-testid="app-footer"
			className={cx('border-t py-6 px-10 bg-grey-brighter', cls)}
		>
			<div
				className="flex flex-row justify-center md:justify-end flex-grow gap-8 items-center flex-wrap md:flex-nowrap">
				{footerLinks.map(link => (
					<Link
						key={link.id}
						to={link.to || ''}
						target={link.to ? '_blank' : undefined}
						onClick={link.onClick}
						className="text-sm hover:text-figma-secondary text-grey-darker"
					>
						{link.label}
					</Link>
				))}
			</div>
		</div>
	)
}
