import { FC } from 'react'

import { useComputedConfig } from 'common'
import { AuthFooter } from '~/components/app-footer/auth'
import { StablehouseFooter } from '~/components/app-footer/stablehouse-footer'
import { ApexFooter } from '~/components/app-footer/apex-footer'
import { useLeftNav } from '~/hooks/use-left-nav'

export const AppFooter: FC<{ cls?: string }> = ({ cls }) => {
	const { tenant } = useComputedConfig()
	const { routesWithNav } = useLeftNav()

	if (routesWithNav) {
		return <AuthFooter cls={cls}/>
	}

	if (tenant === 'Apex') return <ApexFooter />
	return <StablehouseFooter />
}
