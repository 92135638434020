import { API } from 'api';
import { QuorumOperationModalKind, AppIcon, useAppDialog } from 'common';
import { FC, useEffect, useState, useCallback } from 'react';
import { ApprovalConfirmationModal } from '../../../components/approval-confirmation-modal';
import { BankAccountList } from '../../../components/bank-account-list';
import { AddBankAccount } from '../../../components/bank-accounts/add';
import { DeleteBankAccount } from '../../../components/bank-accounts/delete';
import { ShowBankAccount } from '../../../components/bank-accounts/show';
import { DataStore } from '../../../store';
import { SummaryApproval } from '../../../components/dialogs/summary-approval';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_ROUTES } from '../../../routes';
import { AppLoader } from '~/components/app-loader';

export const AdminFundBankDetails: FC = () => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const bankAccounts = DataStore.useStoreState(s => s.settings.bankAccounts);
  const getBankAccounts = DataStore.useStoreActions(
    a => a.settings.getBankAccounts
  );
  const setError = DataStore.useStoreActions(a => a.setError);
  const error = DataStore.useStoreState(s => s.error);
  const globalAppSettings = DataStore.useStoreState(
    a => a.settings.globalAppSettings
  );
  const clientUserType = DataStore.useStoreState(a => a.user.clientUserType);
  const addHeader = DataStore.useStoreActions(a => a.addHeader);

  /**
   *  State
   */
  const [toVerifyBankId, setToVerifyBankId] = useState<string | null>(null);
  const [toShowAccountId, setToShowAccountId] = useState<string | null>(null);

  /**
   * Hooks
   */
  const { showDialog, hideDialog } = useAppDialog();
  const navigate = useNavigate();
  const { fundId } = useParams();

  useEffect(() => {
    if (!toVerifyBankId) {
      if (fundId) {
        addHeader({
          'x-impersonated-account-id': fundId,
        });
      }
      getBankAccounts();
    }
  }, [fundId, toVerifyBankId]);

  /**
   * Methods
   */
  const toShowAccount = (bankAccounts || []).find(
    bank => bank.id === toShowAccountId
  );

  const closeAddConfirmationModal = useCallback(() => {
    hideDialog();
    navigate(APP_ROUTES.AUTH_ADMIN + '/pending-approvals');
  }, [hideDialog, history]);

  const closeAddAccountModal = useCallback(
    (bank: API.CreateQuorumOperationApiResponse | null) => {
      hideDialog();
      if (bank) {
        showDialog(
          <ApprovalConfirmationModal
            createBankAccountAddressData={toShowAccount}
            kind={QuorumOperationModalKind.AddBankAccount}
            onClose={closeAddConfirmationModal}
          />
        );
        setToVerifyBankId(bank?.operationId);
      }
    },
    [setToVerifyBankId, toShowAccount, showDialog, hideDialog]
  );

  const closeDeleteModal = useCallback(() => {
    hideDialog();
    if (error) setError(null);
  }, [hideDialog, showDialog, error, setError]);

  const onDeleteBank = useCallback(() => {
    showDialog(<SummaryApproval onClose={hideDialog} />);
    if (error) setError(null);
  }, [hideDialog, showDialog, error, setError]);

  const closeBankAccountModal = useCallback(
    bankId => {
      showDialog(
        <DeleteBankAccount
          bankId={bankId as string}
          onClose={closeDeleteModal}
          onDelete={onDeleteBank}
        />
      );
    },
    [showDialog, closeDeleteModal, onDeleteBank]
  );

  const getBankAccount = useCallback(
    bankId => (bankAccounts || []).find(bank => bank.id === bankId),
    [bankAccounts]
  );

  /**
   * DOM
   */
  if (busy) {
    return <AppLoader isFixed spinnerTop="104px" />;
  }

  return (
    <div className="rounded-4 bg-white flex-1">
      <div className="flex flex-row border border-b-0 rounded-t justify-between items-center py-5 px-4 md:px-8">
        <p className="font-bold text-primary">Current bank accounts</p>
        {clientUserType !== 'admin' ||
          (globalAppSettings?.userType ===
            API.UserType.ClientAdminReadWrite && (
            <button
              className="app-button-outline text-xs sm:text-sm"
              onClick={() =>
                showDialog(
                  <AddBankAccount
                    isClientAdmin
                    onClose={closeAddAccountModal}
                  />
                )
              }
            >
              <span className="hidden sm:inline">Add a bank account</span>
              <span className="sm:hidden">Add new</span>{' '}
              <AppIcon cls="ml-2" icon="plus" bg="" size={18} />
            </button>
          ))}
      </div>
      <BankAccountList
        accounts={bankAccounts}
        onShow={bankId => {
          setToShowAccountId(bankId);
          showDialog(
            <ShowBankAccount
              account={getBankAccount(bankId)}
              onClose={closeDeleteModal}
              onDelete={closeBankAccountModal}
              onVerify={() => undefined}
            />
          );
        }}
        onVerify={bankId => setToVerifyBankId(bankId)}
      />
    </div>
  );
};
