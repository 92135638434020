export enum CurrencyIconKey {
  // digital
  ADA = 'ADA',
  BTC = 'BTC',
  ETH = 'ETH',
  EURC_ERC20 = 'EURC_ERC20',
  EURC_POLYGON = 'EURC_POLYGON',
  EURR_ERC20 = 'EURR_ERC20',
  USDC = 'USDC',
  USDC_POLYGON = 'USDC_POLYGON',
  USDC_POLYGON_NXTB = 'USDC_POLYGON_NXTB',
  USDT = 'USDT',
  TGBP = 'TGBP',
  GUSD = 'GUSD',
  DAI = 'DAI',
  BNB = 'BNB',
  MATIC = 'MATIC',
  MATIC_ERC20 = 'MATIC_ERC20',
  POL_ERC20 = 'POL_ERC20',
  UNI = 'UNI',
  FTT = 'FTT',
  CRO = 'CRO',
  MANA = 'MANA',
  LINK = 'LINK',
  SNX = 'SNX',
  CRV = 'CRV',
  SUSHI = 'SUSHI',
  RENBTC = 'RENBTC',
  BUSD = 'BUSD',
  USDP = 'USDP',
  EURS = 'EURS',
  SAUD = 'SAUD',
  SEUR = 'SEUR',
  SUSD = 'SUSD',
  XAUT = 'XAUT',
  WBTC = 'WBTC',
  AAVE = 'AAVE',
  YFI = 'YFI',
  COMP = 'COMP',
  DYDX = 'DYDX_ERC20',
  AXS = 'AXS_ERC20',
  ENJ = 'ENJ',
  GNO = 'GNO',
  LRC = 'LRC',
  BAT = 'BAT',
  BNT = 'BNT',
  ZRX = 'ZRX',
  ONEINCH = '1INCH',
  // ?
  QCAD = 'QCAD',
  SCHF = 'SCHF',
  SJPY = 'SJPY',
  TUSD = 'TUSD',
  LBTC = 'LBTC',
  LUSDT = 'LUSDT',
  SAI = 'SAI',
  USDS = 'USDS',
  USDTE = 'USDTE',
  SCAD = 'SCAD',
  XLM = 'XLM',
  HUSD = 'HUSD',
  PAXG = 'PAXG',
  XAU = 'XAU',
  EURT = 'EURT',
  BNB_ERC20 = 'BNB_ERC20',
  PYTH_SPL = 'PYTH_SPL',
  SOL = 'SOL',
  BONK_SPL = 'BONK_SPL',
  WUSDM_ERC20 = 'WUSDM_ERC20',
  SUSDE_ERC20 = 'SUSDE_ERC20',
  SHIB_ERC20 = 'SHIB_ERC20',
  LDO_ERC20 = 'LDO_ERC20',
  RNDR_ERC20 = 'RNDR_ERC20',
  RENDER_SOL = 'RENDER_SOL',
  ENA_ERC20 = 'ENA_ERC20',

  // fiat
  EUR = 'EUR',
  JPY = 'JPY',
  USD = 'USD',
  GBP = 'GBP',
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  BMD = 'BMD',
  HKD = 'HKD',

  // bond tokens
  BRMD_ = 'BRMD_.*',
  AGRO_ = 'AGRO_.*',

  // fallback
  FALLBACK = 'FALLBACK',
}

export interface CurrencyIconProps {
  currencyCode: string | null | undefined;
  size?: number;
  showLabel?: boolean;
  labelTextSize?: string;
  labelCls?: string;
  currencyName?: JSX.Element | string | null;
  apy?: number;
}
