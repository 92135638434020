import { FC, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '../../constants';
import { useLogout } from '../../hooks/use-authentication-hooks';
import { DataStore } from '../../store';
import { RegisterStep1AccountType } from './1-account-type';
import { RegisterStep2UserForm } from './2-user-form';

export const Register: FC = () => {
  /**
   * Store
   */
  const logout = useLogout();
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);

  useEffect(() => {
    // log out the current user if already logged in.
    if (decodedToken) {
      logout();
    }
  }, []);

  /**
   * DOM
   */
  return (
    <StepWizard {...defaultStepWizardProps}>
      <RegisterStep1AccountType />
      <RegisterStep2UserForm />
    </StepWizard>
  );
};
