import { API } from 'api';
import parseNumber from 'multi-number-parse';
import { EnrichedFxRate } from '../types';
import { getPercentageChange } from './get-percentage-change';
import { DEFAULTS } from '../constants';
import { formatters } from './formatters';
import { formatAmount } from './format-amount';

function formatPercentage(value: number) {
  return `${value < 0 ? '-' : '+'}${formatAmount(Math.abs(value), 1)}%`;
}

export const getFxRate = (
  ccyCode: string,
  fxRates: API.FxRate[] | null,
  decimals: number = DEFAULTS.DECIMAL_SCALE
): EnrichedFxRate => {
  const getFiatFormattedValue = (
    amount: string | number | null,
    currencyDecimals?: number
  ) =>
    formatters.getCurrency(amount, currencyDecimals || decimals, 'USD', [
      'USD',
    ]);

  const fallbackResult: EnrichedFxRate = {
    rate: 1,
    rateFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rateWithCurrencyCodeFormatted: getFiatFormattedValue(
      1,
      DEFAULTS.DECIMAL_SCALE
    ),

    rate24HoursAgo: 1,
    rate24HoursAgoFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rate24HoursAgoFormattedUsd: getFiatFormattedValue(
      1,
      DEFAULTS.DECIMAL_SCALE
    ),
    rate24HoursAgoValueChange: 0,
    rate24HoursAgoValueChangeFormatted: `+${formatAmount(0, decimals)}`,
    rate24HoursAgoValueChangeFormattedUsd: `+${getFiatFormattedValue(0)}`,
    rate24HoursAgoPercentageChange: getPercentageChange(1, 1),
    rate24HoursAgoPercentageChangeFormatted: formatPercentage(0),

    rate7DaysAgo: 1,
    rate7DaysAgoFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rate7DaysAgoFormattedUsd: getFiatFormattedValue(1, DEFAULTS.DECIMAL_SCALE),
    rate7DaysAgoValueChange: 0,
    rate7DaysAgoPercentageChange: getPercentageChange(1, 1),

    rate1MonthAgo: 1,
    rate1MonthAgoFormatted: formatAmount(1, DEFAULTS.DECIMAL_SCALE),
    rate1MonthAgoFormattedUsd: getFiatFormattedValue(1, DEFAULTS.DECIMAL_SCALE),
    rate1MonthAgoValueChange: 0,
    rate1MonthAgoPercentageChange: getPercentageChange(1, 1),
  };

  if (!fxRates || !ccyCode) {
    return fallbackResult;
  }

  if (ccyCode === 'USD') {
    return fallbackResult;
  }

  const match = fxRates.find(({ base, quote }) => {
    return base === ccyCode || quote === ccyCode;
  });

  if (!match) {
    return fallbackResult;
  }

  try {
    if (match.base !== ccyCode) {
      const rate = 1 / parseNumber(match.rate || 1);
      const rate24HoursAgo = match.rate24HoursAgo
        ? 1 / parseNumber(match.rate24HoursAgo)
        : null;
      const rate7DaysAgo = match.rate7DaysAgo
        ? 1 / parseNumber(match.rate7DaysAgo)
        : null;
      const rate1MonthAgo = match.rate1MonthAgo
        ? 1 / parseNumber(match.rate1MonthAgo)
        : null;

      const rate24HoursAgoPercentageChange = rate24HoursAgo
        ? getPercentageChange(rate24HoursAgo, rate)
        : null;

      const rate24HoursAgoValueChange = rate24HoursAgo
        ? rate - rate24HoursAgo
        : null;
      const rate24HoursAgoValueChangeSign =
        (rate24HoursAgoValueChange ?? 0) < 0 ? '-' : '+';

      return {
        rate,
        rateFormatted: formatAmount(rate, decimals),
        rateWithCurrencyCodeFormatted: getFiatFormattedValue(rate, decimals),

        rate24HoursAgo,
        rate1MonthAgoFormatted: formatAmount(rate24HoursAgo, decimals),
        rate1MonthAgoFormattedUsd: getFiatFormattedValue(rate24HoursAgo),
        rate24HoursAgoValueChange,
        rate24HoursAgoValueChangeFormatted: `${rate24HoursAgoValueChangeSign}${formatAmount(
          Math.abs(rate24HoursAgoValueChange ?? 0),
          decimals
        )}`,
        rate24HoursAgoValueChangeFormattedUsd: `${rate24HoursAgoValueChangeSign}${getFiatFormattedValue(
          Math.abs(rate24HoursAgoValueChange ?? 0)
        )}`,
        rate24HoursAgoPercentageChange,
        rate24HoursAgoPercentageChangeFormatted: formatPercentage(
          rate24HoursAgoPercentageChange ?? 0
        ),
        rate7DaysAgo,
        rate7DaysAgoFormatted: formatAmount(rate7DaysAgo, decimals),
        rate7DaysAgoFormattedUsd: getFiatFormattedValue(rate7DaysAgo),
        rate7DaysAgoValueChange: rate7DaysAgo ? rate - rate7DaysAgo : null,
        rate7DaysAgoPercentageChange: rate7DaysAgo
          ? getPercentageChange(rate7DaysAgo, rate)
          : null,

        rate1MonthAgo,
        rate24HoursAgoFormatted: formatAmount(rate1MonthAgo, decimals),
        rate24HoursAgoFormattedUsd: getFiatFormattedValue(rate1MonthAgo),
        rate1MonthAgoValueChange: rate1MonthAgo ? rate - rate1MonthAgo : null,
        rate1MonthAgoPercentageChange: rate1MonthAgo
          ? getPercentageChange(rate1MonthAgo, rate)
          : null,
      };
    }

    const rate = parseNumber(match.rate || 1);
    const rate24HoursAgo = match.rate24HoursAgo
      ? parseNumber(match.rate24HoursAgo)
      : null;
    const rate7DaysAgo = match.rate7DaysAgo
      ? parseNumber(match.rate7DaysAgo)
      : null;
    const rate1MonthAgo = match.rate1MonthAgo
      ? parseNumber(match.rate1MonthAgo)
      : null;

    const rate24HoursAgoPercentageChange = rate24HoursAgo
      ? getPercentageChange(rate24HoursAgo, rate)
      : null;

    const rate24HoursAgoValueChange = rate24HoursAgo
      ? rate - rate24HoursAgo
      : null;
    const rate24HoursAgoValueChangeSign =
      (rate24HoursAgoValueChange ?? 0) < 0 ? '-' : '+';

    return {
      rate,
      rateFormatted: formatAmount(rate, decimals),
      rateWithCurrencyCodeFormatted: getFiatFormattedValue(rate, decimals),

      rate24HoursAgo,
      rate24HoursAgoFormatted: formatAmount(rate24HoursAgo, decimals),
      rate24HoursAgoFormattedUsd: getFiatFormattedValue(rate24HoursAgo),
      rate24HoursAgoValueChange,
      rate24HoursAgoValueChangeFormatted: `${rate24HoursAgoValueChangeSign}${formatAmount(
        Math.abs(rate24HoursAgoValueChange ?? 0),
        decimals
      )}`,
      rate24HoursAgoValueChangeFormattedUsd: `${rate24HoursAgoValueChangeSign}${getFiatFormattedValue(
        Math.abs(rate24HoursAgoValueChange ?? 0)
      )}`,
      rate24HoursAgoPercentageChange,
      rate24HoursAgoPercentageChangeFormatted: formatPercentage(
        rate24HoursAgoPercentageChange ?? 0
      ),

      rate7DaysAgo,
      rate7DaysAgoFormatted: formatAmount(rate7DaysAgo, decimals),
      rate7DaysAgoFormattedUsd: getFiatFormattedValue(rate7DaysAgo),
      rate7DaysAgoValueChange: rate7DaysAgo ? rate - rate7DaysAgo : null,
      rate7DaysAgoPercentageChange: rate7DaysAgo
        ? getPercentageChange(rate7DaysAgo, rate)
        : null,

      rate1MonthAgo,
      rate1MonthAgoFormatted: formatAmount(rate1MonthAgo, decimals),
      rate1MonthAgoFormattedUsd: getFiatFormattedValue(rate1MonthAgo),
      rate1MonthAgoValueChange: rate1MonthAgo ? rate - rate1MonthAgo : null,
      rate1MonthAgoPercentageChange: rate1MonthAgo
        ? getPercentageChange(rate1MonthAgo, rate)
        : null,
    };
  } catch (error) {
    console.error(`Error: getFxRate > error with fraction conversion`);
    return fallbackResult;
  }
};
