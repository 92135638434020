import { FC } from 'react';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import {
  InvoiceStatusTitle,
  RowLabelContent,
  StatusIndicator,
  formatMoney,
  getActivityStatus,
  monthsList,
} from 'common';
import { PayInvoicesNavigationProps } from './pay-invoices-navigation';
import { RowSection } from '~/components/workflows/shared/row-section';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import { CurrencyTransaction } from '~/components/workflows/shared/currency-transaction';

export const PreviewInvoice: FC<Partial<PayInvoicesNavigationProps>> = ({
  onClose,
  invoiceToPay,
  payInvoiceOptions,
}) => {
  /**
   * DOM
   */
  if (!invoiceToPay) return null;

  return (
    <div className="relative text-primary h-full">
      <div className="flex flex-col px-6 lg:px-8">
        {/* header  */}
        <DashboardHeader headerPadding="pt-14">
          <div className="mt-9 flex flex-row">
            <h3 className="font-semibold typo-20 md:typo-24 absolute top-9 z-30 pr-20">
              Invoice Details
            </h3>
          </div>
        </DashboardHeader>
        <div className="flex flex-col mt-3 gap-x-6">
          {/* status */}
          <RowLabelContent
            showColon={false}
            cls="border-none"
            label={`Status`}
            content={
              <StatusIndicator
                value={getActivityStatus(invoiceToPay.status)}
                title={InvoiceStatusTitle[invoiceToPay?.status]}
              />
            }
          />
          {/* invoice period */}
          <RowLabelContent
            showColon={false}
            label={`Invoice month/year`}
            content={
              <>
                {monthsList[getMonth(invoiceToPay.creationDate)].name}{' '}
                {getYear(invoiceToPay.creationDate)}
              </>
            }
          />
          {/* invoice number */}
          <RowLabelContent
            showColon={false}
            label={`Invoice number`}
            content={
              <>
                <RowSection
                  title={String(invoiceToPay.number)}
                  description=""
                />
              </>
            }
          />
          {/* from */}
          <RowLabelContent
            showColon={false}
            label={`From`}
            cls="mb-3"
            content={
              <div className="flex flex-col text-primary items-end gap-y-2">
                <CurrencyTransaction
                  positive={false}
                  amount={formatMoney(
                    invoiceToPay.amount.toString(),
                    invoiceToPay.quoteAssetCode || 'USD'
                  )}
                />
                <div>
                  <RowSection
                    title={payInvoiceOptions?.account?.account?.label || ''}
                    description={
                      <span className="flex flex-row gap-1">
                        <span>
                          {payInvoiceOptions?.account?.account?.accountType ||
                            ''}
                        </span>
                        <span>
                          {payInvoiceOptions?.account?.account?.accountNumber ||
                            ''}
                        </span>
                      </span>
                    }
                  />
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-6 px-8 sm:px-10">
        {/* close button  */}
        <button
          type="button"
          className="app-button-outline button-lg sm:button-xl text-md sm:text-lg w-full sm:mr-3 flex-1"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};
