import { Link } from 'react-router-dom';
import { ReactComponent as KycCheckRounded } from '../../assets/icons/kyc-check-rounded.svg';
import { API } from 'api';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';

interface Props {
  onClose: () => void;
}

export const KycPromptModal: React.FC<Props> = props => {
  const kycStatus = DataStore.useStoreState(state => state.kyc.kycStatus);

  const isWaiting =
    kycStatus?.kycState === API.KycState.WaitingForOnfido ||
    kycStatus?.kycState === API.KycState.ManualReviewInProgress;
  const isRejected =
    kycStatus?.lastSubmissionStatus === API.KycSubmissionStatus.Rejected;

  return (
    <div className="flex flex-col align-middle items-center p-10 pb-5">
      <KycCheckRounded width={20} />

      {isWaiting ? (
        <>
          <div className="text-2xl font-bold mt-5">
            Your verification is still pending
          </div>
          <div className="text-gray-400 mt-6 text-center">
            Unfortunately you cannot make any deposits, withdrawals, or
            conversions until you have been verified. You will receive a
            confirmation email from us shortly.{' '}
          </div>
          <Link
            onClick={props.onClose}
            to={APP_ROUTES.AUTH_PORTFOLIO}
            className="app-button-primary inline-block mt-6 mb-4 font-bold"
          >
            OK
          </Link>
        </>
      ) : isRejected ? (
        <>
          <div className="text-2xl font-bold mt-5">
            Your verification has been rejected
          </div>
          <div className="text-gray-400 mt-6 text-center">
            Unfortunately, you did not pass our verification process. Please try
            again or{' '}
            <Link
              to={APP_ROUTES.NON_AUTH_CONTACT_US}
              className="font-bold underline"
            >
              contact us
            </Link>{' '}
            for further clarification.
          </div>
          <Link
            to={APP_ROUTES.AUTH_KYC_INDEX}
            className="app-button-accent inline-block mt-6 mb-4 font-bold"
          >
            Try again
          </Link>
        </>
      ) : (
        <>
          <div className="text-2xl font-bold mt-5">
            Please complete your verification
          </div>
          <div className="text-gray-400 mt-6 text-center">
            Unfortunately you cannot make any deposits or withdrawals until you
            have been verified.
          </div>
          <Link
            to={APP_ROUTES.AUTH_KYC_INDEX}
            className="app-button-accent inline-block mt-6 mb-4 font-bold"
          >
            Get verified
          </Link>
        </>
      )}
    </div>
  );
};
