import { API } from 'api';
import cx from 'classnames';
import { Formik } from 'formik';
import { FC, useCallback } from 'react';
import { DataStore } from '../../store';
import { AppLoader } from '../app-loader';
import { Error } from '../common/error';
import { TextField } from '../forms/text-field';
import {
  AddAddressFormikField,
  AddAddressFormikProps,
  BlockchainNetworkName,
  EnrichedCurrencyInformation,
  FormHeader,
  TIMERS,
  validateAddAddressFormik,
} from 'common';
import { SelectField } from '../forms/select-field';
import { apiClient } from '../../api/client';
import { DebounceField } from '../forms/debounce-field';
import {
  currencyItemTemplate,
  currencySelectedItemTemplate,
} from '../app-selector/templates';

interface Props {
  impersonatedAccountId?: string;
  onClose: (success?: boolean) => void;
}

export const AddCrytpoAddress: FC<Props> = ({
  onClose,
  impersonatedAccountId,
}) => {
  /**
   * Store
   */
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  const {
    createWithdrawalAddress,
    setFormValues,
    createWithdrawalAddressOperation,
  } = DataStore.useStoreActions(a => ({
    createWithdrawalAddress: a.settings.cryptoAddresses.createWithdrawalAddress,
    createWithdrawalAddressOperation:
      a.settings.cryptoAddresses.createWithdrawalAddressOperation,
    setFormValues: a.settings.cryptoAddresses.setFormValues,
  }));
  const error = DataStore.useStoreState(s => s.error);

  /**
   * Methods
   */
  const onFormValidate = (values: AddAddressFormikProps) => {
    const errors = validateAddAddressFormik(
      values,
      apiClient as API.StablehouseClient,
      decodedTokenAccountId
    );
    return errors;
  };

  const onFormSubmit = async (values: AddAddressFormikProps) => {
    setFormValues(values);

    const { isSuccessful } =
      clientUserType === 'admin'
        ? await createWithdrawalAddressOperation({
            accountId: decodedTokenAccountId,
            impersonatedAccountId,
          }) // Note: userType admin does not flow through 2fa approval like retail user, so this conditional is only needed here, instead of 2fa this goes through a quorum process
        : await createWithdrawalAddress();

    if (isSuccessful) {
      onClose(true);
    }
  };

  /**
   * DOM
   */
  const initialValues: AddAddressFormikProps = {
    walletAddress: '',
    label: '',
    currency: null,
    network: null,
  };
  return (
    <div>
      {/* header  */}
      <FormHeader title="Add address" />

      {/* api errors  */}
      <Error message={error} cls={`mx-10`} />

      {/* form  */}
      <Formik<AddAddressFormikProps>
        initialValues={initialValues}
        validate={onFormValidate}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange
        onSubmit={onFormSubmit}
      >
        {({
          errors,
          isValidating,
          submitForm,
          dirty,
          handleBlur,
          setFieldValue,
          values,
        }) => {
          /**
           * Methods
           */
          const onCurrencyChanged = async (
            newValue: EnrichedCurrencyInformation
          ) => {
            if (isValidating) {
              return;
            }

            await setFieldValue(
              AddAddressFormikField.network,
              newValue.blockchain,
              false
            );

            await setFieldValue(AddAddressFormikField.currency, newValue, true);
          };
          const onCancelButtonClicked = useCallback(
            (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              onClose();
            },
            [onClose]
          );
          /**
           * Form DOM
           */
          if (!currencies) {
            return null;
          }
          const isDisabled =
            isValidating ||
            error !== null ||
            Object.keys(errors).length > 0 ||
            !dirty;
          const cryptoCurrenciesList = currencies.filter(
            cur => !cur.isAssetOfTypeFiat && !cur.isAssetOfTypeFund
          );
          return (
            <>
              {/* busy indicator when validation is happening  */}
              {isValidating && (
                <AppLoader bgColor={'bg-transparent'} spinnerTop="0%" />
              )}
              {/* fields  */}
              <div className="my-6 px-10">
                {/* funds dropdown  */}
                <div
                  className={cx('flex flex-col mb-2 sm:mb-0 sm:flex-1 mt-3')}
                >
                  <SelectField
                    name={AddAddressFormikField.currency}
                    label="Choose coin"
                    values={cryptoCurrenciesList}
                    getSelectedItemTemplate={item =>
                      currencySelectedItemTemplate(item, true)
                    }
                    getItemTemplate={(
                      fund,
                      selected: boolean,
                      active: boolean
                    ) => currencyItemTemplate(fund, selected, active, true)}
                    onChange={onCurrencyChanged}
                  />
                </div>

                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <DebounceField
                      name={AddAddressFormikField.walletAddress}
                      label={'Wallet address'}
                      disabled={!values.currency}
                      debounceTimeout={TIMERS.INPUT_DEBOUNCE}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <TextField
                      name={AddAddressFormikField.label}
                      label={'Address name'}
                      maxLength={27}
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <SelectField
                      name={AddAddressFormikField.network}
                      label="Choose network"
                      disabled
                      values={Object.values(BlockchainNetworkName)}
                    />
                  </div>
                </div>
              </div>

              {/* actions  */}
              <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
                {/* cancel button  */}
                <button
                  className="app-button-outline w-full mr-3 flex-1"
                  onClick={onCancelButtonClicked}
                >
                  Cancel
                </button>
                {/* withdraw button  */}
                <button
                  disabled={isDisabled}
                  type="button"
                  className="app-button-primary w-full flex-1"
                  onClick={submitForm}
                >
                  Add address
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
