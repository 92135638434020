import { FC, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { DataStore } from '../../store';
import { nanoid } from 'nanoid';
import base32 from 'base-32';
import { CopyableText } from '../copyable-text';
import { Formik, FormikHelpers, FormikState } from 'formik';
import * as Yup from 'yup';
import { BaseForm } from '../../definitions/base-form';
import {
  DEFAULTS,
  useUrls,
  YUP_VALIDATIONS,
  useMediaQuery,
  getTotpUrl,
  AppIcon,
} from 'common';
import howTo2Fa from 'common/dist/assets/logo/how-to-2fa.png';
import { API } from 'api';
import { Error } from '../common/error';
import { CharacterInput } from '../character-input';
import { nameof } from '../../utils/typescript-helpers';
import { AppTabs } from '../app-tabs/app-tabs';

enum FormField {
  twoFaCode = 'twoFaCode',
}

enum DOWLOAD_DETAIL_TAB {
  IOS = 'iOS App Store',
  ANDROID = 'Google playstore',
}

interface FormProps extends BaseForm {
  [FormField.twoFaCode]: string | null;
}

const formSchema = Yup.object().shape({
  [FormField.twoFaCode]: YUP_VALIDATIONS.TWO_FA_CODE,
});

type TwoFASetupProps = {
  onCompleted?: () => void;
  onCancel?: () => void;
  allowBypass?: boolean;
};

export const TwoFASetup: FC<TwoFASetupProps> = ({
  onCompleted = () => {},
  onCancel = () => {},
  allowBypass = true,
}) => {
  /**
   * Store
   */

  const email = DataStore.useStoreState(s => s.user.decodedToken?.email);
  const error = DataStore.useStoreState(s => s.error);
  const setError = DataStore.useStoreActions(a => a.setError);
  const enable2Fa = DataStore.useStoreActions(a => a.settings.enable2Fa);
  const getAccountSettings = DataStore.useStoreActions(
    a => a.settings.getAccountSettings
  );

  /**
   * State
   */
  const [authKey, setAuthKey] = useState<string | null>(null);
  const [authLink, setAuthLink] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);
  /**
   * Hooks
   */
  const { googleStore2FAUrl, appStore2FAUrl } = useUrls();
  const { XS, SM } = useMediaQuery();
  useEffect(() => {
    if (!email) {
      return;
    }
    (async () => {
      const key = base32.encode(nanoid(10));
      const url = getTotpUrl(email, key);
      setAuthKey(key);
      setAuthLink(url);
    })();
  }, [email]);

  /**
   * Methods
   */
  const unMountView = async (resetForm: () => void) => {
    resetForm();
    setError(null);
    await getAccountSettings({ isBackgroundXHR: false });
    onCompleted();
  };
  const onFormSubmit = async (
    values: FormProps,
    { resetForm }: FormikHelpers<FormProps>
  ) => {
    if (!values.twoFaCode || !authKey) {
      return;
    }
    const body: API.EnableGoogleAuthenticatorRequest = {
      privateKey: authKey,
      code: values.twoFaCode,
    };
    const passed = await enable2Fa(body);
    if (!passed) {
      return;
    }
    await unMountView(resetForm);
  };

  const onFormCancel = (
    resetForm: (nextState?: Partial<FormikState<FormProps>>) => void
  ) => {
    onCancel();
    unMountView(resetForm);
  };

  /**
   * DOM
   */
  const initialValues: FormProps = {
    twoFaCode: null,
  };
  return (
    <ul className="mt-12 text-sm text-center">
      <li className="mb-14">
        <p className="border-t typo-20 pt-6 pb-1.5 font-bold ">
          1. Scan a QR below and download the app with your mobile
        </p>
        <div className="pt-1 pb-5 typo-b2 text-grey-darker">
          Get the Google authentication app from your preferred app store:
        </div>

        <AppTabs
          headerContainerCls="justify-center"
          headerItemCls="border-primary"
          tabs={[
            <div key={DOWLOAD_DETAIL_TAB.IOS}>{DOWLOAD_DETAIL_TAB.IOS}</div>,
            <div key={DOWLOAD_DETAIL_TAB.ANDROID}>
              {DOWLOAD_DETAIL_TAB.ANDROID}
            </div>,
          ]}
          panels={[
            <div
              key={DOWLOAD_DETAIL_TAB.IOS}
              className="flex flex-col justify-center items-center gap-y-1.5"
            >
              <div className="flex flex-col items-center gap-y-2.5 pb-3">
                <span>Scan here</span>
                <QRCode
                  size={190}
                  value={appStore2FAUrl}
                  className="border border-grey-bright p-4"
                />
              </div>
              <a href={appStore2FAUrl}>
                <AppIcon icon="ios-app-store" size={190} height={63} />
              </a>
            </div>,
            <div
              key={DOWLOAD_DETAIL_TAB.ANDROID}
              className="flex flex-col justify-center items-center gap-y-1.5"
            >
              <div className="flex flex-col items-center gap-y-2.5 pb-3">
                <span>Scan here</span>
                <QRCode
                  size={190}
                  value={googleStore2FAUrl}
                  className="border border-grey-bright p-4"
                />
              </div>
              <a href={googleStore2FAUrl}>
                <AppIcon icon="google-app-store" size={190} height={63} />
              </a>
            </div>,
          ]}
          selectedIndex={tabIndex}
          onChange={setTabIndex}
        />
      </li>
      <li className="border-t items-center justify-center flex flex-col gap-y-6 py-6">
        <div>
          <p className="typo-20 font-bold ">2. Add a new key</p>
          <div className="typo-b2 text-grey-darker">
            Once you are in the app, add a new key.
          </div>
        </div>
        <img
          width={140}
          height={223}
          className="bg-transparent"
          src={howTo2Fa}
          alt="how to 2 FA"
        />
      </li>
      <li className="border-t items-center justify-center flex flex-col gap-y-6 py-6">
        <div>
          <p className="typo-20  font-bold ">3. Scan the QR code</p>
          <div className=" typo-b2 text-grey-darker">
            Select the Scan a QR code option from your mobile.
          </div>
        </div>
        {authKey && authLink && (
          <div className="flex flex-col items-center gap-y-6">
            <QRCode
              value={authLink}
              size={190}
              className="border border-grey-bright p-6 bg-white"
            />
            <div className="flex flex-col flex flex-col items-center text-gray-400">
              <span className="typo-b2">
                If you cannot scan the QR code, enter this code manually:
              </span>
              <CopyableText
                cls="flex-col typo-b2 text-grey-darker font-bold items-center"
                text={authKey}
                btnCls="px-2.5 py-3 border rounded bg-white mt-6"
                textCls="items-start"
              />
            </div>
          </div>
        )}
      </li>
      <li className="border-t items-center justify-center flex flex-col gap-y-6 py-6">
        <p className="typo-20 font-bold">
          4. Enter the 6 digit code you see in the app:
        </p>
        <Formik<FormProps>
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={onFormSubmit}
          enableReinitialize
        >
          {({ values, errors, submitForm, resetForm, setFieldValue }) => {
            /**
             * Form Methods
             */
            const onCodeChanged = async (value: string) => {
              await setFieldValue(
                nameof<FormProps>(FormField.twoFaCode),
                value,
                true
              );
            };

            // DOM
            const hasErrors = Object.keys(errors).length > 0;

            return (
              <>
                {/* 2 fa input  */}
                <CharacterInput
                  value={values.twoFaCode || ''}
                  length={DEFAULTS.MAX_TWO_FA_LENGTH}
                  onChange={onCodeChanged}
                />

                {/* api errors  */}
                <Error message={error} />

                {/* action button  */}
                <div className="flex flex-col">
                  <button
                    type="submit"
                    className="app-button-accent"
                    onClick={submitForm}
                    data-testid="button-enable"
                    disabled={hasErrors || !values.twoFaCode}
                  >
                    Enable 2FA
                  </button>

                  {allowBypass && (
                    <button
                      className="app-button-outline"
                      onClick={() => onFormCancel(resetForm)}
                      data-testid="button-cancel"
                    >
                      I&apos;ll do it later
                    </button>
                  )}
                </div>
              </>
            );
          }}
        </Formik>
      </li>
    </ul>
  );
};
