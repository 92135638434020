import { FC, useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { StepWizardChildProps } from 'react-step-wizard';
import { NOOP } from '../../constants';
import { DataStore } from '../../store';
import { SettingsSecuritySteps } from './settings-security';
import { AppIcon, AppDialog } from 'common';

export const SettingsSecurity2faOverview: FC<Partial<StepWizardChildProps>> = ({
  goToStep = NOOP,
}) => {
  /**
   * Store State
   */
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );
  const setError = DataStore.useStoreActions(a => a.setError);

  /**
   *  State
   */
  const [showDisable2FADialog, setShowDisable2FADialog] = useState(false);
  return (
    <div>
      <AppDialog
        isOpen={showDisable2FADialog}
        onClose={() => {
          setShowDisable2FADialog(false);
        }}
      >
        <div className="flex flex-col text-primary">
          <div className="flex flex-col items-center mt-16 mb-12 mx-10">
            <AppIcon icon="lock-2fa" size={60} />
            {/* header  */}
            <h4 className="mt-4 mb-5 mt-6 font-bold text-xl text-center">
              Disabling 2FA will log you out
            </h4>
            {/* content  */}
            <p className="text-gray-400 mb-3 text-center mx-10">
              For security reasons, we will need to log you out when disabling
              2FA. Continue?
            </p>
          </div>
          {/* actions  */}
          <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-8 px-10">
            <button
              className="flex-1 flex items-center justify-center app-button-outline mr-3 "
              onClick={() => setShowDisable2FADialog(false)}
            >
              Cancel
            </button>
            <button
              className="flex-1 flex items-center justify-center app-button-primary"
              onClick={() => goToStep(SettingsSecuritySteps.TWO_FA_ENABLED)}
            >
              Continue
            </button>
          </div>
        </div>
      </AppDialog>
      <h5 className="font-bold mb-4">Two-Factor authentication</h5>
      <>
        <div className="text-sm text-gray-400 mb-2">
          Two-factor authentication (2FA) is the industry standard for securing
          your online accounts. Once enabled, 2FA adds a second layer of
          protection to your Stablehouse account. When you sign in, you’ll be
          prompted to input your password and a verification code generated by
          your authenticator app.
        </div>
        <div className="text-sm text-gray-400">
          You can setup 2FA by using the{' '}
          <span className="text-primary">Google Authenticator</span> app,
          available on
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer mx-1 underline text-primary"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          >
            Android
          </a>
          and
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer mx-1 underline text-primary"
            href="https://itunes.apple.com/us/app/google-authenticator/id388497605"
          >
            iOS
          </a>
          mobile devices. Once enabled, you will only be able to log in to your
          Stablehouse account with a code generated by the app. This code
          regenerates every 30 seconds. We urge all Stablehouse account holders
          to enable 2FA on their accounts. Stablehouse is not responsible for
          any unauthorized users that may access your account.
        </div>
      </>
      <div className="my-4">
        {/* when enabled  */}
        {is2FaEnabled && (
          <button
            onClick={e => {
              e.preventDefault();
              setShowDisable2FADialog(true);
            }}
            data-testid="disable-2fa-button"
            className="app-button-accent flex items-center"
          >
            Disable 2FA
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </button>
        )}
        {/* when disabled  */}
        {!is2FaEnabled && (
          <button
            onClick={e => {
              e.preventDefault();
              setError(null);
              goToStep(SettingsSecuritySteps.TWO_FA_DISABLED);
            }}
            data-testid="enable-2fa-button"
            className="app-button-accent flex items-center"
          >
            Enable 2FA
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </button>
        )}
      </div>
    </div>
  );
};
