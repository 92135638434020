import { API } from 'api';
import { validateDestination } from '../../validations/destination';
import memoize from 'memoizee';
import { AddAddressFormikField, AddAddressFormikProps } from './types';
import { ERROR_MESSAGES } from '../../../constants';
import { SendDestinationType } from '../send/types';

const validate = async (
  { walletAddress, label, network }: AddAddressFormikProps,
  apiClient: API.StablehouseClient,
  accountId: string
) => {
  const errors: Record<string, unknown> = {};

  if (!network) {
    errors[AddAddressFormikField.network] = ERROR_MESSAGES.REQUIRED_VALUE;
  }
  if (!label) {
    errors[AddAddressFormikField.label] = ERROR_MESSAGES.REQUIRED_VALUE;
  }
  if (network) {
    if (!walletAddress) {
      errors[AddAddressFormikField.walletAddress] =
        ERROR_MESSAGES.REQUIRED_VALUE;
    } else {
      const { isValid, message } = await validateDestination(
        walletAddress,
        network,
        apiClient as API.StablehouseClient,
        accountId,
        SendDestinationType.crypto
      );
      if (!isValid) {
        errors[AddAddressFormikField.walletAddress] =
          message || ERROR_MESSAGES.INVALID_DESTINATION;
      }
    }
  }
  return errors;
};

export const validateAddAddressFormik = memoize(validate);
