import { FC, useCallback, useState } from 'react';
import {
  AppIcon,
  AppIconType,
  CurrencyIcon,
  EnrichedCurrencyInformation,
  PriceCell,
  Widgetcard,
  useCopy,
} from 'common';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { WidgetAdd2Fa } from './widget-add-2fa';
import { WidgetGetVerified } from './widget-get-verified';
import { CustomWidget } from './custom-widget';
import { WidgetReferAFriend } from './widget-refer-a-friend';
import { WidgetPortfolioHome } from './widget-portfolio-home';
import { WidgetPortfolioContactUs } from './widget-portfolio-contact-us';
import { WidgetUserActions } from './widget-user-actions';
import { WidgetStablehouseTelegram } from './widget-stablehouse-telegram';
import cx from 'classnames';
import { AppTabs } from '../app-tabs/app-tabs';
import {
  AppTableHeadings,
  SortProps,
  useAppTableSort,
  Props as ColumnHeaderProps,
  AppTableRows,
  SortDirection,
} from '../app-table';
import { DataStore } from '~/store';
import { MarketsColumn, MarketsHeadings } from '~/config';
import { WidgetDefault } from './widget-default';
interface DashboardContactCardProps {
  relationshipManagerEmail?: JSX.Element | string | null;
  relationshipManagerName?: string | null;
  relationshipManagerContactNumber?: JSX.Element | string | null;
  title?: string;
  icon?: AppIconType;
  relationshipManagerEmailTitle?: string;
  relationshipManagerNameTitle?: string;
  relationshipManagerContactNumberTitle?: string;
}
interface RelationshipManagerItemProps {
  itemTitle?: string;
  value?: JSX.Element | string | null;
}

enum MARKETS_TAB {
  TOP_GAINERS = 'top gainers',
  TOP_LOSERS = 'top losers',
}

const getAssetColumn = (
  props: SortProps<MarketsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    showSort: false,
    name: MarketsColumn.Asset,
    cls: 'overflow-hidden',
    cell: (data: EnrichedCurrencyInformation) => {
      return (
        <span className="text-sm truncate">
          <CurrencyIcon
            size={32}
            currencyCode={data.code}
            currencyName={data.name}
            apy={data.apy}
          />
        </span>
      );
    },
  };
};

const getPriceColumn = (
  props: SortProps<MarketsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    showSort: false,
    name: MarketsColumn.Price,
    cls: 'overflow-hidden',
    cell: (data: EnrichedCurrencyInformation) => {
      return (
        <span className="text-sm font-bold truncate justify-end">
          <span className="flex justify-end">
            {data.fxRate.rateWithCurrencyCodeFormatted}
          </span>
          {!!data.fxRate.rate24HoursAgoPercentageChange && (
            <PriceCell
              textSizeCls="text-xs justify-end"
              fontWeightCls="font-bold"
              value={data.fxRate.rate24HoursAgoPercentageChange}
              showColor
            />
          )}
        </span>
      );
    },
  };
};

const getColumns = (
  columnProps: SortProps<MarketsColumn>
): ColumnHeaderProps<MarketsColumn, EnrichedCurrencyInformation>[] => {
  return [
    getAssetColumn(columnProps, MarketsHeadings[MarketsColumn.Asset]),
    getPriceColumn(columnProps, MarketsHeadings[MarketsColumn.Price]),
  ];
};

const ExploreMarketWidget: FC = () => {
  /**
   * State
   */
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  /**
   * Store
   */
  const topGainers = DataStore.useStoreState(s => s.metaData.topGainers);
  const topLosers = DataStore.useStoreState(s => s.metaData.topLosers);

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { columnProps } = useAppTableSort<MarketsColumn>({
    column: MarketsColumn.Asset,
    direction: SortDirection.ASC,
  });
  const columns = getColumns(columnProps);
  const {
    portfolio: {
      gainersAndLosers: { gainers, losers },
    },
  } = useCopy();

  const gainersLosersWidgetContent = (
    rows: EnrichedCurrencyInformation[],
    content: {
      title: string;
      content: string;
    }
  ) =>
    rows.length ? (
      <>
        <AppTableHeadings
          columns={columns}
          borderCls="border-1 border-b-0 bg-white bg-white"
        />
        <AppTableRows<MarketsColumn, EnrichedCurrencyInformation>
          as={Link}
          to={(row) => `${APP_ROUTES.AUTH_ASSET}/${row.code}`}
          rows={rows}
          columns={columns}
          cellRowCls="gap-x-2"
          containerCls="border-1 grey-bright"
        />
      </>
    ) : (
      <Widgetcard
        iconSize={48}
        icon="market-trends"
        title={content.title}
        subText={content.content}
        cls="flex flex-col px-6 py-8 bg-white border"
        subTextCls="text-grey-darker text-center text-sm"
        contentCls="py-4 flex flex-col items-center"
        titleCls="text-sm sm:text-base text-center text-primary font-bold py-2"
      />
    );

  return (
    <AppTabs
      marginCls="-mt-6"
      borderCls="border rounded-t border-b-0 rounded-b-0"
      headerItemCls="border-primary w-full"
      headerContainerCls="bg-white"
      tabs={[
        <div
          key={MARKETS_TAB.TOP_GAINERS}
          className="capitalize text-primary text-base p-4 flex justify-center"
        >
          {MARKETS_TAB.TOP_GAINERS}
        </div>,
        <div
          key={MARKETS_TAB.TOP_LOSERS}
          className="capitalize text-primary text-base p-4 flex justify-center"
        >
          {MARKETS_TAB.TOP_LOSERS}
        </div>,
      ]}
      panels={[
        <div key={MARKETS_TAB.TOP_GAINERS}>
          {gainersLosersWidgetContent(topGainers, gainers)}
        </div>,
        <div key={MARKETS_TAB.TOP_LOSERS}>
          {gainersLosersWidgetContent(topLosers, losers)}
        </div>,
      ]}
      footer={
        <div
          className="border-1 border-t-0 grey-bright rounded-b-4 px-6 py-4 flex items-center flex-row justify-center cursor-pointer bg-white hover-bg-grey-brighter gap-x-2"
          onClick={() => navigate(APP_ROUTES.AUTH_MARKETS)}
        >
          <span className="text-sm sm:text-base font-bold text-primary">
            Explore markets
          </span>
          <AppIcon
            size={14}
            icon="chevron-right"
            bg="bg-transparent"
            fill="fill-current-color-primary-darkest"
            cls="mt-0.5"
          />
        </div>
      }
      selectedIndex={selectedTabIndex}
      onChange={setSelectedTabIndex}
    />
  );
};

const RelationshipManagerItem: FC<RelationshipManagerItemProps> = ({
  itemTitle,
  value,
}) => {
  if (!itemTitle || !value) {
    return null;
  }
  return (
    <p className="flex flex-row justify-between overflow-hidden items-center border-t pt-4">
      <span className="text-xs text-grey-darker">{itemTitle}</span>
      <span className="text-sm font-semibold text-primary truncate">
        {value}
      </span>
    </p>
  );
};

export const DashboardContactCard: FC<DashboardContactCardProps> = ({
  relationshipManagerEmail,
  relationshipManagerName,
  relationshipManagerContactNumber,
  title,
  icon,
  relationshipManagerNameTitle,
  relationshipManagerEmailTitle,
  relationshipManagerContactNumberTitle,
}) => {
  /**
   * Dom
   */
  if (
    (!!relationshipManagerNameTitle && !relationshipManagerName) ||
    (!!relationshipManagerEmailTitle && !relationshipManagerEmail) ||
    (!!relationshipManagerContactNumberTitle &&
      !relationshipManagerContactNumber)
  ) {
    return null;
  }
  return (
    <div
      className="border-1 grey-bright rounded-4 p-6 gap-4 flex items-center flex-col justify-between bg-white"
      onClick={() => {}}
    >
      {icon && <AppIcon icon={icon} size={48} />}
      <span
        className={cx('text-base font-bold text-primary', {
          'mt-2.5': Boolean(icon),
        })}
      >
        {title}
      </span>
      <div className="flex flex-col gap-4 w-full">
        <RelationshipManagerItem
          itemTitle={relationshipManagerNameTitle}
          value={relationshipManagerName}
        />
        <RelationshipManagerItem
          itemTitle={relationshipManagerContactNumberTitle}
          value={relationshipManagerContactNumber}
        />
        <RelationshipManagerItem
          itemTitle={relationshipManagerEmailTitle}
          value={relationshipManagerEmail}
        />
      </div>
    </div>
  );
};

export const Widgets = {
  ExploreMarket: ExploreMarketWidget,
  DashboardContactCard: DashboardContactCard,
  WidgetReferAFriend: WidgetReferAFriend,
  WidgetPortfolioHome: WidgetPortfolioHome,
  WidgetPortfolioContactUs: WidgetPortfolioContactUs,
  WidgetPortfolioUserActions: WidgetUserActions,

  /* user/settings*/
  WidgetAdd2Fa: WidgetAdd2Fa,
  WidgetGetVerified: WidgetGetVerified,
  CustomWidget: CustomWidget,
  WidgetStablehouseTelegram: WidgetStablehouseTelegram,
  WidgetDefault: WidgetDefault,
};
