import { FC } from 'react';
import cx from 'classnames';
import { APP_ROUTES } from '../../routes';
import { Link } from 'react-router-dom';
import { useComputedConfig, useUrls } from 'common';

interface Props {
  cls?: string;
  linkCls?: string;
}

export const AppFooterNonAuth: FC<Props> = ({
  cls = 'text-gray-400',
  linkCls = `hover:text-primary`,
}) => {
  const { termsUrl, privacyUrl } = useUrls();
  const { tenant } = useComputedConfig();

  return (
    <footer
      className={cx(
        'flex flex-row gap-2 items-center py-8 justify-center',
        cls
      )}
    >
      <Link
        to={APP_ROUTES.AUTH_PORTFOLIO}
        className="focus:ring-transparent focus:outline-none font-light text-sm flex-1 mr-3 md:mr-12 cursor-pointer"
      >
        {/* &copy; */}
        {tenant === 'XBTO'
          ? `XBTO Group.`
          : tenant === 'Apex'
            ? `Stablehouse Ltd.`
            : `Stablehouse Ltd.`}
      </Link>
      <Link
        to={{
          pathname: APP_ROUTES.NON_AUTH_CONTACT_US,
        }}
        state={''}
        className={cx('hover:underline ml-3 pl-3  border-l', linkCls)}
      >
        <p className="font-light text-sm">Contact us</p>
      </Link>
      <a
        href={termsUrl}
        className={cx('hover:underline mx-3 px-3 border-l border-r', linkCls)}
      >
        <p className="font-light text-sm">Terms of use</p>
      </a>
      <a href={privacyUrl} className={cx('hover:underline', linkCls)}>
        <p className="font-light text-sm">Privacy policy</p>
      </a>
    </footer>
  );
};
