import { FC, useEffect } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { BaseForm } from '../../definitions/base-form';
import { Formik, FormikHelpers } from 'formik';
import { NOOP } from '../../constants';
import { SettingsSecuritySteps } from './settings-security';
import { API } from 'api';
import { ReactComponent as PadlockUnlockIcon } from 'common/dist/assets/icons/padlock-unlock.svg';
import { CheckIcon } from '@heroicons/react/outline';
import { useToast } from '../../hooks/use-toast';
import { Error } from '../common/error';
import { TextField } from '../forms/text-field';
import memoize from 'memoizee';
import { DataStore } from '../../store';
import { DebounceField } from '../forms/debounce-field';
import { TIMERS, ERROR_MESSAGES } from 'common';
import {
  usePasswordLabel,
  useTogglePassword,
} from '../../hooks/use-toggle-password';

export enum FormField {
  currentPassword = 'currentPassword',
  newPassword = 'newPassword',
  confirmNewPassword = 'confirmNewPassword',
}

interface FormProps extends BaseForm {
  [FormField.currentPassword]: string;
  [FormField.newPassword]: string;
  [FormField.confirmNewPassword]: string;
}

export const SettingsSecurityChangePassword: FC<
  Partial<StepWizardChildProps>
> = ({ goToStep = NOOP }) => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const error = DataStore.useStoreState(s => s.error);
  const setError = DataStore.useStoreActions(a => a.setError);
  const updatePassword = DataStore.useStoreActions(
    a => a.settings.updatePassword
  );
  const getPasswordEntropy = DataStore.useStoreActions(
    a => a.user.getPasswordEntropy
  );

  /**
   * State
   */
  const [showSuccessMsg, showToast] = useToast(TIMERS.TOAST);
  const [showPassword, toggleShowPassword, passwordType] = useTogglePassword();

  /**
   * Methods
   */
  const unMountView = (resetForm: () => void) => {
    resetForm();
    goToStep(SettingsSecuritySteps.OVERVIEW);
  };
  const onFormSubmit = async (
    values: FormProps,
    { resetForm }: FormikHelpers<FormProps>
  ) => {
    setError(null);
    const body: API.UpdatePasswordRequest = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    await updatePassword(body).then((success: boolean) => {
      unMountView(resetForm);
      if (success) showToast();
    });
  };
  const validatePassword = async (password: string) => {
    return await getPasswordEntropy({
      password,
    });
  };

  const memoizedValidatePassword = memoize(validatePassword);

  const validate = async ({
    currentPassword,
    newPassword,
    confirmNewPassword,
  }: FormProps) => {
    const errors: Record<string, unknown> = {};

    if (!currentPassword) {
      errors[FormField.currentPassword] = ERROR_MESSAGES.REQUIRED_VALUE;
    }

    // pwd check
    const response = await memoizedValidatePassword(newPassword);
    if (response && !response.isValid) {
      errors[FormField.newPassword] = ERROR_MESSAGES.INVALID_VALUE;
    }

    // repeat pwd check
    if (newPassword && newPassword !== confirmNewPassword) {
      errors[FormField.confirmNewPassword] = ERROR_MESSAGES.PASSWORD_MUST_MATCH;
    }

    return errors;
  };

  const onFormValidate = async (values: FormProps) => {
    return validate(values).then((errors = {}) => {
      return errors;
    });
  };

  useEffect(() => {
    return () => setError(null);
  }, []);

  /**
   * DOM
   */
  const initialValues: FormProps = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  return (
    <div>
      {showSuccessMsg && (
        <>
          <div
            data-testid="icon-content"
            className="flex flex-row items-center text-gray-400 text-sm"
          >
            <CheckIcon className="w-5 h-5 mr-4" />
            <div>Your password has been updated successfully.</div>
          </div>
          <hr className="bg-grey-bright my-4" />
        </>
      )}

      {/* form  */}
      <Formik<FormProps>
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validate={onFormValidate}
        validateOnChange
      >
        {({ submitForm, isValid, dirty }) => {
          /**
           * Form DOM
           */
          return (
            <div>
              {/* api errors  */}
              <Error message={error} />

              {/* password  */}
              <div className={'max-w-xs'}>
                <TextField
                  type={passwordType}
                  name={FormField.currentPassword}
                  label={usePasswordLabel(
                    'Current password',
                    showPassword,
                    toggleShowPassword
                  )}
                  autoComplete="current-password"
                  data-testid="current-password-input"
                />
              </div>

              {/* new password  */}

              <div className={'max-w-xs'}>
                <DebounceField
                  name={FormField.newPassword}
                  label="New password"
                  data-testid="new-password-input"
                  type={passwordType}
                  validator="password"
                  debounceTimeout={TIMERS.INPUT_DEBOUNCE}
                />
              </div>

              {/* new password  */}
              <div className={'max-w-xs'}>
                <TextField
                  type={passwordType}
                  name={FormField.confirmNewPassword}
                  label="Repeat new password:"
                  data-testid="confirm-new-password-input"
                />
              </div>

              {/* action button  */}
              <div className="my-4 flex flex-row items-center">
                <button
                  disabled={busy || !(isValid && dirty)}
                  className="app-button-accent flex items-center"
                  onClick={submitForm}
                  data-testid="save-change-password-button"
                >
                  <PadlockUnlockIcon className="inline-block mr-2 w-4 h-4 fill-current-color" />
                  Change
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
