import {
  ActionId,
  AppContainer,
  AppIcon,
  AppIconType,
  Badge,
  ChartSeriesType,
  ChartTimeRange,
  CurrencyIcon,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  EnrichedAssetHoldings,
  EnrichedCurrencyInformation,
  maskHash,
  useAppDialog,
  useAssetInfo,
} from 'common';
import { FC, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppLoader } from '~/components/app-loader';
import {
  AppTableRows,
  Props as ColumnHeaderProps,
  SortProps,
  useAppTableSort,
} from '~/components/app-table';
import { Caption } from '~/components/dashboard/columns/caption';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import { PortfolioHero } from '~/components/portfolio';
import { AccountSelector } from '~/components/workflows/account-selector';
import { WorkflowHeroButtons } from '~/components/workflows/hero-buttons';
import { WorkflowRowButtons } from '~/components/workflows/row-buttons';
import { APP_ROUTES } from '~/routes';
import { AppStore, DataStore } from '../../store';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';
import { TradingViewChart } from '~/components/charting/tv-chart';
import { PreventNavigation } from '~/hoc/prevent-navigation';
import { getAccountType } from '~/utils/get-account-details';

type AboutAssetProps = {
  icon: AppIconType;
  title: string;
  subText: string;
};

const AboutAsset = ({ icon, title, subText }: AboutAssetProps) => {
  /**
   * DOM
   */

  return (
    <div className="flex flex-row py-4 gap-3">
      <span>
        <AppIcon icon={icon} fill="text-secondary" />
      </span>
      <div className="flex flex-col text-primary whitespace-pre-line">
        <span className="font-semibold pb-1">{title}</span>
        <span className="text-sm text-grey-darker">{subText}</span>
      </div>
    </div>
  );
};

export enum MarketAssetColumns {
  Asset = 'Asset',
  Value = 'Value',
  Workflow = 'Workflow',
}

export enum MarketAssetTotalColumns {
  Total = 'Total',
  Value = 'Value',
  Workflow = 'Workflow',
}

type OnWorkflowSelected = (
  type: DASHBOARD_SELECTED_DIALOG_TYPE,
  asset: EnrichedAccountDetailAsset | EnrichedAssetHolding | null,
  accountId: string | null
) => void;

const getAssetHoldingNameColumn = (
  props: SortProps<MarketAssetColumns>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '40%',
    showSort: false,
    justify: 'justify-start',
    name: MarketAssetColumns.Asset,
    cell: (data: EnrichedAssetHolding | null) => {
      if (!data) {
        return null;
      }
      return (
        <span className="flex flex-col gap-0.5 overflow-hidden">
          <span className="flex flex-row items-center gap-2">
            <span className="text-sm font-bold truncate">
              {data?.account?.label || '-'}
            </span>
            <span className="text-xs leading-3 font-medium bg-grey-bright p-1 relative rounded-sm">
              {getAccountType(data?.account)}
            </span>
          </span>
          <span className="text-sm text-grey-darker truncate">
            {maskHash(data?.account?.accountNumber || '')}
          </span>
        </span>
      );
    },
  };
};
const getAssetHoldingValueColumn = (
  props: SortProps<MarketAssetColumns> & EnrichedCurrencyInformation,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '60%',
    showSort: false,
    justify: 'justify-end',
    name: MarketAssetColumns.Value,
    cell: (data: EnrichedAssetHolding | null) => {
      if (!data && !props) {
        return null;
      }
      return (
        <span className="flex flex-col items-end gap-0.5 overflow-hidden">
          <span className="text-base font-bold truncate">
            {data?.formatted.balanceIncludingPendingWithCurrencyCode}
          </span>
          <Caption textColor="text-grey-darker" className="text-sm">
            {data?.formatted.value}
          </Caption>
        </span>
      );
    },
  };
};

const getAssetWorkflowActionColumn = (
  props: SortProps<MarketAssetColumns>,
  heading: string | JSX.Element | undefined,
  onWorkflowSelected: OnWorkflowSelected
) => {
  return {
    ...props,
    heading,
    width: '7.5%',
    showSort: false,
    justify: 'justify-end',
    name: MarketAssetColumns.Workflow,
    stopEventPropogation: true,
    cell: (data: EnrichedAssetHolding | null) => {
      if (!data) {
        return null;
      }
      const navigate = useNavigate();
      return (
        <WorkflowRowButtons
          asset={data}
          onActionClick={(action, asset) => {
            if (!action && asset) {
              navigate(
                APP_ROUTES.AUTH_PORTFOLIO + `/${asset?.account?.accountId}`,
                {
                  replace: true,
                  relative: 'path',
                }
              );
            }
            onWorkflowSelected(action, asset, data.account?.accountId || null);
          }}
        />
      );
    },
  };
};

const getAssetHoldingTotalColumn = (
  props: SortProps<MarketAssetTotalColumns>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '30%',
    showSort: false,
    justify: 'justify-start',
    name: MarketAssetTotalColumns.Total,
    cell: (data: EnrichedAssetHoldings | null) => {
      if (!data) {
        return null;
      }
      return <span className="text-base font-bold">Total</span>;
    },
  };
};

const getAssetTotalValueColumn = (
  props: SortProps<MarketAssetTotalColumns>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '64.5%',
    showSort: false,
    justify: 'justify-end',
    name: MarketAssetTotalColumns.Value,
    cell: (data: EnrichedAssetHoldings | null) => {
      if (!data || !props) {
        return null;
      }
      return (
        <span className="flex flex-col items-end gap-0.5 overflow-hidden">
          <span className="text-base font-bold">
            {data.formatted.totalQuantity}
          </span>
          <Caption textColor="text-grey-darker" className="text-sm">
            {data.formatted.value}
          </Caption>
        </span>
      );
    },
  };
};

const getAssetTotalWorkflowActionColumn = (
  props: SortProps<MarketAssetTotalColumns>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '7.5%',
    showSort: false,
    justify: 'justify-end',
    name: MarketAssetTotalColumns.Workflow,
    cell: () => <></>,
  };
};

const getMarketsAssetColumns = (
  columnProps: SortProps<MarketAssetColumns> & EnrichedCurrencyInformation,
  onWorkflowSelected: OnWorkflowSelected
): ColumnHeaderProps<MarketAssetColumns, EnrichedAssetHolding | null>[] => {
  return [
    getAssetHoldingNameColumn(columnProps, MarketAssetColumns.Asset),
    getAssetHoldingValueColumn(columnProps, MarketAssetColumns.Value),
    getAssetWorkflowActionColumn(
      columnProps,
      MarketAssetColumns.Workflow,
      onWorkflowSelected
    ),
  ];
};

const getMarketAssetTotalColumns = (
  columnProps: SortProps<MarketAssetTotalColumns>
): ColumnHeaderProps<
  MarketAssetTotalColumns,
  EnrichedAssetHoldings | null
>[] => {
  return [
    getAssetHoldingTotalColumn(columnProps, MarketAssetTotalColumns.Total),
    getAssetTotalValueColumn(columnProps, MarketAssetTotalColumns.Value),
    getAssetTotalWorkflowActionColumn(
      columnProps,
      MarketAssetTotalColumns.Workflow
    ),
  ];
};

const Detail: FC = () => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const assetHoldings = DataStore.useStoreState(s => s.portfolio.assetHoldings);
  const currencyCodes = DataStore.useStoreState(s => s.metaData.currencyCodes);
  const isKycVerified = DataStore.useStoreState(s => s.user.isKycVerified);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const assetOhlcvData = DataStore.useStoreState(
    s => s.metaData.assetOhlcvData
  );
  const chartingTimeRanges = DataStore.useStoreState(
    s => s.metaData.chartingTimeRanges
  );
  const assetOhlcvSeriesTypes = DataStore.useStoreState(
    s => s.metaData.assetOhlcvSeriesTypes
  );
  const disableAssetOhlcvSelection = DataStore.useStoreState(
    s => s.metaData.disableAssetOhlcvSelection
  );
  const assetOhlcvRatePercentageChangeBasedOnTimeRange =
    DataStore.useStoreState(
      s => s.metaData.assetOhlcvRatePercentageChangeBasedOnTimeRange
    );

  const {
    getAssetHoldings,
    setAssetHoldings,
    setAccountDetail,
    getAccountDetail,
    getAssetOhlcvData,
    setAssetOhlcvData,
  } = DataStore.useStoreActions(_ => ({
    setAssetHoldings: _.portfolio.setAssetHoldings,
    setAccountDetail: _.portfolio.setAccountDetail,
    getAccountDetail: _.portfolio.getAccountDetail,
    getAssetHoldings: _.portfolio.getAssetHoldings,
    getAssetOhlcvData: _.metaData.getAssetOhlcvData,
    setAssetOhlcvData: _.metaData.setAssetOhlcvData,
  }));
  const dashboardSelectedDialogType = AppStore.useStoreState(
    s => s.dashboardSelectedDialogType
  );
  const { setDashboardSelectedAsset, setDashboardSelectedDialogType } =
    AppStore.useStoreActions(_ => ({
      setDashboardSelectedAsset: _.setDashboardSelectedAsset,
      setDashboardSelectedDialogType: _.setDashboardSelectedDialogType,
    }));
  const allowedPortfolioActions = DataStore.useStoreState(
    s => s.portfolio.allowedActions
  );

  /**
   * Hooks
   */
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setAccountDetail(null);
  }, [setAccountDetail]);
  useEffect(() => {
    /**
     * On page load
     * - get getAssetHoldings for given id via location state
     */
    const ccyCode = location.state || location.pathname.split('/').pop();
    if (!currencyCodes.includes(ccyCode)) {
      console.info(`given asset id is not valid: ${ccyCode}`);
      navigate(APP_ROUTES.AUTH_404);
      return;
    }
    getAssetHoldings({
      currencyCode: ccyCode,
    });
    getAssetOhlcvData({
      currencyCode: ccyCode,
      timeRange: ChartTimeRange.H24,
      seriesType: ChartSeriesType.CandleStick,
    });
  }, [location.state]);
  useEffect(() => {
    // Note: @design team wants this page to not load with scrolled offset
    window.scrollTo(0, 0);

    // clean up - on leaving page
    return () => {
      setAssetHoldings(null);
      setAssetOhlcvData(null);
    };
  }, []);
  const assetInfo = useAssetInfo(assetHoldings?.currency?.code);
  const { showDialog, hideDialog } = useAppDialog();
  const { columnProps: marketAssetColumnProps } =
    useAppTableSort<MarketAssetColumns>();
  const { columnProps: marketAssetTotalColumnProps } =
    useAppTableSort<MarketAssetTotalColumns>();

  /**
   * Methods
   */
  const beginWorkflow = useCallback(
    async (
      dialogType: DASHBOARD_SELECTED_DIALOG_TYPE,
      asset: EnrichedAccountDetailAsset | EnrichedAssetHolding | null = null,
      accountId: string | null = null
    ) => {
      if (!accountId) {
        return;
      }
      const { isSuccessful, result } = await getAccountDetail({
        accountId,
        isBackgroundXHR: false,
      });
      if (!isSuccessful) {
        return;
      }

      const doesAccountHaveSelectedAsset = result?.assets.find(
        a => a.currency.code === assetHoldings?.currency.code
      );
      const assetInAccount = !asset ? doesAccountHaveSelectedAsset : asset;
      if (assetInAccount) {
        setDashboardSelectedAsset(assetInAccount);
      }
      setDashboardSelectedDialogType(dialogType);
    },
    [
      setDashboardSelectedDialogType,
      setDashboardSelectedAsset,
      getAccountDetail,
      assetHoldings?.currency,
    ]
  );
  const onWorkflowSelected = useCallback(
    (
      dialogType: DASHBOARD_SELECTED_DIALOG_TYPE,
      asset: EnrichedAccountDetailAsset | EnrichedAssetHolding | null = null,
      accountId: string | null = null
    ) => {
      if (!isKycVerified) {
        setDashboardSelectedDialogType('KYC');
        return;
      }
      if (accountId) {
        beginWorkflow(dialogType, asset, accountId);
        return;
      }
      if (accounts && accounts.length === 1) {
        beginWorkflow(dialogType, asset, accounts[0].account?.accountId);
        return;
      }
      showDialog(
        <AccountSelector
          workflowType={dialogType}
          onClose={hideDialog}
          onNext={async account => {
            hideDialog();
            beginWorkflow(dialogType, asset, account.account?.accountId);
          }}
        />
      );
    },
    [
      isKycVerified,
      showDialog,
      hideDialog,
      setDashboardSelectedDialogType,
      beginWorkflow,
    ]
  );
  const onChartTimeRangeOrSeriesTypeChanged = useCallback(
    (
      timeRange: ChartTimeRange | undefined,
      seriesType: ChartSeriesType | undefined,
      currencyCode?: string
    ) => {
      getAssetOhlcvData({
        timeRange,
        currencyCode,
        seriesType,
      });
    },
    [getAssetOhlcvData]
  );

  /**
   * DOM
   */
  if (!assetHoldings || !assetHoldings?.currency) {
    return null;
  }
  const marketAssetColumns = getMarketsAssetColumns(
    {
      ...marketAssetColumnProps,
      ...assetHoldings.currency,
    },
    onWorkflowSelected
  );
  const marketAssetTotalColumns = getMarketAssetTotalColumns(
    marketAssetTotalColumnProps
  );
  return (
    <AppContainer containerWidth="sm" containerCls="pb-4" cls="px-4 pb-8 pt-16">
      {/* busy  */}
      {busy && !dashboardSelectedDialogType && (
        <AppLoader isFixed spinnerTop="104px" />
      )}
      {/* content  */}
      <DashboardHeader>
        {assetHoldings.currency.isAssetOfTypeFund ? (
          <p className="bg-white rounded border-1 text-primary text-xl font-bold py-6 px-8">
            {assetHoldings.currency.name}
          </p>
        ) : (
          <div className="flex flex-row text-primary mb-2 px-0.5">
            <CurrencyIcon
              currencyCode={assetHoldings.currency?.code}
              showLabel={false}
              size={32}
            />
            <div className="flex flex-col ml-3">
              <div className="flex flex-row items-start text-2xl font-bold mt-0.5">
                {assetHoldings.currency?.name}
                {assetHoldings.currency.apy > 0 && (
                  <Badge.EarnApy
                    apy={assetHoldings.currency.apy}
                    prefix="Earn"
                  />
                )}
              </div>
              <span className="text-xs text-grey-darker font-normal">
                {assetHoldings.currency?.displayCode}
              </span>
            </div>
          </div>
        )}
        {!assetHoldings.currency.isAssetOfTypeFund && (
          <div className="flex flex-row text-primary mb-6 px-0.5">
            <WorkflowHeroButtons
              onSelected={onWorkflowSelected}
              canBuy={allowedPortfolioActions.includes(ActionId.Buy)}
              canSell={allowedPortfolioActions.includes(ActionId.Sell)}
              canSend={allowedPortfolioActions.includes(ActionId.Send)}
              canReceive={allowedPortfolioActions.includes(ActionId.Receive)}
              canAddCash={allowedPortfolioActions.includes(ActionId.AddCash)}
              canTransfer={allowedPortfolioActions.includes(ActionId.Transfer)}
              canWithdrawCash={allowedPortfolioActions.includes(
                ActionId.WithdrawCash
              )}
            />
          </div>
        )}
      </DashboardHeader>
      {!assetHoldings.currency.isAssetOfTypeFund && (
        <PortfolioHero
          cls="px-0 py-6"
          contentCls="px-4 md:px-8"
          title={null}
          withBorder
          amountCls="text-4xl"
          balance={Number(assetHoldings?.currency?.fxRate?.rate)}
          additionalContent={
            assetHoldings.currency.showOhlcvChart ? (
              <TradingViewChart
                percentageChange={
                  assetOhlcvRatePercentageChangeBasedOnTimeRange
                }
                data={assetOhlcvData}
                timeRanges={chartingTimeRanges}
                disabled={disableAssetOhlcvSelection}
                seriesTypes={assetOhlcvSeriesTypes}
                onChange={onChartTimeRangeOrSeriesTypeChanged}
              />
            ) : undefined
          }
        />
      )}
      <AppContainer bg="bg-white" cls="my-4 md:my-8" containerWidth="lg">
        <p className="rounded-t border-1 grey-bright text-primary text-xl font-bold py-6 px-8">
          Your{' '}
          {!assetHoldings.currency.isAssetOfTypeFund &&
            assetHoldings.currency?.displayCode}{' '}
          holdings
        </p>
        {!!assetHoldings.currency && (
          <>
            <AppTableRows<MarketAssetColumns, EnrichedAssetHolding | null>
              columns={marketAssetColumns}
              rows={assetHoldings.accounts || []}
              containerCls="rounded-0 border-t-0 border-1 grey-bright"
              cls="cursor-pointer hover-bg-grey-brighter"
              onRowClick={value =>
                navigate(
                  APP_ROUTES.AUTH_PORTFOLIO + `/${value?.account?.accountId}`,
                  {
                    replace: true,
                    relative: 'path',
                  }
                )
              }
            />
            <AppTableRows<MarketAssetTotalColumns, EnrichedAssetHoldings | null>
              columns={marketAssetTotalColumns}
              rows={[assetHoldings]}
              containerCls="border-1 border-t-0 rounded-b grey-bright"
              cls="cursor-default"
            />
          </>
        )}
        {!assetHoldings.currency && (
          <div className="bg-white border-1 grey-bright border-t-0 rounded-b">
            <p className="text-primary text-base font-semibold p-6 flex items-center justify-center">
              No Data
            </p>
          </div>
        )}
      </AppContainer>
      {!!assetInfo && (
        <AppContainer
          bg="bg-white"
          cls="my-4 md:my-8"
          withBorder
          containerWidth="lg"
        >
          <div className="py-6">
            <span className="text-primary text-xl font-bold px-6">
              What you need to know
            </span>
          </div>
          <div className="border-t-1 p-4 md:p-6">
            {/** what is it */}
            <AboutAsset
              icon="question-dot"
              title={'What is it?'}
              subText={assetInfo.description}
            />
            {/** what are it's uses */}
            {assetInfo.usecase && (
              <AboutAsset
                icon="key-facts-pencil"
                title={'What are its uses?'}
                subText={assetInfo.usecase}
              />
            )}
            {/** opportunity and risks */}
            {assetInfo.procon && (
              <AboutAsset
                icon="why-relevant-pin"
                title={'Opportunities and risks'}
                subText={assetInfo.procon}
              />
            )}
          </div>
        </AppContainer>
      )}
    </AppContainer>
  );
};

export const AssetDetail: FC = () => {
  const { id: currId } = useParams();
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);

  const validate = useCallback(() => {
    const hasCcy = currencies.find(curr => curr.code === currId);
    return currId && hasCcy;
  }, [currId, currencies]);

  return (
    <PreventNavigation validate={validate}>
      <Detail />
    </PreventNavigation>
  );
};
