import { FC } from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline';

interface Props {
  title: string;
  subtitle: string;
  href?: string;
  onClick?: () => void;
  testid?: string;
}

export const HeroLink: FC<Props> = ({
  href,
  title,
  subtitle,
  onClick,
  testid,
}) => {
  return (
    <a
      className="border-b border-grey-bright flex items-center justify-between py-6 cursor-pointer hover-border-bottom-accent"
      href={href}
      onClick={event => {
        if (!href) {
          event.preventDefault();
        }
        if (onClick) {
          onClick();
        }
      }}
      data-testid={testid}
    >
      <div>
        <h4>{title}</h4>
        <div className="typo-b1 text-gray-300">{subtitle}</div>
      </div>
      <ArrowRightIcon className="w-5 h-5" />
    </a>
  );
};
