import { FC } from 'react';
import { APP_ROUTES } from '../routes';
import { ReactComponent as WelcomeBonusIcon } from 'common/dist/assets/icons/welcome-bonus.svg';
import { useUrls } from 'common';
import { AppContainer } from '../components/app-container';
import { DASHBOARD_PARAMS } from '../components/dashboard/types';

export const WelcomeBonus: FC = () => {
  const { registerUrl, termsUrl } = useUrls();

  /**
   * DOM
   */
  return (
    <div className="text-primary py-12">
      {/* content  */}
      <AppContainer as="main">
        {/* copy  */}
        <div className="flex flex-col items-center py-8 max-w-xl mx-auto">
          {/* header  */}
          <div>
            <WelcomeBonusIcon />
          </div>
          <p className="text-3xl font-bold pt-8 text-center">
            $100 Welcome bonus when you deposit
            <br className="hidden sm:block" /> $5,000 or more for 60 days
          </p>

          <h4 className="text-primary mt-16 font-bold">
            Unlock your $100 Welcome bonus
          </h4>

          <div className="mt-12 text-gray-400">
            <div className="flex flex-row">
              <h4 className="mr-5 text-primary rounded-full border-accent border-2 w-10 h-10 flex items-center justify-center flex-shrink-0">
                1
              </h4>
              <h4>
                Register at{' '}
                <a href={registerUrl} className="text-primary underline">
                  {registerUrl}
                </a>
              </h4>
            </div>
            <div className="flex flex-row my-6">
              <h4 className="mr-5 text-primary rounded-full border-accent border-2 w-10 h-10 flex items-center justify-center flex-shrink-0">
                2
              </h4>
              <h4>
                Get verified, it only takes 10 minutes to submit your details
              </h4>
            </div>
            <div className="flex flex-row my-6">
              <h4 className="mr-5 text-primary rounded-full border-accent border-2 w-10 h-10 flex items-center justify-center flex-shrink-0">
                3
              </h4>
              <h4>
                Deposit and hold $5,000 or more (either in crypto or directly
                from your bank) into your account for 60 days
              </h4>
            </div>
            <div className="flex flex-row">
              <h4 className="mr-5 text-primary rounded-full border-accent border-2 w-10 h-10 flex items-center justify-center flex-shrink-0">
                4
              </h4>
              <h4>
                After 60 days, 100 USDC will be credited to you automatically
              </h4>
            </div>
          </div>

          {/* terms of use  */}
          <div className="my-6">
            <a
              href={termsUrl}
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              <h4>Terms and Conditions</h4>
            </a>
          </div>
          <div className="mt-4">
            <a
              href={
                APP_ROUTES.AUTH_PORTFOLIO + '/' + DASHBOARD_PARAMS.WELCOME_BONUS
              }
              className="app-button-accent"
            >
              Make a deposit
            </a>
          </div>
        </div>
      </AppContainer>
    </div>
  );
};
