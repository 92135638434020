import { DEFAULTS, FIAT_SYMBOLS } from '../constants';
import { formatAmount } from './format-amount';

export function formatMoney(
  amount: string,
  currencyCode: string,
  decimals: number = DEFAULTS.DECIMAL_SCALE
) {
  // TOFIX: Sometimes `currencyCode` here, is `displayCode`.
  // The below works because fiat currencies codes are the same as their display codes.
  // Ideally we should pass both `currencyCode` and `displayCode` to the function
  const symbol = FIAT_SYMBOLS[currencyCode];
  const amountFormatted = formatAmount(amount, decimals);

  if (symbol) {
    const sign = amountFormatted.charAt(0);

    if (sign === '+' || sign === '-') {
      return `${sign}${symbol}${amountFormatted.slice(1)}`;
    }

    return `${symbol}${amountFormatted}`;
  }

  return `${amountFormatted} ${currencyCode}`;
}
