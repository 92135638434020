import React, { FC } from 'react'
import { OpenLayout } from '../layouts/open-layout'
import { FallbackCta } from '~/components/404/fallback-cta'
import { APP_ROUTES } from '~/routes'

export const Restricted404: FC = () => {
	return (
		<OpenLayout
			icon="search-off"
			title="We couldn’t find the page you are looking for"
			cta={
				<FallbackCta
					href={APP_ROUTES.NON_AUTH_HOME}
					target="_self"
					label="Go to home page"
				/>
			}
		>
			The page you are looking for doesn’t exist or has been moved
		</OpenLayout>
	)
}
