import { FC } from 'react';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '~/routes';
import {
  formatDateShort,
  referAFriendBonusLastDate,
  AppIcon,
  useUrls,
  useEnvironmentConfig,
} from 'common';
import { Widgetcard } from './widget-card';
import { DataStore } from '~/store';
import { CopyableText } from '../copyable-text';

interface Props {
  type: 'primary' | 'secondary';
}

export const isWidgetReferAFriendRelevant = () =>
  referAFriendBonusLastDate.getTime() > new Date().getTime();

export const WidgetReferAFriend: FC<Props> = ({ type = 'primary' }) => {
  /**
   * Store
   */
  const allowReferralRewards = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.allowReferralRewards
  );
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);

  /**
   * Hooks
   */
  const { registerUrl } = useUrls();
  const { REACT_APP_SHOW_WAITING_LIST } = useEnvironmentConfig();
  const showWaitlist = !!(REACT_APP_SHOW_WAITING_LIST === 'true');

  /**
   * DOM
   */
  if (!allowReferralRewards) {
    // hide refer a friend widget if - no allowReferralRewards
    return null;
  }
  const referralCode = decodedToken?.referralCode;
  if (type === 'primary') {
    return (
      <Widgetcard
        icon="friend-referral"
        title="Refer a friend"
        subText={`Share Stablehouse with your friends and earn 40 USDC when they fund an account with at least $100 and hold the funds for 30 days.`}
        ctaText={
          <div className="flex flex-col">
            <span className="text-primary text-center text-sm pb-2.5 border-b">{`${registerUrl}?ref=${referralCode}`}</span>
            <CopyableText
              text={
                showWaitlist
                  ? referralCode
                  : `${registerUrl}?ref=${referralCode}`
              }
              showText={false}
              btnCls={'app-button-outline text-sm w-full w-336 mt-4'}
              beforeCopyText="Copy link"
            />
          </div>
        }
      />
    );
  }
  return (
    <>
      <AppIcon icon="friend-referral" size="lg" />
      <div className="flex flex-col w-72">
        <Link
          to={APP_ROUTES.AUTH_REFER_A_FRIEND}
          className="focus:outline-none focus:ring-transparent flex items-center"
        >
          <div className="flex flex-col justify-end items-start">
            <span className="text-2xl font-bold">Refer a friend</span>
            <span className="text-gray-400 text-sm text-left">
              Recommend a friend to Stablehouse and you&#39;ll both earn 40
              USDC. <br />
              Ends {formatDateShort(referAFriendBonusLastDate)}.{' '}
              <span className="text-primary underline">Get started</span>
            </span>
          </div>
        </Link>
      </div>
    </>
  );
};
