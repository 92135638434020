import { API } from 'api';
import { FC, useEffect, useState } from 'react';
import { DataStore } from '../../store';
import { AppIcon, AppDialog } from 'common';
import { BankAccountList } from '../bank-account-list';
import { AddBankAccount } from '../bank-accounts/add';
import { DeleteBankAccount } from '../bank-accounts/delete';
import { ShowBankAccount } from '../bank-accounts/show';
import { VerifyCode } from '../verify-code';

export const SettingsPaymentMethods: FC = () => {
  /**
   * Store
   */
  const bankAccounts = DataStore.useStoreState(s => s.settings.bankAccounts);
  const getBankAccounts = DataStore.useStoreActions(
    a => a.settings.getBankAccounts
  );
  const setError = DataStore.useStoreActions(a => a.setError);
  const verifyBankAccount = DataStore.useStoreActions(
    a => a.settings.verifyBankAccount
  );
  const error = DataStore.useStoreState(s => s.error);
  const resendBankAccountVerificationCode = DataStore.useStoreActions(
    a => a.settings.resendBankAccountVerificationCode
  );

  /**
   * State
   */
  const [showAddAccountDialog, setShowAddAccountDialog] = useState(false);
  const [toDeleteBankId, setToDeleteBankId] = useState<string | null>(null);
  const [toVerifyBankId, setToVerifyBankId] = useState<string | null>(null);
  const [toShowAccountId, setToShowAccountId] = useState<string | null>(null);

  const toShowAccount = (bankAccounts || []).find(
    bank => bank.id === toShowAccountId
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * Hooks
   */
  useEffect(() => {
    getBankAccounts();
  }, []);
  useEffect(() => {
    if (!toDeleteBankId) {
      setError(null);
      getBankAccounts();
    }
  }, [toDeleteBankId]);

  useEffect(() => {
    if (!toVerifyBankId) {
      setError(null);
      getBankAccounts();
    }
  }, [toVerifyBankId]);

  /**
   * DOM
   */
  const isEmpty = (bankAccounts || []).length === 0;
  const showAddBankAccount = clientUserType !== 'fund-manager';

  return (
    <div>
      {/* content  */}
      <section className="flex flex-col">
        <nav className="flex flex-row justify-between items-center py-3 px-4 md:px-8">
          <p className="font-bold">Saved bank accounts</p>
          {showAddBankAccount && (
            <button
              className="app-button-outline text-xs sm:text-sm"
              onClick={e => {
                e.preventDefault();
                setShowAddAccountDialog(true);
              }}
            >
              <span className="hidden sm:inline">Add a bank account</span>
              <span className="sm:hidden">Add new</span>
              <AppIcon cls="ml-2" icon="plus" bg="bg-transparent" size={18} />
            </button>
          )}
        </nav>
        {/* table  */}
        <main>
          {/* content  */}

          {isEmpty && (
            <div className="flex flex-col items-center">
              <AppIcon
                icon="institutions"
                size={48}
                cls="mt-10 mb-4 p-3 justify-center items-center text-secondary"
              />
              <h4 className="text-center">You have no saved bank accounts</h4>
              {showAddBankAccount && (
                <button
                  type="button"
                  className="app-button-accent flex-1 flex items-center justify-center mt-6 mb-10"
                  onClick={e => {
                    e.preventDefault();
                    setShowAddAccountDialog(true);
                  }}
                >
                  Add a bank account
                </button>
              )}
            </div>
          )}
          {!isEmpty && (
            <BankAccountList
              accounts={bankAccounts}
              onShow={bankId => setToShowAccountId(bankId)}
              onVerify={bankId => setToVerifyBankId(bankId)}
            />
          )}
        </main>
      </section>

      {/* add bank account dialog  */}
      <AppDialog
        isOpen={showAddAccountDialog}
        onClose={() => {
          setShowAddAccountDialog(false);
        }}
      >
        <AddBankAccount
          onClose={(bank: API.BankAccount | null) => {
            setShowAddAccountDialog(false);
            if (bank) {
              setToVerifyBankId(bank.id);
            }
          }}
        />
      </AppDialog>

      {/* verify bank dialog  */}
      <AppDialog
        isOpen={toVerifyBankId !== null}
        onClose={() => setToVerifyBankId(null)}
      >
        <VerifyCode
          titleMessage="Check email for verification number"
          error={error}
          onClose={() => setToVerifyBankId(null)}
          onSubmit={async code => {
            const body: API.VerifyBankAccountRequest = {
              bankAccountId: toVerifyBankId as string,
              appAuthenticatorCode: null,
              code,
            };
            const done = await verifyBankAccount(body);
            if (!done) {
              return;
            }
            getBankAccounts();
            setToVerifyBankId(null);
          }}
          onResend={async () => {
            const body: API.ResendBankAccountVerificationCodeRequest = {
              bankAccountId: toVerifyBankId as string,
            };
            const done = await resendBankAccountVerificationCode(body);
            if (!done) {
              return;
            }
          }}
        />
      </AppDialog>

      {/* delete bank account dialog  */}
      <AppDialog
        isOpen={toDeleteBankId !== null}
        onClose={() => setToDeleteBankId(null)}
      >
        <DeleteBankAccount
          bankId={toDeleteBankId as string}
          onClose={() => setToDeleteBankId(null)}
          onDelete={() => setToDeleteBankId(null)}
        />
      </AppDialog>

      {/* view bank account dialog  */}
      <AppDialog
        isOpen={toShowAccountId !== null}
        onClose={() => setToShowAccountId(null)}
      >
        <ShowBankAccount
          account={toShowAccount}
          onClose={() => setToShowAccountId(null)}
          onDelete={bankId => {
            setToShowAccountId(null);
            setToDeleteBankId(bankId);
          }}
          onVerify={bankId => setToVerifyBankId(bankId)}
        />
      </AppDialog>
    </div>
  );
};
