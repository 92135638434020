import {
  ActionId,
  AllowedWorkflowType,
  AppContainer,
  ChartSeriesType,
  ChartTimeRange,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  ERROR_MESSAGES,
  formatMoney,
  HoldingsTableColumnId,
  useUrls,
} from 'common';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppLoader } from '~/components/app-loader';
import { AppTabs } from '~/components/app-tabs/app-tabs';
import { AppTooltip } from '~/components/app-tooltip';
import { BreadCrumbsNavigation } from '~/components/dashboard/bread-crumbs-navigation';
import { TransactionsTable } from '~/components/transactions';
import { WorkflowHeroButtons } from '~/components/workflows/hero-buttons';
import { APP_ROUTES } from '~/routes';
import {
  PortfolioAccountHoldings,
  PortfolioHero,
} from '../../components/portfolio';
import { AppStore, DataStore } from '../../store';
import { useFilterTransactions } from '~/components/transactions/transactions-filters/use-filter-transactions';
import { API } from 'api';
import {
  DASHBOARD_SELECTED_DIALOG_TYPE,
  SortActivityProps,
  SortFilterTransactionsProps,
  SortHoldingsProps,
} from '~/store/types';
import { ACCOUNT_DETAIL_TAB, SortDirection, TransactionColumn } from '~/config';
import { BalanceBreakdown } from '~/components/portfolio/balance-breakdown';
import { TradingViewChart } from '~/components/charting/tv-chart';
import { useAppTabs } from '~/components/app-tabs/use-app-tabs';
import { PreventNavigation } from '~/hoc/prevent-navigation';

export type SortDataProps = {
  Activity: SortActivityProps | null;
  Holdings: SortHoldingsProps | undefined;
  FilterOptions: SortFilterTransactionsProps | undefined;
  TabIndex: number;
  Page: number;
};

export type TransactionSortProps = {
  sortColumn: API.SortColumn | null;
  sortDirection: API.SortDirection;
};

export const initSortInfo = {
  Page: 1,
  TabIndex: 0,
  FilterOptions: undefined,
  Activity: {
    column: TransactionColumn.Date,
    direction: SortDirection.DESC,
  },
  Holdings: {
    column: HoldingsTableColumnId.Value,
    direction: SortDirection.DESC,
  },
};

export const AccountDetailInner: FC = () => {
  /**
   * Store
   */
  const isKycVerified = DataStore.useStoreState(
    s => s.user.isKycVerified ?? false
  );
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const accountError = DataStore.useStoreState(s => s.portfolio.error);
  const balances = DataStore.useStoreState(s => s.portfolio.accountBalances);
  const accountBalanceHistoryTimeRanges = DataStore.useStoreState(
    s => s.portfolio.accountBalanceHistoryTimeRanges
  );
  const accountBalanceHistorySeriesTypes = DataStore.useStoreState(
    s => s.portfolio.accountBalanceHistorySeriesTypes
  );
  const disableAccountBalanceHistorySelection = DataStore.useStoreState(
    s => s.portfolio.disableAccountBalanceHistorySelection
  );
  const accountBalanceHistory = DataStore.useStoreState(
    s => s.portfolio.accountBalanceHistory
  );
  const getAccountBalanceHistory = DataStore.useStoreActions(
    _ => _.portfolio.getAccountBalanceHistory
  );
  const setAccountDetail = DataStore.useStoreActions(
    _ => _.portfolio.setAccountDetail
  );
  const getAccountDetail = DataStore.useStoreActions(
    _ => _.portfolio.getAccountDetail
  );
  const transactions = DataStore.useStoreActions(_ => _.transactions);

  const setDashboardSelectedAsset = AppStore.useStoreActions(
    _ => _.setDashboardSelectedAsset
  );
  const setDashboardSelectedDialogType = AppStore.useStoreActions(
    _ => _.setDashboardSelectedDialogType
  );

  /**
   * State
   */
  const [isFetching, setIsFetching] = useState(false);

  /**
   * Hooks
   */

  const { tabIndex, handleOnTabClicked } = useAppTabs(ACCOUNT_DETAIL_TAB);
  const navigate = useNavigate();
  const { id: accId } = useParams();

  const { resetFilters } = useFilterTransactions();
  const { zendeskHowEarningWorksUrl, zendeskInterestPaidOutUrl } = useUrls();
  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        return;
      }
      if (isFetching) {
        return;
      }
      if (!accId) {
        return;
      }
      setIsFetching(true);
      transactions.resetPaginatedActivitiesFilters();
      transactions.setPaginatedActivities(null);
      resetFilters();
      await getAccountDetail({
        accountId: accId,
      });
      setIsFetching(false);
    })();
  }, [accId]);

  useEffect(() => {
    return () => {
      handleOnAccountSelected();
    };
  }, []);

  useEffect(() => {
    if (accountError === ERROR_MESSAGES.INVALID_ACCOUNT_ID) {
      navigate(APP_ROUTES.AUTH_404);
    }
  }, [accountError, navigate]);

  /**
   * Methods
   */
  const onChartTimeRangeOrSeriesTypeChanged = useCallback(
    (
      timeRange: ChartTimeRange | undefined,
      seriesType: ChartSeriesType | undefined
    ) => {
      if (!accId) {
        return;
      }
      getAccountBalanceHistory({
        accountId: accId,
        isBackgroundXHR: false,
        timeRange,
        seriesType,
      });
    },
    [getAccountBalanceHistory, accId]
  );
  const handleOnAction = useCallback(
    (
      asset: EnrichedAccountDetailAsset | EnrichedAssetHolding | null,
      action: DASHBOARD_SELECTED_DIALOG_TYPE | AllowedWorkflowType | null
    ) => {
      if (!action && asset) {
        setAccountDetail(null);
        const url = `${APP_ROUTES.AUTH_ASSET}/${asset.currency.code}`;
        navigate(url, {
          state: asset.currency.code,
        });
      }
      if (action && setDashboardSelectedAsset) {
        setDashboardSelectedAsset(asset);
      }
      if (action && setDashboardSelectedDialogType) {
        setDashboardSelectedDialogType(action);
      }
    },
    [setAccountDetail, navigate]
  );
  const onWorkflowSelected = useCallback(
    (dialogType: DASHBOARD_SELECTED_DIALOG_TYPE) => {
      if (!isKycVerified) {
        setDashboardSelectedDialogType('KYC');
        return;
      }
      setDashboardSelectedDialogType(dialogType);
    },
    [isKycVerified]
  );
  const handleOnAccountSelected = useCallback(() => {
    transactions.setPaginatedActivities(null);
    transactions.resetPaginatedActivitiesFilters();
  }, []);

  const allowedPortfolioActions = accountDetail?.allowedActions;

  /**
   * DOM
   */
  if (!accountDetail || isFetching) {
    return <AppLoader isFixed spinnerTop="104px" />;
  }
  return (
    <>
      <BreadCrumbsNavigation
        title="Portfolio"
        onAccountSelected={handleOnAccountSelected}
      />
      <PortfolioHero
        wrapperCls="p-6"
        cls="px-0 py-6"
        withBorder
        contentCls="px-4 md:px-8"
        balanceChange={accountDetail?.balanceChange}
        title={
          <p className="font-bold text-gray-400 flex gap-x-3 items-center text-sm">
            <span className="text-primary">
              {accountDetail?.account?.label}
            </span>
            <span className="text-sm font-normal text-grey-darker">
              {accountDetail?.account?.accountNumber}
            </span>
          </p>
        }
        titleCls="px-4 md:px-8"
        balance={Number(accountDetail?.totalBalanceIncludingPendingUsd)}
        additionalContent={
          <>
            <div className="flex flex-col lg:mt-1 text-primary gap-1">
              <div className="mt-2 px-4 md:px-8">
                {!!balances && accountDetail.showBalancesButton && (
                  <BalanceBreakdown {...balances} title="Account breakdown" />
                )}
                <div className="mt-6">
                  <WorkflowHeroButtons
                    onSelected={onWorkflowSelected}
                    canBuy={allowedPortfolioActions?.includes(ActionId.Buy)}
                    canSell={allowedPortfolioActions?.includes(ActionId.Sell)}
                    canSend={allowedPortfolioActions?.includes(ActionId.Send)}
                    canReceive={allowedPortfolioActions?.includes(
                      ActionId.Receive
                    )}
                    canAddCash={allowedPortfolioActions?.includes(
                      ActionId.AddCash
                    )}
                    canTransfer={allowedPortfolioActions?.includes(
                      ActionId.Transfer
                    )}
                    canWithdrawCash={allowedPortfolioActions?.includes(
                      ActionId.WithdrawCash
                    )}
                  />
                </div>
              </div>
              {accountDetail.enableBalanceGraph && (
                <TradingViewChart
                  disabled={disableAccountBalanceHistorySelection}
                  data={accountBalanceHistory}
                  timeRanges={accountBalanceHistoryTimeRanges}
                  seriesTypes={accountBalanceHistorySeriesTypes}
                  onChange={onChartTimeRangeOrSeriesTypeChanged}
                  containerHeight={385}
                />
              )}
            </div>
            {accountDetail?.account?.isYieldEligible && (
              <div className="flex flex-col sm:flex-row gap-y-4 gap-x-32 border-t py-6 mt-6">
                <span className="flex flex-col">
                  <span className="flex flex-row gap-x-2">
                    <span className="text-sm text-grey-darker font-bold mb-1.5 leading-normal">
                      Total interest earned
                    </span>
                    <AppTooltip
                      effect="solid"
                      place="top"
                      event="click"
                      cls="mt-1"
                      content={
                        <div className="my-1">
                          <p className="font-bold">
                            <span>
                              This is the total amount of interest you’ve earned
                              up until your last Friday payout, and does not
                              include the interest you are currently accruing.
                            </span>
                            <span className="flex flex-row">
                              See{' '}
                              <a
                                href={zendeskHowEarningWorksUrl}
                                rel="help"
                                className="flex flex-row hover:underline ml-1"
                              >
                                FAQs
                              </a>
                            </span>
                          </p>
                        </div>
                      }
                    />
                  </span>
                  <span className="font-semibold text-3xl md:text-4xl text-primary">
                    {formatMoney(
                      accountDetail.earnedInterestTotalUsd ?? '0',
                      'USD'
                    )}
                  </span>
                </span>

                <span className="flex flex-col">
                  <span className="flex flex-row gap-x-2">
                    <span className="text-sm text-grey-darker font-bold mb-1.5 leading-normal">
                      Accruing interest
                    </span>
                    <AppTooltip
                      effect="solid"
                      place="top"
                      event="click"
                      cls="mt-1"
                      content={
                        <div className="my-1">
                          <p className="font-bold">
                            <span>
                              This represents the interest that you have been
                              accruing since your last payout.
                            </span>
                            <span className="flex flex-row">
                              See{' '}
                              <a
                                href={zendeskInterestPaidOutUrl}
                                rel="help"
                                className="flex flex-row hover:underline ml-1"
                              >
                                FAQs
                              </a>
                            </span>
                          </p>
                        </div>
                      }
                    />
                  </span>
                  <span className="font-semibold text-3xl md:text-4xl text-primary">
                    {formatMoney(
                      accountDetail.earnedInterestThisWeekUsd ?? '0',
                      'USD'
                    )}
                  </span>
                </span>
              </div>
            )}
          </>
        }
      />
      <AppContainer wrapperCls="px-6 pb-6" cls="text-primary">
        <AppTabs
          headerItemCls="border-primary"
          borderCls=""
          tabs={[
            <div key={ACCOUNT_DETAIL_TAB.HOLDINGS} className="tab-header">
              {ACCOUNT_DETAIL_TAB.HOLDINGS}
            </div>,
            <div key={ACCOUNT_DETAIL_TAB.ACTIVITY} className="tab-header">
              {ACCOUNT_DETAIL_TAB.ACTIVITY}
            </div>,
          ]}
          panels={[
            <div key={ACCOUNT_DETAIL_TAB.HOLDINGS}>
              <PortfolioAccountHoldings
                accountDetail={accountDetail}
                onAction={handleOnAction}
              />
            </div>,
            <div key={ACCOUNT_DETAIL_TAB.ACTIVITY}>
              <TransactionsTable />
            </div>,
          ]}
          selectedIndex={tabIndex}
          onChange={handleOnTabClicked}
        />
      </AppContainer>
    </>
  );
};

export const AccountDetail: FC = () => {
  const { id: accId } = useParams();
  const accountIds = DataStore.useStoreState(s => s.portfolio.accountIds);

  const validate = useCallback(() => {
    return accId && accountIds.includes(accId);
  }, [accId, accountIds]);

  return (
    <PreventNavigation validate={validate}>
      <AccountDetailInner />
    </PreventNavigation>
  );
};
