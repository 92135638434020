import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { AppTabs } from '../app-tabs/app-tabs';
import { SettingsSecurity2faWizard } from './settings-security-2fa-wizard';
import { SettingsSecurityChangePassword } from './settings-security-change-password';
import { Auth0 } from '~/utils/auth0';
import { SettingsSecurityAuth02ChangePassword } from './settings-security-auth0-change-password';
import { SettingsSecurityAuth02faWizard } from './settings-security-auth0-2fa-wizard';

export const securitySettingsTabs = {
  Password: 'password',
  TwoFactorAuthentication: '2fa',
};

export const tabs = [
  securitySettingsTabs.Password,
  securitySettingsTabs.TwoFactorAuthentication,
];

export const SettingsSecurityOverview: FC = () => {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const isUsingAuth0 = Auth0.useIsAuthenticated();

  /**
   * DOM
   */
  const panels = isUsingAuth0
    ? [
        <SettingsSecurityAuth02ChangePassword key={'tab1'} />,
        <SettingsSecurityAuth02faWizard key={'tab2'} />,
      ]
    : [
        <SettingsSecurityChangePassword key={'tab1'} />,
        <SettingsSecurity2faWizard key={'tab2'} />,
      ];
  return (
    <div>
      {/* change password */}
      <AppTabs
        headerContainerCls="px-8 pt-1.5 h-20"
        headerItemCls="flex flex-col justify-center"
        panelContainerCls="px-8"
        tabs={[
          <div key={'tab1'} className="font-bold text-base md:text-xl">
            Change password
          </div>,
          <div key={'tab2'} className="font-bold text-base md:text-xl">
            Two-factor authentication
          </div>,
        ]}
        defaultIndex={0}
        onChange={index => {
          const uri = `${APP_ROUTES.AUTH_SETTINGS}/security/${tabs[index]}`;
          navigate(uri);
        }}
        panels={panels}
      />
    </div>
  );
};
