import { Formik } from 'formik';
import { FC, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { API } from 'api';
import {
  AddAddressFormikField,
  AddAddressFormikProps,
  BlockchainNetworkName,
  EnrichedCurrencyInformation,
  FormHeader,
  TIMERS,
  validateAddAddressFormik,
} from 'common';
import { apiClient } from '~/api/client';
import { DataStore } from '~/store';
import { Error } from '~/components/common/error';
import { SelectField } from '~/components/forms/select-field';
import {
  currencyItemTemplate,
  currencySelectedItemTemplate,
} from '~/components/app-selector/templates';
import { DebounceField } from '~/components/forms/debounce-field';
import { TextField } from '~/components/forms/text-field';
import { AddCryptoAddressSteps } from './steps';
import { ClosableStepProps } from './types';
import { createAddress } from './helper';
import { Auth0 } from '~/utils/auth0';

export const Form: FC<ClosableStepProps> = ({
  goToNamedStep,
  onClose,
  currencyCode,
}) => {
  /**
   * Store
   */
  const cryptoAddressesError = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.error
  );
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );
  const formValues = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.formValues
  );
  const allowedCurrencies = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.allowedCurrencies
  );

  const { setFormValues, createWithdrawalAddress, setCryptoAddressesError } =
    DataStore.useStoreActions(_ => ({
      setFormValues: _.settings.cryptoAddresses.setFormValues,
      createWithdrawalAddress:
        _.settings.cryptoAddresses.createWithdrawalAddress,
      setCryptoAddressesError: _.settings.cryptoAddresses.setError,
    }));

  if (!goToNamedStep) {
    return null;
  }

  /**
   * Methods
   */
  const onFormValidate = useCallback(
    (values: AddAddressFormikProps) => {
      setCryptoAddressesError(null);
      const errors = validateAddAddressFormik(
        values,
        apiClient as API.StablehouseClient,
        decodedTokenAccountId
      );
      return errors;
    },
    [setCryptoAddressesError, validateAddAddressFormik]
  );

  const onFormSubmit = async (values: AddAddressFormikProps) => {
    // set form values and go to next screen
    setFormValues(values);

    const isAuth0 = await Auth0.isAuthenticated();
    const shouldMfa = is2FaEnabled || isAuth0;
    if (shouldMfa) {
      if (isAuth0) {
        const token = await Auth0.triggerMfa();
        if (!token) {
          return;
        }
      } else {
        goToNamedStep(AddCryptoAddressSteps.Validate2FA);
        return;
      }
    }

    await createAddress(values, undefined, createWithdrawalAddress, step => {
      goToNamedStep(step);
    });
  };

  /**
   * DOM
   */
  const initialValues: AddAddressFormikProps = {
    walletAddress: formValues?.walletAddress || '',
    label: formValues?.label || '',
    currency: formValues?.currency || null,
    network: formValues?.network || null,
  };
  return (
    <div className="relative">
      {/* header  */}
      <FormHeader title="Add address" />

      {/* form  */}
      <Formik<AddAddressFormikProps>
        initialValues={initialValues}
        validate={onFormValidate}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange
        onSubmit={onFormSubmit}
      >
        {({
          errors,
          isValidating,
          submitForm,
          dirty,
          handleBlur,
          setFieldValue,
          values,
          isSubmitting,
        }) => {
          /**
           * Methods
           */

          useEffect(() => {
            if (!currencyCode) return;
            const selectedCurrencyCode = allowedCurrencies?.find(
              ({ code }) => code === currencyCode
            );

            if (!selectedCurrencyCode) return;
            onCurrencyChanged(selectedCurrencyCode);
          }, [currencyCode]);

          const onCurrencyChanged = async (
            newValue: EnrichedCurrencyInformation
          ) => {
            if (isValidating) {
              return;
            }

            await setFieldValue(
              AddAddressFormikField.network,
              newValue.blockchain,
              false
            );

            await setFieldValue(AddAddressFormikField.currency, newValue, true);
          };
          const onCancelButtonClicked = (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ) => {
            e.preventDefault();
            onClose();
          };
          /**
           * Form DOM
           */
          if (!currencies) {
            return null;
          }
          const isDisabled =
            isValidating ||
            cryptoAddressesError !== null ||
            Object.keys(errors).length > 0 ||
            !dirty ||
            isSubmitting;
          return (
            <>
              {/* fields  */}
              <div className="my-6 px-10">
                {/* funds dropdown  */}
                <div
                  className={cx('flex flex-col mb-2 sm:mb-0 sm:flex-1 mt-3')}
                >
                  <SelectField
                    name={AddAddressFormikField.currency}
                    label="Choose coin"
                    values={allowedCurrencies}
                    getSelectedItemTemplate={item =>
                      currencySelectedItemTemplate(item, true)
                    }
                    getItemTemplate={(
                      fund,
                      selected: boolean,
                      active: boolean
                    ) => currencyItemTemplate(fund, selected, active, true)}
                    disabled={!!currencyCode}
                    onChange={onCurrencyChanged}
                  />
                </div>

                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <DebounceField
                      name={AddAddressFormikField.walletAddress}
                      label={'Wallet address'}
                      disabled={!values.currency}
                      debounceTimeout={TIMERS.INPUT_DEBOUNCE}
                      onBlur={handleBlur}
                      spellCheck={false}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <TextField
                      name={AddAddressFormikField.label}
                      label={'Address name'}
                      maxLength={27}
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <SelectField
                      name={AddAddressFormikField.network}
                      label="Choose network"
                      disabled
                      values={Object.values(BlockchainNetworkName)}
                    />
                  </div>
                </div>
              </div>

              {/* api errors  */}
              <Error message={cryptoAddressesError} cls={`mx-10`} />

              {/* actions  */}
              <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
                {/* cancel button  */}
                <button
                  className="app-button-outline w-full mr-3 flex-1"
                  onClick={onCancelButtonClicked}
                >
                  Cancel
                </button>
                {/* withdraw button  */}
                <button
                  disabled={isDisabled}
                  type="button"
                  className="app-button-primary w-full flex-1"
                  onClick={submitForm}
                >
                  Add address
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
