import { FC } from 'react';
import {
  Note,
  formatMoney,
  CurrencyIcon,
  SelectInput,
  EnrichedAccountDetailAsset,
  FormHeader,
} from 'common';
import {
  accountItemTemplate,
  singleFundItemTemplate,
  accountSelectedItemTemplate,
} from '~/components/app-selector/templates';
import { PayInvoicesNavigationProps } from './pay-invoices-navigation';
import { StepWizardChildProps } from 'react-step-wizard';
import { getAccountInfo } from '~/utils/get-account-details';

export const PayInvoices: FC<
  Partial<StepWizardChildProps> & PayInvoicesNavigationProps
> = ({
  error,
  onClose,
  nextStep,
  account,
  accounts,
  assetOfKind = [],
  invoiceToPay,
  onAssetChange,
  onAccountChange,
  payInvoiceOptions,
  disableAssetSelection,
}) => {
  /**
   * DOM
   */

  const getCurrencyTemplate = (code: string) => {
    const currency = assetOfKind.find(i => i.currency.code === code);
    if (!currency) {
      return <></>;
    } else {
      return (
        <div className={`p-0 flex items-center`}>
          <CurrencyIcon
            currencyCode={currency.currency.code}
            apy={currency.currency.apy}
            currencyName={`${currency.formatted.balance} available`}
          />
        </div>
      );
    }
  };

  const currencyItemTemplate = (
    fund: EnrichedAccountDetailAsset,
    selected: boolean,
    active: boolean
  ) => singleFundItemTemplate(fund, selected, active);

  return (
    <div className="relative text-primary h-full">
      {/* header  */}
      <FormHeader
        title={`Pay Invoice`}
        onBack={onClose}
        accountInfo={getAccountInfo(account)}
      />
      <div className="flex flex-col px-6 sm:px-10">
        <div className="flex flex-col gap-3.5 sm:gap-7">
          {/* invoice payment period */}
          <p className="flex flex-col sm:gap-1.5">
            <label className="text-gray-400">Month</label>
            {invoiceToPay?.creationDate && (
              <span className="font-semibold text-lg md:text-xl text-primary md:leading-tight">
                {invoiceToPay?.formattedDueMonth}
              </span>
            )}
          </p>

          {/* invoice payment amount */}
          <p className="flex flex-col sm:gap-1.5">
            <label className="text-gray-400">Amount to Pay</label>
            <span className="font-semibold text-lg md:text-xl text-primary md:leading-tight">
              {formatMoney(invoiceToPay?.amount.toString() ?? '0', 'USD')}
            </span>
          </p>
        </div>
        {/* choose account */}
        <div className="flex flex-col mt-7 gap-6">
          <div className="flex flex-col flex-1">
            <label className="pb-2 text-sm text-gray-400">Pay using</label>
            <SelectInput
              name={'account'}
              values={accounts}
              onChange={onAccountChange}
              value={payInvoiceOptions?.account}
              getItemTemplate={accountItemTemplate}
              getSelectedItemTemplate={accountSelectedItemTemplate}
            />
          </div>
          <div className="pb-10">
            {/* choose asset */}
            <div className="flex flex-col flex-1">
              <label className="pb-2 text-sm text-gray-400">Choose Asset</label>
              <SelectInput
                name={'asset'}
                values={assetOfKind}
                onChange={onAssetChange}
                disabled={disableAssetSelection}
                getItemTemplate={currencyItemTemplate}
                getItemValue={fund => fund.currency.code}
                getSelectedItemTemplate={getCurrencyTemplate}
                value={payInvoiceOptions?.paymentAssetAccepted}
              />
            </div>
            {!!error && (
              <Note cls="mt-2 text-xs font-bold text-failure">
                {error}
                {/**Todo - workflow */}
                {/* <span> Add cash</span> */}
              </Note>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-6 px-8 sm:px-10">
        {/* preview  */}
        <button
          role="button"
          type="button"
          disabled={
            !Object.values(payInvoiceOptions || {}).every(v => v) || !!error
          }
          className="app-button-primary button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
          onClick={nextStep}
        >
          Preview
        </button>
      </div>
    </div>
  );
};
