export function maskHash(hash: string, trailingLength?: number) {
  if (trailingLength && trailingLength > 0) {
    return `XXXX-${hash.slice(-trailingLength)}`;
  }

  if (hash.length > 18) {
    return `${hash.slice(0, 10)}...${hash.slice(-5)}`;
  }

  return hash;
}
