import * as React from 'react';
import cx from 'classnames';
import { BaseComponentProps } from 'common';

interface Props extends BaseComponentProps {
  muted?: boolean;
  big?: boolean;
  className?: string;
  noMargin?: boolean;
}

export const KycSubtitle: React.FC<Props> = props => {
  return (
    <div
      className={cx(
        props.muted && 'text-gray-400',
        !props.noMargin && 'mb-5',
        'text-center',
        props.big && 'text-3xl',
        !props.big && 'text-lg',
        props.className
      )}
      // just following DDS's design...
      style={{ letterSpacing: '-0.01em' }}
    >
      {props.children}
    </div>
  );
};
