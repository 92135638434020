import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { DetailsCrypto } from './details/crypto';
import { FormHeader, FundShowDepositDetails } from 'common';
import { ReceiveProps } from '.';
import { getAccountInfo } from '~/utils/get-account-details';

export const Preview: FC<
  Partial<StepWizardChildProps & FundShowDepositDetails> & ReceiveProps
> = ({ fundDetailsType, chooseCurrency, chooseMethod, chooseNetwork }) => {
  /**
   * Store
   */
  const formValues = DataStore.useStoreState(s => s.receive.formValues);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);

  /**
   * DOM
   */
  if (!formValues || !accountDetail) {
    return null;
  }
  const title = fundDetailsType
    ? `Crypto deposit address`
    : `Receive ${formValues?.toAsset?.displayCode || ''}`;
  return (
    <div
      data-testid="receive-preview"
      className="flex flex-col text-primary mb-6"
    >
      {/* header  */}
      <FormHeader
        title={title}
        accountInfo={getAccountInfo(accountDetail.account, 'To')}
      />
      {/* summary  */}
      <DetailsCrypto
        chooseMethod={chooseMethod}
        chooseNetwork={chooseNetwork}
        chooseCurrency={chooseCurrency}
      />
    </div>
  );
};
