import { parse } from 'query-string';
import { FC } from 'react';
import { useAsync } from 'react-async-hook';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppIcon } from 'common';
import { AppInlineLoader } from '../../components/app-loader/index';
import { KycTitle } from '../../components/kyc/kyc-title';
import { KycWizardLayout } from '../../components/kyc/kyc-wizard-layout';
import { OnboardingLayout } from '../../components/layouts/onboarding';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';

/**
 * TODO: this whole page needs design re-work
 */
export const ConfirmEmailAddress: FC = () => {
  /**
   * Store
   */
  const verifyEmailAddress = DataStore.useStoreActions(
    a => a.user.verifyEmailAddress
  );

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = parse(search);
  const { loading, status } = useAsync(async () => {
    await verifyEmailAddress(parsed && (parsed.token as string));
    navigate(APP_ROUTES.AUTH_EMAIL_VERIFICATION_SUCCESSFUL);
  }, [parsed && parsed.token]);

  /**
   * DOM
   */
  return (
    <OnboardingLayout testid="page-confirm-email-address" userIcon={false}>
      <KycWizardLayout title={''} loading={false}>
        <div className="text-center">
          <div className="flex items-center justify-center mb-8">
            <AppIcon icon="circle-shield-cross" size="lg" />
          </div>
          {/* status = error  */}
          {status === 'error' && (
            <KycTitle data-testid="failure">
              This link is no longer valid.
            </KycTitle>
          )}
          {/* loading  */}
          {loading && (
            <>
              <KycTitle data-testid="loading">
                Please wait while we verify your email address...
              </KycTitle>
              <AppInlineLoader bgColor="white" />
            </>
          )}
          {/* actions  */}
          <div className="mt-5 underline font-bold mb-4">
            <Link to={APP_ROUTES.AUTH_PORTFOLIO}>Back to sign in</Link>
          </div>
        </div>
      </KycWizardLayout>
    </OnboardingLayout>
  );
};
