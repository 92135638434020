import { API } from 'api';

import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';
import { Enriched } from '../../types';

export function enrichListAccountItem(
  item: API.ListAccountItem,
  fiatCurrencyCodes: string[]
): Enriched.ListAccountItem {
  return {
    ...item,
    hasBalance: Number(item.totalUsd ?? '0') > 0,
    formatted: {
      totalUsd: formatters.getCurrency(
        item.totalUsd,
        DEFAULTS.DECIMAL_SCALE,
        'USD',
        fiatCurrencyCodes
      ),
    },
  };
}
