import { getStorageKeys, useEnvironmentConfig } from 'common';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContainer } from '../components/app-container';
import { AppIcon } from 'common';
import { AppLogo } from '../components/app-logo';
import { TwoFASetup } from '../components/two-fa-setup';
import { APP_ROUTES } from '../routes';
import { DataStore } from '../store';
import { RequireAuth } from '~/components/auth/require-auth';

export const Enforce2FASetup: FC = () => {
  /**
   * Store
   */
  const is2FaEnabled = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.is2FaEnabled
  );
  const allowBypass = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.display2FaAfterLogin?.bypass
  );

  const { resetStore } = DataStore.useStoreActions(a => ({
    resetStore: a.resetStore,
  }));

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { REACT_APP_BUILD_ENV_NAME } = useEnvironmentConfig();
  useEffect(() => {
    if (is2FaEnabled) {
      navigate(APP_ROUTES.AUTH_PORTFOLIO);
    }
  }, [is2FaEnabled]);

  /**
   * Methods
   */
  const handleOnCompleted = () => {
    resetStore();
    navigate(APP_ROUTES.AUTH_PORTFOLIO);
  };
  const handleOnBypass = () => {
    localStorage.setItem(
      getStorageKeys('web', REACT_APP_BUILD_ENV_NAME)?.user2FABypassed,
      'true'
    );
  };

  /**
   * DOM
   */
  return (
    <RequireAuth fullScreen>
      <div className="bg-grey-brighter flex flex-1">
        <div className="mx-auto max-w-screen-sm">
          <AppContainer as="main" cls="text-primary pt-6 sm:pt-12">
            <div className="flex justify-center pt-2 pb-6 md:pb-20">
              <AppLogo variant="dark" />
            </div>

            <div className="flex justify-center">
              <AppIcon
                icon="negative-circle-shield-check"
                cls="mb-5"
                size="lg"
              />
            </div>
            <p className="text-center font-bold typo-24 mb-4 text-primary">
              Two factor authentication protects your Stablehouse account and
              secures the sending and withdrawal processes.
            </p>
            <p className="text-center text-grey-darker text-sm">
              For security reasons, you will need to enable 2FA as a second
              layer of protection to your account. When you login, you&apos;ll
              be prompted to input your password and a verification code
              generated by your authenticator app.
            </p>
            <TwoFASetup
              allowBypass={allowBypass}
              onCancel={handleOnBypass}
              onCompleted={handleOnCompleted}
            />
          </AppContainer>
        </div>
      </div>
    </RequireAuth>
  );
};
