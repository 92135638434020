import { useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { AppContainer, Enriched } from 'common';
import { API } from 'api';
import { AppTablePaginator as Paginator } from '../app-table-paginator';
import { AppTableHeadings } from '../app-table/headings';
import { AppTableRows } from '../app-table/rows';
import { getTransactionsColumns } from './columns';
import { SortDirection, TransactionColumn } from '~/config';
import { useActivityFilterOptions } from './use-activity-filter-options';
import { SearchEmpty } from '~/components/search-empty/search-empty';
import { handleNavigation } from '../shared/url-params/url-params';
import { TRANSACTIONS_SORT_PARAMS_KEYS } from './transactions-filters/use-filter-transactions';
import { SortInfo } from '~/store/types';

interface Props {
  showRefresh?: boolean;
}

export const TransactionsTable = ({ showRefresh = true }: Props) => {
  /**
   * Hooks
   */
  const {
    hasData,
    csvButton,
    isLoading,
    handleGetData,
    handleRowClick,
    paginatedActivities,
  } = useActivityFilterOptions();

  const {
    applyParams,
    queryParams: { sortColumn, sortDirection, ...rest },
  } = handleNavigation<TRANSACTIONS_SORT_PARAMS_KEYS>();
  useEffect(() => {
    handleGetData({ sortColumn, sortDirection, ...rest });
  }, [
    sortColumn,
    sortDirection,
    rest?.dateEnd,
    rest?.dateStart,
    rest?.statusFilter,
    rest?.activityLabels,
    rest?.currencyCodeFilter,
  ]);

  /**
   * Variables
   */
  const columnProps = useMemo(() => {
    return {
      sort: {
        column:
          TransactionColumn[sortColumn as TransactionColumn] ||
          TransactionColumn.Date,
        direction:
          SortDirection[sortDirection as SortDirection] || SortDirection.DESC,
      },
      onSort: (value: SortInfo<TransactionColumn>) =>
        applyParams({
          sortColumn: value?.column,
          sortDirection: value?.direction,
        }),
    };
  }, [sortColumn, sortDirection, applyParams]);

  const columns = getTransactionsColumns(columnProps as never);
  const handleEmptyTable = useCallback(
    (emptyData, withData) => {
      return isLoading ? emptyData : withData;
    },
    [isLoading]
  );

  const handlePagination = useCallback(
    arg => {
      applyParams(
        {
          page: arg.page,
        },
        true,
        handleGetData
      );
    },
    [applyParams, handleGetData]
  );

  /**
   * DOM
   */
  return (
    <div className="bg-gray-100 text-primary">
      {/* content  */}
      <AppContainer cls="" containerWidth="xl">
        {/* export csv  */}
        {csvButton(showRefresh)}

        {/* transactions table header  */}
        <AppTableHeadings<
          TransactionColumn,
          Enriched.ActivityTransaction | null
        >
          borderCls={cx('border-grey-bright bg-white border', {
            'border-b-0': !isLoading && !hasData,
          })}
          columns={columns}
        />

        {/* transactions table rows  */}
        <AppTableRows<TransactionColumn, API.ActivityTransaction | null>
          columns={handleEmptyTable([], columns)}
          containerCls="border-t-0 border grey-bright"
          cellItemCls="text-sm md:text-base truncate"
          rows={handleEmptyTable(null, paginatedActivities?.result)}
          cls={'cursor-pointer hover-bg-grey-brighter py-0'}
          rowPadding="px-4 md:px-8 py-3"
          onRowClick={handleRowClick}
          emptyDataText={<SearchEmpty title="No activity found" />}
        />

        {/* footer  */}
        <Paginator
          paginatedActivities={handleEmptyTable(null, paginatedActivities)}
          onChanged={handlePagination}
        />
      </AppContainer>
    </div>
  );
};
