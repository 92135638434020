import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { NOOP } from '../../constants';
import { ERROR_MESSAGES, YUP_VALIDATIONS } from 'common';
import { BaseForm } from '../../definitions/base-form';
import { DataStore } from '../../store';
import { API } from 'api';
import { SettingsSecuritySteps } from './settings-security';
import { nameof } from '../../utils/typescript-helpers';
import { Error } from '../common/error';
import { TextField } from '../forms/text-field';
import { NumberField } from '../forms/number-field';
import {
  usePasswordLabel,
  useTogglePassword,
} from '../../hooks/use-toggle-password';

enum FormField {
  password = 'password',
  twoFaCode = 'twoFaCode',
}

interface FormProps extends BaseForm {
  [FormField.password]: string;
  [FormField.twoFaCode]: string | null;
}

const formSchema = Yup.object().shape({
  [FormField.password]: Yup.string().required(ERROR_MESSAGES.REQUIRED_VALUE),
  [FormField.twoFaCode]: YUP_VALIDATIONS.TWO_FA_CODE,
});

export const SettingsSecurity2FaEnabled: FC<Partial<StepWizardChildProps>> = ({
  goToStep = NOOP,
}) => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const error = DataStore.useStoreState(s => s.error);
  const setError = DataStore.useStoreActions(a => a.setError);
  const disable2Fa = DataStore.useStoreActions(a => a.settings.disable2Fa);
  const getAccountSettings = DataStore.useStoreActions(
    a => a.settings.getAccountSettings
  );

  /**
   * Hooks
   */
  const [showPassword, toggleShowPassword, passwordType] = useTogglePassword();

  /**
   * Methods
   */
  const unMountView = (resetForm: () => void) => {
    resetForm();
    setError(null);
    goToStep(SettingsSecuritySteps.OVERVIEW);
    getAccountSettings({
      isBackgroundXHR: false,
    });
  };
  const onFormSubmit = async (
    values: FormProps,
    { resetForm }: FormikHelpers<FormProps>
  ) => {
    if (!values.twoFaCode) {
      return;
    }
    const body: API.DisableGoogleAuthenticatorRequest = {
      code: values.twoFaCode,
      currentPassword: values.password,
    };
    const passed = await disable2Fa(body);
    if (!passed) {
      return;
    }
    unMountView(resetForm);
  };

  /**
   * DOM
   */
  const initialValues: FormProps = {
    password: '',
    twoFaCode: '',
  };
  return (
    <div>
      {/* heading  */}
      <h5 className="font-bold">Disable 2 Factor Authentication</h5>
      <hr className="bg-grey-bright my-4" />

      {/* form  */}
      <Formik<FormProps>
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({ errors, submitForm, resetForm }) => {
          /**
           * Form DOM
           */
          return (
            <div>
              {/* password  */}
              <div className={'max-w-xs'}>
                <TextField
                  type={passwordType}
                  autoComplete="current-password"
                  name={nameof<FormProps>(FormField.password)}
                  label={usePasswordLabel(
                    'Enter your password',
                    showPassword,
                    toggleShowPassword
                  )}
                />
              </div>

              {/* 2 fa input  */}
              <div className="mt-3 max-w-xs">
                <NumberField
                  name="twoFaCode"
                  label="Enter the 6 digit code from your 2FA App"
                  autoComplete="off"
                  allowLeadingZeros
                  allowNegative={false}
                  decimalScale={0}
                  thousandSeparator={false}
                />
              </div>

              {/* api errors  */}
              <Error message={error} />

              {/* action button  */}
              <div className="my-6 flex flex-row items-center">
                <button
                  disabled={busy || Object.keys(errors).length > 0}
                  className="app-button-accent"
                  onClick={submitForm}
                >
                  Disable
                </button>

                <button
                  className="app-button-outline ml-2"
                  onClick={() => unMountView(resetForm)}
                >
                  Cancel
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
