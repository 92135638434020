import { FC, useCallback, useState } from 'react';
import {
  SearchableModal,
  assetHeaderTemplate,
  useFilterCurrencies,
  assetContentTemplate,
} from 'common';
import { DataStore } from '~/store';

interface Props {
  multiSelection?: boolean;
  initialValues?: string[];
  onClose: (arg?: unknown | string[]) => void;
}

export const FilterAsset: FC<Props> = ({
  onClose,
  initialValues,
  multiSelection = true,
}) => {
  /**
   * State
   */
  const [filterAssetList, setFilterAssetList] = useState<string[]>(
    initialValues || []
  );

  /**
   * Hooks
   */
  const { currencies, filterBy, setFilterBy } = useFilterCurrencies(
    DataStore,
    []
  );

  /**
   * Methods
   */
  const handleFilterValuesUpdate = useCallback(
    ({ code }) => {
      if (!code) {
        return;
      }
      setFilterAssetList(prev =>
        prev?.includes(code) ? prev.filter(v => v !== code) : [...prev, code]
      );

      if (!multiSelection) {
        onClose([code]);
        return;
      }
    },
    [filterAssetList, multiSelection]
  );

  return (
    <div className="flex flex-col">
      {/* header  */}
      <div className="flex flex-col">
        <SearchableModal
          autoFocus
          onClose={onClose}
          values={currencies}
          onItemSelection={handleFilterValuesUpdate}
          headerTemplate={() =>
            assetHeaderTemplate({
              filterBy,
              onClick: arg => {
                if (!arg) return;
                setFilterBy(arg);
              },
            })
          }
          defaultSearchFilter={['code', 'name']}
          contentTemplate={args =>
            assetContentTemplate({
              // @ts-ignore
              ...args,
              multiSelection,
              filterAssetList,
              onItemSelection: handleFilterValuesUpdate,
            })
          }
        />
        <div className="flex flex-col sticky bottom-0 sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-6 px-8 sm:px-10">
          {/* cancel button  */}
          <button
            type="button"
            disabled={!filterAssetList?.length}
            className="app-button-outline button-lg sm:button-xl text-md sm:text-lg w-full sm:mr-3 flex-1"
            onClick={() => setFilterAssetList([])}
          >
            {multiSelection ? `Clear selection` : `Close`}
          </button>
          {/* preview  */}
          <button
            role="button"
            type="button"
            className="app-button-primary button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
            onClick={() => {
              onClose(filterAssetList);
            }}
          >
            Submit selection
          </button>
        </div>
      </div>
    </div>
  );
};
