import { useCallback, useMemo } from 'react';
import { handleNavigation } from '../shared/url-params/url-params';

export enum SEARCH_PARAMS {
  TAB_INDEX = 'tabIndex',
}

export const useAppTabs = (
  customParamOptions?: Record<string, string | number>
) => {
  /**
   * Hooks
   */
  const {
    applyParams,
    queryParams: { tabIndex },
  } = handleNavigation<SEARCH_PARAMS>();

  const handleOnTabClicked = useCallback(
    async (tab: number) => {
      applyParams({
        [SEARCH_PARAMS.TAB_INDEX]: customParamOptions
          ? Object.values(customParamOptions)[tab]
          : tab,
      });
    },
    [applyParams, customParamOptions]
  );

  /**
   * Return
   */
  return {
    tabIndex: useMemo(() => {
      return (
        customParamOptions
          ? Object.values(customParamOptions).indexOf(tabIndex as string)
          : tabIndex
      ) as number;
    }, [tabIndex, customParamOptions]),
    handleOnTabClicked,
  };
};
