import { useUrls } from 'common';
import { FC } from 'react';
import { AlreadyHaveAccount } from '../../components/common/already-have-account';
import { HeroLink } from '../../components/hero-link/hero-link';
import { ExternalSplashLayout } from '../../components/layouts/external-splash';

export const RegisterStep1AccountType: FC = () => {
  /**
   * Hooks
   */
  const { webSiteUrl } = useUrls();

  /**
   * DOM
   */
  const contactUsUrl = `${webSiteUrl}/#get-started`;
  return (
    <ExternalSplashLayout title="Open account">
      <HeroLink
        testid="personal-account"
        href={contactUsUrl}
        title="Individual Account"
        subtitle="I am an individual investing my own funds"
      />
      <HeroLink
        testid="institutional-account"
        href={contactUsUrl}
        title="Institutional Account"
        subtitle="I am a company investing corporate funds"
      />
      <AlreadyHaveAccount cls="mt-4" />
    </ExternalSplashLayout>
  );
};
