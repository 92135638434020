import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppIcon } from 'common';
import { APP_ROUTES } from '../../routes';
import { FallbackProps } from 'react-error-boundary';
import { AppContainer } from '../app-container';

export const AppErrorBoundary: FC<FallbackProps> = ({ error }) => {
  /**
   * DOM
   */
  return (
    <AppContainer cls="flex-1 text-primary flex flex-col justify-center items-center text-primary text-center my-10">
      <AppIcon icon="flag" size="lg" />
      <p className="my-8 text-2xl font-bold">Oops - something&apos;s up!</p>
      <p className="text-gray-400">
        Something went wrong.
        <br />
        <pre>{error.message}</pre>
        Just navigate back to the home and try again.
      </p>

      <Link
        role="button"
        className="app-button-primary w-full mt-12"
        style={{ width: 275 }}
        to={APP_ROUTES.AUTH_PORTFOLIO}
      >
        Back to Dashboard
      </Link>
    </AppContainer>
  );
};
