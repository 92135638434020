import { getStorageKeys, useEnvironmentConfig } from 'common';
import { FC, useEffect } from 'react';
import { AppIcon } from 'common';
import { APP_ROUTES } from '../routes';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/forms/button';
import { KycSubtitle } from '../components/kyc/kyc-subtitle';
import { KycTitle } from '../components/kyc/kyc-title';
import { KycWizardLayout } from '../components/kyc/kyc-wizard-layout';
import { OnboardingLayout } from '../components/layouts/onboarding';
import { DataStore } from '../store';
import { API } from 'api';
import { storageHelper } from '../utils/storage-helper';
import { RequireAuth } from '~/components/auth/require-auth';

export const VerifyAddress: FC = () => {
  /**
   * Store
   */
  const getUploadProofOfAddressLink = DataStore.useStoreActions(
    s => s.settings.getUploadProofOfAddressLink
  );
  const showProofOfAddressAlert = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.proofOfAddressStatus
  );

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { REACT_APP_BUILD_ENV_NAME } = useEnvironmentConfig();

  useEffect(() => {
    if (showProofOfAddressAlert !== API.ProofOfAddressStatus.RequiresPrompt) {
      navigate(APP_ROUTES.AUTH_PORTFOLIO);
    }
  }, []);

  return (
    <RequireAuth fullScreen>
      <OnboardingLayout>
        <KycWizardLayout title={''} loading={false}>
          <div className="text-center flex flex-col items-center">
            <AppIcon size="lg" icon={'kyc-shield'} cls="m-auto mb-4" />
            <KycTitle>We need to verify your address</KycTitle>
            <KycSubtitle muted>
              For compliance reasons, we need a proof of address for all our
              clients. Please click below to go through our third-party
              verification provider and follow the instructions.
            </KycSubtitle>
            <Button
              as={Button}
              onClick={async () => {
                const res = await getUploadProofOfAddressLink();
                if (!res?.link) return;
                window.location.href = res?.link;
              }}
              className="app-button-accent font-bold inline-block mt-3"
            >
              Verify my address
            </Button>
            <div className="mt-5 font-bold mb-4">
              <Link
                to={APP_ROUTES.AUTH_PORTFOLIO}
                onClick={() => {
                  storageHelper.alerts.set(
                    getStorageKeys('web', REACT_APP_BUILD_ENV_NAME)
                      ?.verifyAddressBypassed,
                    'true'
                  );
                }}
              >
                I’ll do it later
              </Link>
            </div>
          </div>
        </KycWizardLayout>
      </OnboardingLayout>
    </RequireAuth>
  );
};
