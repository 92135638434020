import { FC } from 'react';
import { RowLabelContent, formatMoney } from 'common';
import { Error } from '../../components/common/error';
import { StepWizardChildProps } from 'react-step-wizard';
import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { PayInvoicesNavigationProps } from './pay-invoices-navigation';
import { RowSection } from '~/components/workflows/shared/row-section';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import { CurrencyTransaction } from '~/components/workflows/shared/currency-transaction';

export const ConfirmInvoicesPayment: FC<
  Partial<StepWizardChildProps> & PayInvoicesNavigationProps
> = ({
  error,
  onClose,
  nextStep,
  invoiceToPay,
  payInvoiceOptions,
  previousStep,
  handlePreview,
}) => {
  /**
   * DOM
   */
  if (!invoiceToPay) return null;

  return (
    <div className="relative text-primary h-full">
      <div className="flex flex-col px-6 lg:px-8">
        {/* header  */}
        <DashboardHeader headerPadding="pt-14">
          <div className="mt-9 flex flex-row">
            <button onClick={previousStep} type="button" aria-label="go back">
              <ArrowSmLeftIcon className="w-8 h-8 mr-3 absolute top-9 z-30" />
            </button>
            <h3 className="font-semibold typo-20 md:typo-24 ml-12 absolute top-9 z-30 pr-20">
              Pay Invoice
            </h3>
          </div>
        </DashboardHeader>
        {/* api errors  */}
        <Error message={error} cls="mt-3" />

        <div className="flex flex-col mt-3 gap-x-6">
          {/* invoice period */}
          <RowLabelContent
            showColon={false}
            cls="border-none"
            label={`Invoice month/year`}
            content={invoiceToPay?.formattedDueMonth}
          />
          {/* invoice number */}
          <RowLabelContent
            showColon={false}
            label={`Invoice number`}
            content={
              <>
                <RowSection
                  title={String(invoiceToPay.number)}
                  description=""
                />
              </>
            }
          />
          {/* from */}
          <RowLabelContent
            showColon={false}
            label={`From`}
            cls="mb-3"
            content={
              <div className="flex flex-col text-primary items-end gap-y-2">
                <CurrencyTransaction
                  positive={false}
                  amount={formatMoney(
                    invoiceToPay.amount.toString(),
                    invoiceToPay.quoteAssetCode ?? 'USD'
                  )}
                />
                <div>
                  <RowSection
                    title={payInvoiceOptions?.account?.account?.label || ''}
                    description={
                      <span className="flex flex-row gap-1">
                        <span>
                          {payInvoiceOptions?.account?.account?.accountType ||
                            ''}
                        </span>
                        <span>
                          {payInvoiceOptions?.account?.account?.accountNumber ||
                            ''}
                        </span>
                      </span>
                    }
                  />
                </div>
              </div>
            }
          />
          {/* to */}
          <RowLabelContent
            showColon={false}
            label={`To`}
            cls="mb-3"
            content={
              <div className="flex flex-col text-primary items-end gap-y-1.5">
                <CurrencyTransaction
                  amount={formatMoney(
                    invoiceToPay.amount.toString(),
                    invoiceToPay.quoteAssetCode || 'USD'
                  )}
                />
              </div>
            }
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-6 px-8 sm:px-10">
        {/* cancel button  */}
        <button
          type="button"
          className="app-button-outline button-lg sm:button-xl text-md sm:text-lg w-full sm:mr-3 flex-1"
          onClick={onClose}
        >
          Cancel
        </button>
        {/* preview  */}
        <button
          role="button"
          type="button"
          className="app-button-accent button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
          onClick={async () => {
            if (!nextStep) return;
            const res = await handlePreview();
            if (res as unknown) {
              nextStep();
            }
          }}
        >
          Pay invoice
        </button>
      </div>
    </div>
  );
};
