import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DataStore } from '~/store';
import {
  FilterFormikProps,
  AppContainer,
  AppDialog,
  AppIcon,
  EnrichedInvoice,
  InvoiceStatusTitle,
  StatusIndicator,
  getActivityStatus,
  useAppDialog,
  invoiceTypeLabels,
  getInvoiceBalanceError,
  Enriched,
} from 'common';
import {
  useAppTableSort,
  AppTableRows,
  SortProps,
  Props as ColumnHeaderProps,
  AppTableHeadings,
} from '~/components/app-table';
import { API } from 'api';
import cx from 'classnames';
import { PortfolioHero } from '~/components/portfolio';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { AppRoundButton } from '~/components/app-round-button';
import { PayInvoiceNavigation } from './pay-invoices-navigation';
import { FilterNavigation } from '~/components/app-table-filters/filters/filter-navigation';
import { pdfDownloadMessage } from '~/utils/pdf-download-message';
import {
  FloatingFocusManager,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  autoUpdate,
  flip,
  offset,
} from '@floating-ui/react';
import { PreviewInvoice } from './preview-invoice';
import { initialSortInfo } from '~/components/app-table-filters/filters/filter-main';
import { SearchEmpty } from '~/components/search-empty/search-empty';
import { useNavigate } from 'react-router-dom';

enum Column {
  Month = 'Month',
  Number = 'Invoice number',
  Amount = 'Amount',
  Status = 'Status',
  Action = 'Action',
}

export interface PayInvoicesProps {
  account: Enriched.ListAccountItem | null;
  paymentAssetAccepted: string | null;
}

const getInvoiceMonthColumn = <R,>(
  props: SortProps<Column>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '30%',
    showSort: false,
    name: Column.Month,
    justify: 'justify-start ',
    cell: (data: EnrichedInvoice | null) => {
      if (!data) return null;
      return (
        <span className="text-sm font-bold">{data.formattedDueMonth}</span>
      );
    },
  };
};

const getInvoiceNumberColumn = <R,>(
  props: SortProps<Column>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '30%',
    showSort: false,
    name: Column.Number,
    cell: (data: EnrichedInvoice | null) => {
      if (!data) return null;
      return <span className="text-sm font-bold">{data.number}</span>;
    },
  };
};

const getInvoiceAmountColumn = <R,>(
  props: SortProps<Column>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '30%',
    showSort: false,
    name: Column.Amount,
    cell: (data: EnrichedInvoice | null) => {
      if (!data) return null;
      return <span className="text-sm font-bold">{data.formattedAmount}</span>;
    },
  };
};

const getInvoiceStatusColumn = <R,>(
  props: SortProps<Column>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '10%',
    showSort: false,
    name: Column.Status,
    justify: 'justify-end',
    cell: (data: EnrichedInvoice | null) => {
      if (!data) return null;
      return (
        <StatusIndicator
          value={getActivityStatus(data.status)}
          title={InvoiceStatusTitle[data?.status]}
        />
      );
    },
  };
};

const getInvoiceActionColumn = <R,>(
  props: SortProps<Column>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '30%',
    showSort: false,
    name: Column.Action,
    justify: 'justify-end',
    stopEventPropogation: true,
    cell: (data: EnrichedInvoice | null) => {
      /**
       * Store
       */
      const { setShowPayInvoices, setInvoiceToPay, getInvoicePdf } =
        DataStore.useStoreActions(_ => ({
          setShowPayInvoices: _.invoices.setShowPayInvoices,
          setInvoiceToPay: _.invoices.setInvoiceToPay,
          getInvoicePdf: _.invoices.getInvoicePdf,
        }));

      /**
       * State
       */
      const [isOpen, setIsOpen] = useState(false);
      const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
          offset({
            mainAxis: 5,
            crossAxis: -70,
          }),
          flip(),
        ],
        whileElementsMounted: autoUpdate,
      });
      const click = useClick(context);
      const dismiss = useDismiss(context);
      const role = useRole(context);
      const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role,
      ]);

      /**
       * Methods
       */
      const navigate = useNavigate();

      const handleGetPdf = async (
        item: EnrichedInvoice,
        type: 'view' | 'download'
      ) => {
        if (type === 'view') {
          navigate(`/invoices/${item.id}`);
        } else {
          const { isSuccessful, result, errorMessage } = await getInvoicePdf(
            item.id
          );

          pdfDownloadMessage(
            isSuccessful,
            result?.uri,
            errorMessage,
            type,
            'Could not download PDF invoice'
          );
        }
      };

      if (!data?.status) {
        return null;
      }
      const buttons: JSX.Element[] = [];
      const defaultsButtonProps = {
        iconCls: 'bg-transparent circle-btn',
        as: AppRoundButton,
        cls: 'py-2.5 px-3.5 hover-bg-grey-brighter',
        iconInteractive: false,
        onClick: () => {},
      };
      const iconProps = {
        size: 25,
        cls: 'bg-transparent cursor-pointer',
      };

      if (data.status === API.InvoiceStatus.WaitingPayment) {
        buttons.push(
          <AppRoundButton
            {...defaultsButtonProps}
            icon={
              <AppIcon
                icon="workflow-send"
                {...iconProps}
                bg="transparent"
                size="md"
              />
            }
            text="Pay"
            onClick={() => {
              setShowPayInvoices(true);
              setInvoiceToPay(data);
            }}
            key={API.InvoiceStatus.WaitingPayment}
          />
        );
      }
      if (data.isPdfReady) {
        buttons.push(
          <AppRoundButton
            {...defaultsButtonProps}
            icon={
              <AppIcon
                icon="visibility-show"
                {...iconProps}
                size={19}
                bg="transparent"
              />
            }
            text="View"
            key="view-account"
            onClick={() => handleGetPdf(data, 'view')}
          />
        );
        buttons.push(
          <AppRoundButton
            {...defaultsButtonProps}
            icon={
              <AppIcon
                icon="download"
                {...iconProps}
                size={19}
                bg="transparent"
              />
            }
            text="Download"
            key="dowload-invoice"
            onClick={() => handleGetPdf(data, 'download')}
          />
        );
      }
      return (
        <span className="flex flex-row items-center">
          {data.isPayable && (
            <button
              onClick={() => {
                setShowPayInvoices(true);
                setInvoiceToPay(data);
              }}
              className="app-button-primary button-md"
            >
              Pay now
            </button>
          )}
          <>
            {Boolean(buttons.length) && (
              <button
                ref={refs.setReference}
                {...getReferenceProps()}
                className="rounded-full p-1"
              >
                <DotsVerticalIcon
                  className={cx('w-5 h-5', {
                    'text-primary': isOpen,
                    'text-gray-400': !isOpen,
                  })}
                />
              </button>
            )}
            {isOpen && (
              <FloatingFocusManager context={context} modal={false}>
                <div
                  ref={refs.setFloating}
                  style={floatingStyles}
                  className={
                    'w-44 bg-white rounded-md shadow-2xl focus:outline-none border z-20 pointer-events-auto'
                  }
                  {...getFloatingProps()}
                >
                  {buttons}
                </div>
              </FloatingFocusManager>
            )}
          </>
        </span>
      );
    },
  };
};

const getColumns = (
  columnProps: SortProps<Column>
): ColumnHeaderProps<Column, EnrichedInvoice>[] => [
  getInvoiceMonthColumn(columnProps, Column.Month),
  getInvoiceNumberColumn(columnProps, Column.Number),
  getInvoiceAmountColumn(columnProps, Column.Amount),
  getInvoiceStatusColumn(columnProps, Column.Status),
  getInvoiceActionColumn(columnProps, Column.Action),
];

export const ListOfInvoices: FC = memo(() => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.invoices.error);
  const filters = DataStore.useStoreState(s => s.invoices.filters);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const totalOwed = DataStore.useStoreState(s => s.invoices.totalOwed);
  const isLoading = DataStore.useStoreState(s => s.invoices.busy);
  const invoiceToPay = DataStore.useStoreState(s => s.invoices.invoiceToPay);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const showPayInvoices = DataStore.useStoreState(
    s => s.invoices.showPayInvoices
  );
  const payInvoiceResult = DataStore.useStoreState(
    s => s.invoices.payInvoiceResult
  );
  const invoices = DataStore.useStoreState(s => s.invoices.invoices);

  const {
    payInvoice,
    setFilters,
    setInvoiceToPay,
    getAccountDetail,
    setShowPayInvoices,
    getInvoices,
  } = DataStore.useStoreActions(_ => ({
    payInvoice: _.invoices.payInvoice,
    setFilters: _.invoices.setFilters,
    setInvoiceToPay: _.invoices.setInvoiceToPay,
    getAccountDetail: _.portfolio.getAccountDetail,
    setShowPayInvoices: _.invoices.setShowPayInvoices,
    getInvoices: _.invoices.getInvoices,
  }));

  /**
   * State
   */
  const [payInvoiceOptions, setPayInvoiceOptions] = useState<PayInvoicesProps>({
    account: null,
    paymentAssetAccepted: null,
  });
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);

  /**
   * Hooks
   */
  const { showDialog, hideDialog } = useAppDialog();
  useEffect(() => {
    handleGetAccountInvoiceList();
  }, []);

  useEffect(() => {
    if (!!invoiceToPay?.quoteAssetCode && !!payInvoiceOptions?.account) {
      setPayInvoiceOptions({
        ...payInvoiceOptions,
        paymentAssetAccepted: invoiceToPay?.quoteAssetCode,
      });
    }
  }, [invoiceToPay?.quoteAssetCode, payInvoiceOptions?.account]);

  const handleUpdateFilterParams = useCallback(
    (updatedData = initialSortInfo) => {
      setFilters(updatedData);
      handleGetAccountInvoiceList(1, null, updatedData);
    },
    [initialSortInfo]
  );

  useEffect(() => {
    return () => {
      setFilters(initialSortInfo);
      handleUpdateFilterParams();
    };
  }, []);

  /**
   * DOM
   */
  const handleGetAccountInvoiceList = async (
    page?: number | null,
    pageSize?: number | null,
    data?: FilterFormikProps | null
  ) =>
    await getInvoices({
      dateStart: data?.filterStartDate || null,
      dateEnd: data?.filterEndDate || null,
      page: page || null,
      pageSize: pageSize || null,
      invoiceType:
        (data?.filterInfo?.split(' ').join('') as API.InvoiceItemType) || null,
    });
  const { columnProps } = useAppTableSort<Column>();
  const columns = getColumns(columnProps);
  const filterCount = useMemo(() => {
    let count = 0;

    filters?.filterInfo?.length ? (count += 1) : count;
    filters?.filterStartDate ? (count += 1) : count;
    return count;
  }, [filters]);

  const disableAssetSelection = true;

  const balanceError = useMemo(() => {
    const accountAsset =
      (accountDetail?.assets ?? []).find(
        _asset => _asset.currency.code === invoiceToPay?.quoteAssetCode
      ) ?? null;

    return getInvoiceBalanceError(invoiceToPay, accountAsset);
  }, [invoiceToPay]);

  const handleFilterModal = useCallback(
    (showFilters: boolean, val = filters) => {
      if (!showFilters) return;
      showDialog(
        <FilterNavigation
          filters={val}
          filterOnChange={false}
          title="Filter Invoices"
          filterInfoLabel="Invoice type"
          onClose={async (
            values?: FilterFormikProps | null,
            submit?: boolean
          ) => {
            const applyChanges = Boolean(submit);
            handleFilterModal(true, values);

            if (applyChanges) {
              handleUpdateFilterParams(values || initialSortInfo);
              return hideDialog();
            }
          }}
          filterInfoLabelPlaceholder="Choose type"
          items={invoiceTypeLabels}
        />
      );
    },
    [filters]
  );

  return (
    <AppContainer
      containerWidth="lg"
      orderContentCls="order-last md:order-first"
      wrapperCls="h-full p-6"
      cls=""
    >
      {/* pay invoices dialog */}
      <AppDialog
        isOpen={showPayInvoices}
        onClose={() => {
          setShowPayInvoices(false);
        }}
      >
        <PayInvoiceNavigation
          accounts={accounts?.filter(({ canPayInvoice }) => canPayInvoice)}
          error={error || balanceError}
          invoiceToPay={invoiceToPay}
          payInvoiceResult={payInvoiceResult}
          account={accountDetail?.account}
          assetOfKind={accountDetail?.assets}
          payInvoiceOptions={payInvoiceOptions}
          disableAssetSelection={disableAssetSelection}
          onAccountChange={async value => {
            if (!value.account?.accountId) return;
            await getAccountDetail({
              accountId: value.account?.accountId,
            });
            setPayInvoiceOptions({
              ...payInvoiceOptions,
              account: value,
            });
          }}
          onAssetChange={value =>
            setPayInvoiceOptions({
              ...payInvoiceOptions,
              paymentAssetAccepted: value,
            })
          }
          handlePreview={async () => {
            const invoiceRequest = {
              invoiceId: invoiceToPay?.id,
              paymentAssetCode: payInvoiceOptions.paymentAssetAccepted,
              paymentAccountId: payInvoiceOptions.account?.account?.accountId,
            } as API.PayInvoiceRequest;
            const res = await payInvoice(invoiceRequest);
            await handleGetAccountInvoiceList();
            return res?.isSuccessful;
          }}
          handlePdfExport={() => {}}
          onClose={() => {
            setShowPayInvoices(false);
            setInvoiceToPay(null);
            setPayInvoiceOptions({
              account: null,
              paymentAssetAccepted: null,
            });
          }}
        />
      </AppDialog>

      {/**Invoice details */}
      <AppDialog
        isOpen={showInvoiceDetails}
        onClose={() => {
          setShowInvoiceDetails(false);
        }}
      >
        <PreviewInvoice
          onClose={() => setShowInvoiceDetails(false)}
          invoiceToPay={invoiceToPay}
          payInvoiceOptions={payInvoiceOptions}
        />
      </AppDialog>

      {/* busy  */}
      {/* {busy && <AppLoader bgColor={'bg-transparent'} spinnerTop="0%" />} */}

      <PortfolioHero
        balance={totalOwed}
        additionalContent={
          invoices.some(
            ({ status }) => status === API.InvoiceStatus.WaitingPayment
          ) ? (
            <>
              {/** TODO enable when asset payment dropdown is enabled  */}
              {!disableAssetSelection && (
                <button
                  onClick={() => setShowPayInvoices(true)}
                  className="app-button-primary button-md text-sm"
                >
                  Pay invoices
                </button>
              )}
            </>
          ) : (
            <></>
          )
        }
        contentCls="flex flex-row justify-between items-center"
        title={<p className="text-sm font-medium text-gray-400">Total owed</p>}
      />
      <div className="flex flex-row border-1 border-b-0 mt-7 bg-white grey-bright justify-between items-center p-5 mdp-7">
        <h4 className="text-xl text-primary font-bold">Latest invoices</h4>
        {/**TODO - enable filter */}
        <button
          className="flex gap-x-2.5 items-center py-2.5 md:py-3 pl-5 pr-4 border rounded hover-bg-grey-brighter cursor-pointer select-none hover-bg-grey-brighter"
          onClick={() => handleFilterModal(true)}
        >
          <span className="font-bold text-sm text-primary">Filter</span>
          {filterCount > 0 ? (
            <span className="w-7 p-1 text-primary font-bold text-sm bg-yellow-300 rounded-full">
              {filterCount}
            </span>
          ) : (
            <AppIcon icon="filter" bg="transparent" size={13} />
          )}
        </button>
      </div>
      <div className="snap-x snap-mandatory max-w-6xl overflow-scroll">
        <div className="min-w-780 overflow-none">
          <AppTableHeadings
            columns={columns}
            borderCls={cx('border-grey-bright bg-white border', {
              'border-b-0': !isLoading && !invoices?.length,
            })}
          />

          <AppTableRows<Column, EnrichedInvoice>
            columns={columns}
            rows={isLoading ? null : invoices}
            rowPadding="px-4 md:px-8 py-3"
            cls={'py-0'}
            onRowClick={_data => {
              /**TODO enable below to view invoice details once design is implemnented */
              // setInvoiceToPay(data);
              // setShowInvoiceDetails(true);
            }}
            emptyDataText={<SearchEmpty title="No invoices found" />}
            containerCls="rounded-b-4 border-t-0 border-1 grey-bright"
          />
        </div>
      </div>
    </AppContainer>
  );
});
