import { useMemo } from 'react'
import { DataStore } from '~/store'
import { useUrls } from 'common'
import { pdfDownloadMessage } from '~/utils/pdf-download-message'
import { API } from 'api'


export const useFooterLinks = () => {
  /**
   * Store
   */
  const getFees = DataStore.useStoreActions(a => a.settings.getFees)

  /**
   * Hooks
   */
  const { faqUrl, privacyUrl, termsUrl, webappContactUsUrl } = useUrls()
  const handleGetPdf = async () => {
    const { isSuccessful, result, errorMessage } = await getFees({
      type: API.OrganizationDocumentType.FeeSchedule,
    })

    pdfDownloadMessage(
      isSuccessful,
      result?.url,
      errorMessage,
      'view',
      errorMessage || 'Could not download Fees schedule',
    )
  }

  return useMemo(() => [
    { id: 'fees', label: 'Fees', onClick: handleGetPdf },
    { id: 'contact', label: 'Contact us', to: webappContactUsUrl },
    { id: 'faq', label: 'Support & FAQs', to: faqUrl },
    { id: 'terms', label: 'Terms of use', to: termsUrl },
    { id: 'privacy', label: 'Privacy policy', to: privacyUrl },
  ], [])
};
