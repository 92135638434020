import { API } from 'api';
import startOfDay from 'date-fns/startOfDay';
import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import { Enriched } from '../types';

export function groupActivitiesByDay(
  activities: Enriched.ActivityTransaction[],
  dir: API.SortDirection
) {
  const activitiesByDay = _groupBy(
    activities,
    // Group by day timestamp (ts)
    ({ creationDate }) =>
      startOfDay(new Date(creationDate)).getTime().toString()
  );

  const sections = Object.entries(activitiesByDay).map(([ts, _activities]) => {
    return [
      // Day timestamp
      ts,
      // Section activities (sorted by creation date)
      _orderBy(
        _activities,
        [({ creationDate }) => new Date(creationDate).getTime()],
        [dir === API.SortDirection.Descending ? 'desc' : 'asc']
      ),
    ] as const;
  });

  // 2- Sort sections by day (ts)
  return _orderBy(
    sections,
    [([ts]) => parseInt(ts, 10)],
    [dir === API.SortDirection.Descending ? 'desc' : 'asc']
  );
}
