import { FC } from 'react';
import cx from 'classnames';
import { API } from 'api';

interface Props {
  bankId: string;
  status: API.CustomerBankAccountStatus;
  onVerify?: (bankId: string) => void;
}

export const BankStatus: FC<Props> = ({ bankId, status, onVerify }) => {
  const title =
    status === API.CustomerBankAccountStatus.Verifying
      ? `Not verified`
      : `Verified`;
  return (
    <div className="flex flex-row gap-3.5">
      <div className="flex flex-row items-center gap-2">
        <span
          className={cx('font-bold truncate text-xs', {
            'hidden sm:block':
              status === API.CustomerBankAccountStatus.Verifying,
          })}
        >
          {title}
        </span>
        <span
          className={cx(
            'block w-3 h-3 bg-black rounded-full',
            {
              'bg-warning': status === API.CustomerBankAccountStatus.Verifying,
            },
            { 'bg-success': status === API.CustomerBankAccountStatus.Ready }
          )}
        />
      </div>
      {onVerify && status === API.CustomerBankAccountStatus.Verifying && (
        <button
          className="app-button-primary h-9 sm:h-12 button-xs text-sm"
          onClick={e => {
            e.stopPropagation();
            onVerify(bankId);
          }}
        >
          Verify
        </button>
      )}
    </div>
  );
};
