import { format } from 'date-fns';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppIcon,
  CurrencyIcon,
  DATE_FORMATS,
  EnrichedCryptoAddress,
  RowLabelContent,
  useAppDialog,
} from 'common';
import { APP_ROUTES } from '~/routes';
import { AppStore, DataStore } from '~/store';
import { Error } from '~/components/common/error';
import { DeleteAddressEntryModal } from './delete-address-entry-modal';
import { CancelAddressEntryModal } from './cancel-address-entry-modal';
import { AccountSelector } from '~/components/workflows/account-selector';
import { CopyableText } from '~/components/copyable-text';

interface SettingsAddressBookDetailsProps {
  error?: string | null;
  address: EnrichedCryptoAddress;
}

export const SettingsAddressBookDetails: FC<
  SettingsAddressBookDetailsProps
> = ({ error, address }) => {
  /**
   * Store
   */
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const isKycVerified = DataStore.useStoreState(
    s => s.user.isKycVerified ?? false
  );

  const { getAccountDetail } = DataStore.useStoreActions(_ => ({
    getAccountDetail: _.portfolio.getAccountDetail,
  }));
  const {
    setDashboardSelectedDialogType,
    setDashboardSelectedAsset,
    setSelectedWithdrawalAddress,
  } = AppStore.useStoreActions(_ => ({
    setDashboardSelectedDialogType: _.setDashboardSelectedDialogType,
    setDashboardSelectedAsset: _.setDashboardSelectedAsset,
    setSelectedWithdrawalAddress: _.setSelectedWithdrawalAddress,
  }));

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useAppDialog();

  /**
   * Methods
   */
  const beginSendWorkflow = async (accountId?: string) => {
    if (!accountId) {
      return;
    }
    const { isSuccessful, result } = await getAccountDetail({
      accountId,
      isBackgroundXHR: false,
    });
    if (!isSuccessful) {
      return;
    }
    const assetInAccount =
      result?.assets.find(a => a.currency.code === address.currencyCode) ||
      null;
    setDashboardSelectedAsset(assetInAccount);
    if (!isKycVerified) {
      setDashboardSelectedDialogType('KYC');
      return;
    }
    setDashboardSelectedDialogType('workflow-send');
  };
  const handleSend = () => {
    setSelectedWithdrawalAddress(address);
    if (accounts && accounts.length === 1) {
      beginSendWorkflow(accounts[0].account?.accountId);
      return;
    }

    showDialog(
      <AccountSelector
        workflowType={'workflow-send'}
        onClose={hideDialog}
        onNext={async accountItem => {
          hideDialog();
          beginSendWorkflow(accountItem.account?.accountId);
        }}
      />
    );
  };
  const handleCloseCancelDeleteWithdrawalAddress = () => {
    hideDialog();
    navigate(APP_ROUTES.AUTH_SETTINGS + '/crypto-addresses');
  };
  const handleDelete = () => {
    showDialog(
      <DeleteAddressEntryModal
        addressId={address.id}
        onClose={handleCloseCancelDeleteWithdrawalAddress}
      />
    );
  };
  const handleCancel = () => {
    showDialog(
      <CancelAddressEntryModal
        addressId={address.id}
        onClose={handleCloseCancelDeleteWithdrawalAddress}
      />
    );
  };

  /**
   * DOM
   */
  const showSendButton = !address.isVerifying; // Note: addresses are global to all accounts, so one can send to an address (contactlist) from any account
  return (
    <div className="flex flex-col h-full">
      {/* heading  */}
      {address.isVerifying && (
        <div className="px-6 pt-6 pb-2">
          <div className="flex flex-col bg-yellow-100 px-6 py-4 text-primary text-center justify-center items-center font-bold rounded-md">
            Pending
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row gap-y-2 px-4 sm:px-6 py-4 justify-between ">
        <CurrencyIcon
          labelCls="text-primary font-bold"
          labelTextSize="normal"
          currencyCode={address.currencyCode}
          currencyName={
            <CopyableText
              showCopyText={false}
              text={address.address}
              textCls="flex-row items-center text-right break-all"
            />
          }
        />
        <div className="flex flex-col md:flex-row gap-y-2 gap-x-1">
          {showSendButton && (
            <button className="app-button-outline text-sm" onClick={handleSend}>
              Send <AppIcon cls="ml-2" icon="send" bg="" size={16} />
            </button>
          )}
          {!address.isVerifying && (
            <button
              className="w-full md:w-auto app-button-failure text-sm"
              onClick={handleDelete}
            >
              Remove
            </button>
          )}
          {address.isVerifying && (
            <button
              className="w-full md:w-auto app-button-failure text-sm"
              onClick={handleCancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>

      <RowLabelContent
        cls="px-6"
        label={'Address label'}
        content={
          <span className={`text-right break-normal`}>{address.label}</span>
        }
      />

      <RowLabelContent
        cls="px-6"
        label={'Asset'}
        content={
          <CurrencyIcon
            labelCls="text-primary font-bold"
            labelTextSize="normal"
            currencyCode={address.currencyCode}
          />
        }
      />

      <RowLabelContent
        cls="px-6"
        label={'Network'}
        content={
          <span className={`text-right break-normal`}>{address.network}</span>
        }
      />
      <RowLabelContent
        cls="px-6"
        label={'Date added'}
        content={
          <span
            className={`text-right break-normal text-primary font-bold`}
          >{`${format(
            address.creationDate,
            DATE_FORMATS.DD_MONTH_YYYY
          )} - ${format(address.creationDate, DATE_FORMATS.HH_MM_TZ)}`}</span>
        }
      />

      {/* error */}
      {error && <Error cls="px-6 py-1 md:px-12" message={error} />}

      {/* content */}
    </div>
  );
};
