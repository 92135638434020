import { API } from 'api';
import cx from 'classnames';
import { FC, useState } from 'react';
import { DataStore } from '../../store';
import { AppTableHeadings } from '../app-table';
import { AppTableRows } from '../app-table/rows';
import {
  ApprovalsConfirmationColumn,
  getAprovalsConfirmationColumns,
} from '../dashboard/apex-quorum-tables/columns';
import { SortInfo } from '~/store/types';
import { SearchEmpty } from '~/components/search-empty/search-empty';
export interface BankAccountRowProps {
  accounts: API.BankAccount[] | null;
  onShow: (bankId: string) => void;
  onVerify: (bankId: string) => void;
}

export const BankAccountList: FC<BankAccountRowProps> = ({
  accounts,
  onShow,
  onVerify,
}) => {
  /**
   * Store
   */
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   *  State
   */
  const [sortInfo, setSortInfo] =
    useState<SortInfo<ApprovalsConfirmationColumn> | null>(null);

  const columnProps = {
    sort: sortInfo,
    onVerify: onVerify,
    isApexFundManager: clientUserType === 'fund-manager',
    onSort: (value: SortInfo<ApprovalsConfirmationColumn>) =>
      setSortInfo(value),
  };
  const columns = getAprovalsConfirmationColumns(columnProps);
  return (
    <>
      <AppTableHeadings<ApprovalsConfirmationColumn, API.BankAccount | null>
        borderCls={cx('border-grey-bright bg-white border-b-0 border', {
          'border-b-0': accounts?.length,
        })}
        columns={columns}
      />
      <AppTableRows<ApprovalsConfirmationColumn, API.BankAccount | null>
        columns={columns}
        rows={accounts || []}
        rowPadding="px-4 md:px-8 py-2.5 md:py-4"
        cls="cursor-pointer hover-bg-grey-brighter"
        emptyDataText={<SearchEmpty title="There are no saved bank accounts" />}
        onRowClick={(f: API.BankAccount | null) => {
          if (!f) return;
          onShow(f?.id);
        }}
      />
    </>
  );
};
