import { AccountLabels } from './account-labels';
import { ApiKeys } from './api-keys';
import { Details } from './details';
import { Notifications } from './notifications';
import { PaymentMethods } from './payment-methods';
import { Rewards } from './rewards';
import { Root } from './root';
import { Security } from './security';
import { AddressBook } from './address-book';
import { AddressBookDetails } from './address-book-details';

export const SettingsPage = {
  Root: Root,
  Details: Details,
  Notifications: Notifications,
  ApiKeys: ApiKeys,
  BankAccounts: PaymentMethods,
  Rewards: Rewards,
  Security: Security,
  AddressBook: AddressBook,
  AccountLabels: AccountLabels,
  AddressBookDetails: AddressBookDetails,
};
