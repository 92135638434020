import { FC, useRef, useState } from 'react';
import cx from 'classnames';
import format from 'date-fns/format';
import { Error } from '../../components/common/error';
import { StepWizardChildProps } from 'react-step-wizard';
import { CopyableText } from '~/components/copyable-text';
import { ArrowSmRightIcon } from '@heroicons/react/outline';
import {
  AppIcon,
  CurrencyIcon,
  DATE_FORMATS,
  InvoiceStatusTitle,
  RowLabelContent,
  formatMoney,
} from 'common';
import { PayInvoicesNavigationProps } from './pay-invoices-navigation';
import { RowSection } from '~/components/workflows/shared/row-section';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import { CurrencyTransaction } from '~/components/workflows/shared/currency-transaction';
import { ExportPdfButton } from '~/components/common/export-pdf-button';
import { API } from 'api';

export const CompleteInvoicesPayment: FC<
  Partial<StepWizardChildProps> & PayInvoicesNavigationProps
> = ({ onClose, handlePdfExport, error, invoiceToPay, payInvoiceResult }) => {
  /**
   * State
   */
  const [isPdfProcessing, setIsPdfProcessing] = useState(false);
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * DOM
   */
  return (
    <div className="relative text-primary h-full">
      <div ref={ref} className="flex flex-col px-6 lg:px-8">
        <DashboardHeader headerPadding="pt-14">
          <div className="mt-9 flex flex-row">
            <h3 className="font-semibold typo-20 md:typo-24 absolute top-9 z-30 pr-20">
              Pay Invoice
            </h3>
          </div>
          <div className="flex flex-row items-center justify-center mb-8">
            <CurrencyIcon
              showLabel={false}
              currencyCode={payInvoiceResult?.quoteAssetCode}
            />
            <ArrowSmRightIcon className="w-4 h-4 mx-1 text-grey" />
            <AppIcon icon="logo-circle" size={32} bg="bg-transparent" />
          </div>
        </DashboardHeader>
        {/* api errors  */}
        <Error message={error} cls="mt-3" />

        <div className="flex flex-col mt-3 gap-x-6">
          {/* status */}
          <RowLabelContent
            showColon={false}
            label={`Status`}
            content={
              <>
                <span className="text-sm font-bold flex flex-row gap-x-1 items-center">
                  {InvoiceStatusTitle[payInvoiceResult?.status || '']}
                  <span
                    className={cx('ml-2 w-3 h-3 rounded-full', {
                      'bg-yellow':
                        payInvoiceResult?.status !== API.InvoiceStatus?.Paid,
                      'bg-success':
                        payInvoiceResult?.status === API.InvoiceStatus?.Paid,
                    })}
                  />
                </span>
              </>
            }
          />
          {/* invoice period */}
          <RowLabelContent
            showColon={false}
            label={`Invoice month/year`}
            content={
              <>
                {payInvoiceResult?.creationDate && (
                  <>{payInvoiceResult?.formattedDueMonth}</>
                )}
              </>
            }
          />
          {/* invoice number */}
          <RowLabelContent
            showColon={false}
            label={`Invoice number`}
            content={
              <>
                <RowSection
                  title={String(payInvoiceResult?.number)}
                  description=""
                />
              </>
            }
          />
          {/* from */}
          <RowLabelContent
            showColon={false}
            label={`From`}
            cls="mb-3"
            content={
              <div className="flex flex-col text-primary items-end gap-y-2">
                <CurrencyTransaction
                  positive={false}
                  amount={formatMoney(
                    payInvoiceResult?.amount.toString() ?? '0',
                    payInvoiceResult?.quoteAssetCode ?? 'USD'
                  )}
                />
                <div>
                  <RowSection
                    title={payInvoiceResult?.paymentAccountLabel || ''}
                    description={
                      <span className="flex flex-row gap-1">
                        <span>
                          {payInvoiceResult?.paymentAccountType || ''}
                        </span>
                        <span>
                          {payInvoiceResult?.paymentAccountNumber || ''}
                        </span>
                      </span>
                    }
                  />
                </div>
              </div>
            }
          />
          {/* to */}
          <RowLabelContent
            showColon={false}
            label={`To`}
            cls="mb-3"
            content={
              <div className="flex flex-col text-primary items-end gap-y-1.5">
                <CurrencyTransaction
                  amount={formatMoney(
                    payInvoiceResult?.amount.toString() ?? '0',
                    payInvoiceResult?.quoteAssetCode ?? 'USD'
                  )}
                />
              </div>
            }
          />

          {/* reference code  */}
          <RowLabelContent
            showColon={false}
            labelCls="text-sm sm:text-base"
            label={`Reference code`}
            content={
              <CopyableText
                showCopyText={false}
                text={String(payInvoiceResult?.referenceNumber)}
                textCls="font-bold uppercase text-sm sm:text-base"
              />
            }
          />
          {/* invoice period */}
          <RowLabelContent
            cls="mb-5"
            showColon={false}
            labelCls="text-sm sm:text-base"
            label={`Date`}
            content={
              <>
                {format(new Date(), DATE_FORMATS.D_LLL_Y)}{' '}
                {format(new Date(), DATE_FORMATS.H_MM_AAA_Z)}
              </>
            }
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-6 px-8 sm:px-10">
        {/* export  */}
        <ExportPdfButton
          node={ref}
          fileName={`${invoiceToPay?.number || 'invoice'}-${Date.now()}`}
          isProcessing={isPdfProcessing}
          setIsProcessing={setIsPdfProcessing}
          cls="button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
        />
      </div>
    </div>
  );
};
