import { FC } from 'react';
import { format } from 'date-fns';
import {
  DATE_FORMATS,
  formatMoney,
  Note,
  COLORS,
  BaseComponentProps,
} from 'common';
import cx from 'classnames';
import { API } from 'api';
import { AppTooltip } from '../app-tooltip';
import { daysDiff } from 'common';
import { DataStore } from '../../store';
import { ReferralCode } from '../referral-code';
import { ReactComponent as AccountIcon } from 'common/dist/assets/icons/account.svg';
import { IconContent } from '../icon-content';
import { AppIcon } from 'common';

interface DotProps {
  cls?: string;
  color: string;
}

const Dot: FC<DotProps> = ({ cls, color }) => {
  return (
    <span
      className={cx(cls, 'w-3 h-3 rounded-full')}
      style={{ backgroundColor: color }}
    ></span>
  );
};

interface StatusLabelProps {
  description: string;
  em: boolean;
  title: string;
  value: 'WARNING' | 'ATTENTION' | 'INFO' | 'SUCCESS' | 'FAILURE';
}

const StatusLabel: FC<StatusLabelProps> = ({
  description,
  em = false,
  title,
  value,
}) => {
  return (
    <AppTooltip
      cls="mr-2"
      icon={
        <div className="flex flex-row items-center text-left">
          <AppIcon
            icon="information-circle"
            cls="fill-current-color w-4 h-4 mr-2 items-top"
            size="sm"
            bg="bg-transparent"
          />
          <strong className={cx({ 'text-primary': em })}>{title}</strong>
          <Dot cls="ml-2" color={COLORS.STATUS[value]} />
        </div>
      }
      content={
        <div className="text-sm  text-left">
          <div className="flex flex-row items-center text-left">
            <Dot cls="mr-2" color={COLORS.STATUS[value]} />
            <strong className="text-primary">{title}</strong>
          </div>
          <p>{description}</p>
        </div>
      }
    />
  );
};

const RewardStatus: FC<{ reward: API.Reward }> = ({ reward }) => {
  const isReferralProgram =
    reward.promotion === API.PromotionType.Referree ||
    reward.promotion === API.PromotionType.Referrer;
  const isMatchInKind = reward.promotion === API.PromotionType.MatchInKind;
  if (reward.status === API.RewardState.SignedUp) {
    return (
      <StatusLabel
        title="Pending"
        description="Your referral friend has signed up to Stablehouse but they’ve still yet to verify their identity or make their first deposit. Please give them a nudge."
        value="WARNING"
        em
      />
    );
  }

  if (reward.status === API.RewardState.Qualified && isReferralProgram) {
    return (
      <StatusLabel
        title="Qualified"
        description="Your friend made their deposit. You will both be rewarded if they keep it in their account for at least 60 days."
        value="INFO"
        em
      />
    );
  }

  if (reward.status === API.RewardState.Qualified && isMatchInKind) {
    return (
      <StatusLabel
        title="Qualified"
        description="You will be rewarded if you keep your funds in your account for at least 60 days."
        value="INFO"
        em
      />
    );
  }

  if (reward.status === API.RewardState.RewardEarned && isMatchInKind) {
    return (
      <StatusLabel
        title="Rewarded"
        description="Payout made. Funds have been successfully transferred."
        value="SUCCESS"
        em
      />
    );
  }

  if (reward.status === API.RewardState.RewardEarned && isReferralProgram) {
    return (
      <StatusLabel
        title="Rewarded"
        description="Referral payout made. Funds have been successfully transferred."
        value="SUCCESS"
        em
      />
    );
  }

  if (reward.status === API.RewardState.Cancelled) {
    if (reward.promotion === API.PromotionType.Referrer) {
      return (
        <StatusLabel
          title="Cancelled"
          description="Your friend did not keep their deposit in their account for a minimum of 60 days. Both your referral bonuses have been cancelled."
          value="FAILURE"
          em
        />
      );
    } else {
      return (
        <StatusLabel
          title="Cancelled"
          description="You did not keep your deposit in your account for a minimum of 60 days. Your reward has been cancelled."
          value="FAILURE"
          em
        />
      );
    }
  }

  return null;
};

export const SettingsRewards: FC = () => {
  /**
   * Store
   */
  const rewards = DataStore.useStoreState(s => s.settings.rewards);

  const emptyEl = (
    <div className="text-center font-bold my-10">
      You don’t have any rewards yet.
    </div>
  );
  const headerEl = (
    <div className="hidden sm:table-row text-gray-400 text-sm border-t border-b border-grey-bright">
      <div className="table-cell py-2">Program</div>
      <div className="table-cell py-2">Amount</div>
      <div className="table-cell py-2">Date initiated</div>
      <div className="table-cell py-2">Days lefts</div>
      <div className="table-cell py-2 text-right w-56">Status</div>
    </div>
  );

  /**
   * DOM
   */
  return (
    <>
      <Title>Rewards history</Title>

      <RewardsList
        data={rewards || []}
        ListEmptyComponent={emptyEl}
        ListHeaderComponent={headerEl}
        className="mb-3"
      />
    </>
  );
};

interface ListProps {
  data: Object[];
  ListEmptyComponent: JSX.Element;
  ListHeaderComponent: JSX.Element;
}

interface RewardsListProps extends ListProps {
  className?: string;
  data: API.Reward[];
}

const ReferralNote = () => {
  const kycStatus = DataStore.useStoreState(state => state.kyc.kycStatus);
  const currentKycTier = kycStatus?.currentKycTier;
  const isKycTier1 = currentKycTier === 1;
  return (
    <Note bgColor="bg-gray-100" cls="rounded-4 px-5 py-5" textSize="base">
      <IconContent
        cls="mb-3"
        textSize="base"
        iconColor="black"
        content={
          <>
            <strong className="block text-primary">Remember</strong>
            <p>
              You get rewarded every time you refer a new friend to Stablehouse,
              and they open an account with a minimum deposit of $200.
            </p>
          </>
        }
      />
      <IconContent
        icon={<AccountIcon className="fill-current-color" />}
        textSize="base"
        iconColor="black"
        content={
          <>
            <strong className="block text-primary">Remind your friend</strong>
            <p>
              They must keep their deposit in their account for a minimum of 60
              days to qualify.
            </p>
          </>
        }
      />

      {isKycTier1 && (
        <div
          className="mt-12"
          style={{
            maxWidth: '70%',
          }}
        >
          <ReferralCode />
        </div>
      )}
    </Note>
  );
};

const RewardsList: FC<RewardsListProps> = ({
  className: cls,
  data,
  ListEmptyComponent,
  ListHeaderComponent,
}) => {
  if (!data.length) {
    return (
      <div className={cls}>
        <div className="sm:table w-full border-t border-collapse">
          {Boolean(ListHeaderComponent) && ListHeaderComponent}
        </div>
        {ListEmptyComponent}
        <ReferralNote />
      </div>
    );
  }

  const renderItem = (reward: API.Reward, index: number) => {
    return <RewardItem key={index} reward={reward} />;
  };

  return (
    <>
      <div className={cx('sm:table w-full border-t border-collapse', cls)}>
        {Boolean(ListHeaderComponent) && ListHeaderComponent}
        {data.map(renderItem)}
      </div>

      <ReferralNote />
    </>
  );
};

const renderPromotion = (promotion: API.PromotionType) => {
  if (promotion === API.PromotionType.MatchInKind) {
    return 'Deposit match';
  } else if (promotion === API.PromotionType.Referrer) {
    return 'Refer a friend';
  } else if (promotion === API.PromotionType.Referree) {
    return 'Refer a friend';
  }
};

const RewardItem: FC<{ reward: API.Reward }> = ({ reward }) => {
  const {
    creationDate,
    firstname,
    amountInCurrency,
    currency,
    promotion,
    scheduledDeliveryDate,
  } = reward;

  return (
    <div
      key={creationDate?.toJSON()}
      className={cx('sm:table-row border-b', {})}
    >
      {/* type  */}
      <div className="sm:table-cell pt-3 sm:py-6 align-middle">
        <strong>{renderPromotion(promotion)}</strong>
        {firstname && (
          <>
            <br />({firstname})
          </>
        )}
      </div>
      {/* amount / currency  */}
      <div className="sm:table-cell pt-3 sm:py-6 align-middle">
        <strong>
          {currency &&
            formatMoney(amountInCurrency?.toString() ?? '0', currency)}
        </strong>
      </div>
      <div className="sm:table-cell py-3 sm:py-6 align-middle">
        <strong>
          {format(new Date(creationDate!), DATE_FORMATS.DD_MMM_YYYY)}
        </strong>
      </div>
      <div className="sm:table-cell py-3 sm:py-6 align-middle">
        <strong>
          {' '}
          {scheduledDeliveryDate &&
          new Date(scheduledDeliveryDate).getTime() > new Date().getTime()
            ? daysDiff(scheduledDeliveryDate, new Date())
            : '-'}
        </strong>
      </div>
      <div className="sm:table-cell pb-3 sm:py-6 align-middle text-right w-56">
        <RewardStatus reward={reward} />
      </div>
    </div>
  );
};

const Title: FC<BaseComponentProps> = ({ children }) => {
  return <h1 className="font-bold text-xl mb-5">{children}</h1>;
};
