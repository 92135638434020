import { FILTER_ASSETS_BY } from '../config';
import { EnrichedCurrencyInformation } from '../types';
import { string } from 'is';

export function contains(
  value: string | EnrichedCurrencyInformation,
  search: string,
  defaultSearchFilter?: string[]
) {
  if (defaultSearchFilter?.length) {
    return defaultSearchFilter.some(i =>
      value[i]?.toLowerCase().includes(search?.toLowerCase())
    );
  }
  if (string(value)) {
    return (value as string)?.toLowerCase().includes(search?.toLowerCase());
  }
  return value;
}

export function filterAssetsBy(
  filterBy: FILTER_ASSETS_BY,
  currency: EnrichedCurrencyInformation
) {
  return (
    filterBy === FILTER_ASSETS_BY.ALL ||
    (filterBy === FILTER_ASSETS_BY.CASH && currency.isAssetOfTypeFiat) ||
    (filterBy === FILTER_ASSETS_BY.CRYPTO && !currency.isAssetOfTypeFiat) ||
    (filterBy === FILTER_ASSETS_BY.STABLECOIN && currency.isStablecoin)
  );
}

export const filterCurrencies = (
  currencies: EnrichedCurrencyInformation[],
  filterBy: FILTER_ASSETS_BY,
  search: string,
  defaultSearchFilter?: string[]
) => {
  return currencies.filter(currency => {
    const ok = filterAssetsBy(filterBy, currency);

    if (ok && search) {
      return contains(currency, search, defaultSearchFilter || ['code']);
    }
    return ok;
  });
};
