import { FC, memo, useMemo } from 'react';
import {
  FilterFormikField,
  FilterFormikProps,
  AppIcon,
  defaultFilterContentTemplate,
} from 'common';
import { FormikProps } from 'formik';
import { SelectField } from '~/components/forms/select-field';
import cx from 'classnames';
import { FilterProps } from './filter-navigation';

interface Props {
  withTitleBorder?: boolean;
  filterDateRangeLabel?: string;
  filterDateRangePlaceholder?: string;
  handleSelection: (
    key?: FilterFormikField,
    val?: string,
    clear?: boolean
  ) => void;
}

export const initialSortInfo: FilterFormikProps = {
  filterInfo: '',
  filterStartDate: null,
  filterEndDate: null,
  filterDateRange: '',
};

export const FilterMain: FC<
  FormikProps<FilterFormikProps> & Props & FilterProps
> = memo(
  ({
    items,
    handleSelection,
    dateFilterOptions,
    title,
    withTitleBorder = false,
    filterDateRangeLabel = 'Date range',
    filterDateRangePlaceholder = 'Choose date range',
    filterInfoLabel,
    filterInfoLabelPlaceholder,
    disableFilterOptions = false,
    showDateFilter = true,
    showAccountFilter = true,
    ...rest
  }) => {
    if (!rest) {
      return null;
    }
    const { values } = rest;
    const showReset = useMemo(
      () =>
        Object.values(values || {}).some(v =>
          Array.isArray(v) ? v?.length : v
        ) && withTitleBorder,
      [values]
    );

    return (
      <div data-testid="filter-form" className="flex flex-col">
        {/* header  */}
        <div className="gap-3">
          <div
            className={cx('flex flex-row justify-between ', {
              'pt-8 pb-6 px-8 border-b': withTitleBorder,
              'py-8 px-8': !withTitleBorder,
            })}
          >
            <h3 className="font-semibold typo-24">{title}</h3>
            {showReset && (
              <button
                className={cx('absolute flex flex-row items-center', {
                  'right-6': withTitleBorder,
                  'right-20': !withTitleBorder,
                })}
                type="button"
                disabled={!showReset}
                aria-label="go back"
                onClick={() => handleSelection(undefined, undefined, true)}
              >
                <span className="text-sm text-grey-darker">Clear</span>
                <AppIcon
                  icon="close-button"
                  bg="transparent"
                  size="sm"
                  cls="mt-0.5"
                />
              </button>
            )}
          </div>
          <div className="px-6 lg:px-8 flex flex-col gap-3.5 mb-8">
            {/* filter date */}
            {showDateFilter && (
              <div className="flex flex-col flex-1">
                <SelectField
                  name={FilterFormikField.filterDateRange}
                  disableHelpers
                  disabled={disableFilterOptions || !dateFilterOptions?.length}
                  label={filterDateRangeLabel}
                  placeHolder={filterDateRangePlaceholder}
                  values={dateFilterOptions || []}
                  contentTemplate={defaultFilterContentTemplate}
                  onChange={value =>
                    handleSelection(FilterFormikField.filterDateRange, value)
                  }
                />
              </div>
            )}
            {/* filter account  */}
            {showAccountFilter && (
              <div className="flex flex-col flex-1">
                <SelectField
                  name={FilterFormikField.filterInfo}
                  disableHelpers
                  disabled={disableFilterOptions || !items?.length}
                  label={filterInfoLabel}
                  placeHolder={filterInfoLabelPlaceholder}
                  values={items}
                  contentTemplate={defaultFilterContentTemplate}
                  onChange={value => {
                    handleSelection(FilterFormikField.filterInfo, value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
