import { FC, memo, useCallback } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { FilterMain } from './filter-main';
import { FormikDebug } from '~/components/common/formik-debug';
import { FilterFormikProps, FilterFormikField, dateRanges } from 'common';

export interface FilterProps {
  title: string;
  items: string[];
  filterInfoLabel: string;
  filterInfoLabelPlaceholder: string;
  dateFilterOptions?: string[] | null;
  disableFilterOptions?: boolean;
  showDateFilter?: boolean;
  showAccountFilter?: boolean;
}
interface Props {
  onClose?: (values: FilterFormikProps | null, submit?: boolean) => void;
  filterOnChange?: boolean;
  filters: FilterFormikProps | null;
}

export const FilterNavigation: FC<Props & FilterProps> = memo(
  ({
    filters,
    onClose,
    filterOnChange = false,
    dateFilterOptions = Object.entries(dateRanges).map(
      ([_entry, value]) => value?.text
    ),
    ...props
  }) => {
    if (!onClose) return null;
    /**
     * Methods
     */
    const submitFilterValues = useCallback(
      (values: FilterFormikProps | null, submit = false) => {
        const selectedTime = Object.values(dateRanges).find(
          ({ text }) => text === values?.filterDateRange
        );
        const updatedFilter: FilterFormikProps = {
          filterDateRange: selectedTime?.text || '',
          filterEndDate: selectedTime?.endDate || null,
          filterInfo: values?.filterInfo || '',
          filterStartDate: selectedTime?.startDate || null,
        };
        onClose(updatedFilter, submit);
      },
      []
    );

    const onFormSubmit = (values: FilterFormikProps) =>
      submitFilterValues(values, false);

    /**
     * DOM
     */
    const initialValues: FilterFormikProps = {
      filterDateRange: (!filterOnChange ? filters?.filterDateRange : '') || '',
      filterStartDate:
        (!filterOnChange ? filters?.filterStartDate : null) || null,
      filterEndDate: (!filterOnChange ? filters?.filterEndDate : null) || null,
      filterInfo: (!filterOnChange ? filters?.filterInfo : '') || '',
    };

    return (
      <div className="relative text-primary h-full">
        {/* form  */}
        <Formik<FilterFormikProps>
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnMount={false}
          validateOnChange
          enableReinitialize
          onSubmit={onFormSubmit}
        >
          {({ ...rest }) => {
            /**
             * Handle Methods
             */
            const handleSelection = useCallback(
              async (
                key?: FilterFormikField,
                val?: string | null,
                clear = false
              ) => {
                if (rest?.isValidating || !rest?.setFieldValue) {
                  return;
                }

                if (!key || !val || clear) {
                  const data = clear ? initialValues : filters || initialValues;
                  submitFilterValues(data, filterOnChange);
                  await rest?.setValues(data);
                  return;
                }
                const updatedData = val || filters?.[key];
                await rest?.setFieldValue(
                  FilterFormikField[key],
                  updatedData,
                  true
                );
                submitFilterValues(
                  {
                    ...rest?.values,
                    [key]: updatedData,
                  },
                  filterOnChange
                );
              },
              [filters, rest?.values, filterOnChange, rest?.isValidating]
            );
            return (
              <FormikForm>
                <FilterMain
                  {...rest}
                  {...props}
                  withTitleBorder={filterOnChange}
                  handleSelection={handleSelection}
                  dateFilterOptions={dateFilterOptions || []}
                />
                {!filterOnChange && (
                  <div className="flex flex-col sm:flex-row gap-4 border-t border-grey-bright bg-grey-brightest rounded-b py-6 px-8 sm:px-10">
                    {/* cancel button  */}
                    <button
                      type="button"
                      disabled={
                        !Object.values(rest?.values || {}).some(v =>
                          Array.isArray(v) ? v?.length : v
                        )
                      }
                      className="app-button-outline capitalize button-lg sm:button-xl text-md sm:text-lg w-full sm:mr-3 flex-1"
                      onClick={() => {
                        submitFilterValues({
                          filterInfo: '',
                          filterEndDate: null,
                          filterDateRange: '',
                          filterStartDate: null,
                        });
                      }}
                    >
                      clear
                    </button>
                    {/* preview  */}
                    <button
                      role="button"
                      type="button"
                      className="app-button-primary capitalize button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
                      onClick={() => submitFilterValues(rest?.values, true)}
                    >
                      apply
                    </button>
                  </div>
                )}
                <FormikDebug />
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    );
  }
);
