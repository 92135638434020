/**
 * This component is a generic wrapper around other elements
 * where an extra message needs to be shown on hover of an icon
 * eg:
 * - info icon with a tooltip message
 * - error validation message over input
 */
import React, { FC } from 'react';
import cx from 'classnames';
import { nanoid } from 'nanoid';
import { dataTestIds } from '../../utils/test-data/data-testids';
import { useMediaQuery } from '../../hooks';
import { BaseComponentProps } from '../../types';

export interface ValidationMessageProps {
  message: string | undefined | null;
  cls?: string;
  type?: 'global'; // global error, or defaults to inline
}

export const ValidationMessage: FC<ValidationMessageProps> = ({
  message,
  cls = '',
  type,
}) => {
  if (!message) {
    return null;
  }

  return (
    <div
      className={cx('p-1', cls, {
        'bg-grey-brighter border rounded-4': type === 'global',
      })}
    >
      <div className="font-medium text-failure text-xs">{message}</div>
    </div>
  );
};

export interface Props extends BaseComponentProps {
  message: string | undefined;
  show: boolean;
  cls?: string;
  testid?: string;
}

export const AppValidationWrapper: FC<Props> = ({
  show,
  message,
  children,
  cls,
  testid = dataTestIds.components.appValidationWrapper.root,
}) => {
  const key = nanoid(6);

  /**
   * Hooks
   */
  const { XS } = useMediaQuery();

  /**
   * DOM
   */
  const showError = !!message && !!show;
  const wrapperCls = cx('relative flex-1 bg-transparent rounded-4', cls);
  if (XS) {
    return (
      <div className={'relative w-full'}>
        <div className={wrapperCls}>{children}</div>
        {showError && <ValidationMessage message={message} cls="w-full" />}
      </div>
    );
  }
  return (
    <div data-testid={testid} className={wrapperCls} data-for={key}>
      {children}
      {showError && <ValidationMessage message={message} />}
    </div>
  );
};
