import { API } from 'api';

export const getCurrencyInfoForFund = (
  fund: API.SingleFund | API.FiatFund | null | undefined,
  currencies: API.CurrencyInformation[] | null
) => {
  if (!fund || !currencies) {
    return;
  }
  const ccy = currencies.find(ccy => {
    return ccy.code === fund.currencyCode;
  });
  return ccy;
};
