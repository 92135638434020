import { FC } from 'react';
import cx from 'classnames';
import { referAFriendBonusLastDate, useUrls } from 'common';
import { useEnvironmentConfig } from 'common';
import productImg from 'common/dist/assets/product/refer-a-friend2.png';
import ReactTooltip from 'react-tooltip';
import { ReferralCode } from '../components/referral-code';
import { AppIcon } from 'common';
import { AppContainer } from '../components/app-container';
import { formatDateShort } from 'common';

export const ReferAFriend: FC = () => {
  const { REACT_APP_SHOW_WAITING_LIST } = useEnvironmentConfig();
  const showWaitlist = !!(REACT_APP_SHOW_WAITING_LIST === 'true');
  const { joinTheWaitlistUrl, termsUrl } = useUrls();

  const NumberedBullet = (num: number) => (
    <h4 className="mt-1 mr-5 text-primary text-xs rounded-full border-primary border-2 w-6 h-6 flex items-center justify-center flex-shrink-0">
      {num}
    </h4>
  );

  /**
   * DOM
   */
  return (
    <div className="text-primary py-12">
      {/* content  */}
      <AppContainer as="main">
        {/* copy  */}
        <div className="flex flex-col items-center py-8 max-w-xl mx-auto">
          {/* header  */}
          <AppIcon icon="negative-refer-a-friend" size="lg" />

          <p className="text-3xl font-bold py-4 text-center">
            Refer your friends to get
            <br className="hidden sm:block" /> $40 USD in USDC
          </p>

          {/* banner  */}
          <img src={productImg} className="w-full" />

          {/* copy  */}
          <h4
            className={cx('text-gray-400 text-center mt-1 mb-10', {
              'typo-20': showWaitlist,
            })}
          >
            Get rewarded every time you refer a new friend to Stablehouse, and
            they open an account and make a minimum deposit of{' '}
            <span className="text-primary">$200</span>.
          </h4>

          <ReferralCode />

          <h3 className="text-primary my-10 font-bold">
            Steps to referring your friend
          </h3>

          {showWaitlist ? (
            <div className="text-gray-400">
              <div className="flex flex-row">
                {NumberedBullet(1)}
                <h4>
                  Your friend enters the waitlist at
                  <br />
                  <a
                    href={joinTheWaitlistUrl}
                    className="underline text-primary"
                  >
                    {joinTheWaitlistUrl}
                  </a>
                </h4>
              </div>
              <div className="flex flex-row my-6">
                {NumberedBullet(2)}
                <h4>
                  Once they receive an invite to join Stablehouse, they enter
                  your referral code as part of the sign-up process
                </h4>
              </div>
              <div className="flex flex-row my-6">
                {NumberedBullet(3)}
                <h4>
                  Once their account has been verified, they deposit and keep at
                  least <b>$200</b> onto their account for <b>60 days</b>
                </h4>
              </div>
              <div className="flex flex-row">
                {NumberedBullet(4)}
                <h4>
                  After 60 days, you both earn 40 USDC
                  <span className="ml-1 mr-2 inline-flex">
                    <div
                      data-tip
                      data-for="total-interest-earned"
                      className="text-gray-400 cursor-pointer"
                    >
                      <AppIcon icon="question-dot" size="sm" />
                    </div>
                    <ReactTooltip
                      id="total-interest-earned"
                      className="app-tooltip"
                      effect="float"
                      place="top"
                    >
                      <div className="px-5 py-5 text-gray-400 text-base">
                        <span className="text-primary">USDC</span>
                        <br />
                        USDC is one of the most <br />
                        trusted and recognised <br />
                        Stablecoins. It&#39;s a digital <br />
                        Dollar regulated by financial <br />
                        institutions, and backed by <br />
                        fully reserved assets.
                      </div>
                    </ReactTooltip>
                  </span>
                  <br />
                  Your reward is credited automatically in your respective
                  accounts
                </h4>
              </div>
            </div>
          ) : (
            <div className="text-gray-400 typo-20">
              <div className="flex flex-row">
                {NumberedBullet(1)}
                <h4 className="typo-20">You refer your friend.</h4>
              </div>
              <div className="flex flex-row my-6">
                {NumberedBullet(2)}
                <h4 className="typo-20">
                  They open a Stablehouse account and, like you, get verified.
                  They then make a minimum deposit of <b>$200</b> before{' '}
                  <span className="text-primary">
                    {formatDateShort(referAFriendBonusLastDate)}
                  </span>{' '}
                  and keep it in their account for{' '}
                  <span className="text-primary">60 days</span>.
                </h4>
              </div>
              <div className="flex flex-row my-6">
                {NumberedBullet(3)}
                <h4 className="typo-20">
                  You both earn{' '}
                  <span className="text-primary">40 USD credited in USDC</span>{' '}
                  instantly to both your accounts. You are elligible for up to{' '}
                  <span className="text-primary">5</span> referral rewards. Once
                  they receive an invite to join Stablehouse, they enter your
                  referral code as part of the sign-up process
                </h4>
              </div>
            </div>
          )}

          {/* terms of use  */}
          <div className="my-6">
            <a
              href={termsUrl}
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              <h4 className={cx({ 'typo-20': showWaitlist })}>See Terms</h4>
            </a>
          </div>
        </div>
      </AppContainer>
    </div>
  );
};
