import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import cx from 'classnames';

interface Tabs {
  panels: JSX.Element[];
  tabs: JSX.Element[];
  onChange?: (index: number) => void;
  defaultIndex?: number;
  selectedIndex?: number;
  headerContainerCls?: string;
  headerItemCls?: string;
  panelContainerCls?: string;
  marginCls?: string;
  borderCls?: string;
  footer?: JSX.Element;
  fontCls?: string;
  spacingCls?: string;
}

export const AppTabs: React.FC<Tabs> = ({
  defaultIndex = 0,
  selectedIndex,
  tabs,
  onChange,
  panels,
  headerContainerCls,
  headerItemCls,
  panelContainerCls,
  marginCls = 'mt-5',
  borderCls = 'border-b-1',
  footer,
  fontCls = 'font-bold',
  spacingCls = 'gap-x-3.5 md:gap-x-5',
}) => {
  return (
    <Tab.Group
      defaultIndex={defaultIndex}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <Tab.List>
        <div
          className={cx(
            'flex text-sm',
            headerContainerCls,
            borderCls,
            spacingCls
          )}
        >
          {tabs.map((t, index) => (
            <Tab key={index} as={Fragment}>
              {({ selected }: { selected: boolean }) => (
                <div
                  className={cx('cursor-pointer pt-2', headerItemCls, fontCls, {
                    'bg-tab-selected border-accent': selected,
                    'text-gray-600': !selected,
                  })}
                >
                  {t}
                </div>
              )}
            </Tab>
          ))}
        </div>
      </Tab.List>
      <Tab.Panels>
        <div className={cx(marginCls, panelContainerCls)}>
          {panels.map((t, index) => (
            <Tab.Panel key={index}>{t}</Tab.Panel>
          ))}
          {footer}
        </div>
      </Tab.Panels>
    </Tab.Group>
  );
};
