import { FC, memo, useEffect, useRef } from 'react';
import StepWizard from 'react-step-wizard';
import { defaultStepWizardProps } from '../../../constants';
import { AppStore, DataStore } from '../../../store';
import { TradeList } from './list';
import { Form } from './form';
import { Summary } from './summary';
import { WorkflowCompletedStatus } from './workflow-completed-status';
import { TRADE_STEPS } from './steps';
import { API } from 'api';

interface Props {
  side: API.Side;
}

export const TradeWorkflow: FC<Props> = memo(({ side }) => {
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * Store
   */
  const setError = DataStore.useStoreActions(a => a.buySell.setError);
  const resetState = DataStore.useStoreActions(a => a.buySell.resetState);
  const getAssets = DataStore.useStoreActions(a => a.buySell.getAssets);
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );

  /**
   * Hooks
   */
  useEffect(() => {
    // clean up
    return () => {
      resetState();
      setDashboardSelectedAsset(null);
    };
  }, []);
  useEffect(() => {
    getAssets({
      side,
    });
  }, []);

  /**
   * DOM
   */
  return (
    <div className="relative text-primary h-full" ref={ref}>
      <StepWizard
        {...defaultStepWizardProps}
        className="h-full"
        onStepChange={() => {
          setError(null);
        }}
      >
        {/* stpe: 1 */}
        <TradeList stepName={TRADE_STEPS.CHOOSE_ASSETS} />
        {/* step: 2 */}
        <Form stepName={TRADE_STEPS.FORM} />
        {/* step: 3 */}
        <WorkflowCompletedStatus stepName={TRADE_STEPS.COMPLETED_STATUS} />
        {/* step: 4  */}
        <Summary stepName={TRADE_STEPS.SUMMARY} />
      </StepWizard>
    </div>
  );
});
