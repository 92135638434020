import { API } from 'api';

export function isBankAccountRequirementRequired(
  requirement: API.BankAccountRequirementType | null | undefined
) {
  return requirement === API.BankAccountRequirementType.Required;
}

export function getAccountNumberLabel(
  requirements: API.BankAccountRequirementsOutput | null
) {
  if (isBankAccountRequirementRequired(requirements?.accountNumber)) {
    return 'Account number';
  }

  // requirements?.iban = "Required"
  return 'IBAN';
}

export function getBankIdentifierLabel(
  requirements: API.BankAccountRequirementsOutput | null
) {
  if (isBankAccountRequirementRequired(requirements?.aba)) {
    return 'ABA / Routing number';
  }

  if (isBankAccountRequirementRequired(requirements?.sortCode)) {
    return 'Sort code';
  }

  // requirements?.bicSwift = "Required"
  return 'BIC / Swift code';
}
