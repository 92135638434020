import parseNumber from 'multi-number-parse';
import { EnrichedAccountDetailAsset } from '../../types';

export const validateAmountWithInBalance = (
  amount: number | string | null,
  asset: EnrichedAccountDetailAsset | null
) => {
  try {
    const value = parseNumber(amount || 0) || 0;
    const balance = parseNumber(asset?.withdrawableQuantityDecimal || 0) || 0;
    return value <= balance;
  } catch (error) {
    return false;
  }
};
