import { useUrls } from 'common';
import { ReactComponent as KycCheckRounded } from '../../assets/icons/kyc-check-rounded.svg';

interface Props {
  onClose?: () => void;
}

export const HouseFullModal: React.FC<Props> = props => {
  const { zendeskHouseFullUrl } = useUrls();
  /**
   * DOM
   */
  return (
    <div className="flex flex-col align-middle items-center p-10 pb-5">
      <KycCheckRounded width={20} />
      <>
        <div className="text-2xl font-bold mt-5">
          The house is temporarily full
        </div>
        <div className="text-gray-400 mt-6 text-center">
          Unfortunately you cannot perform this action at the moment.
        </div>
        <button
          onClick={() => {
            window.open(zendeskHouseFullUrl, '_blank');
            if (props.onClose) {
              props.onClose();
            }
          }}
          className="app-button-primary inline-block mt-6 mb-4 font-bold"
        >
          Learn more
        </button>
      </>
    </div>
  );
};
