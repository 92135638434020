import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API } from 'api';
import { APP_ROUTES, externalRoutes } from '../routes';
import { DataStore } from '../store';
import { useEffectOnce } from './use-effect-once';
import { useRenewedToken } from './use-authentication-hooks';

interface Props {
  redirectIfNecessary: boolean;
}

export const useClearKycStateAtLogout = () => {
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);

  const clearKycStatus = DataStore.useStoreActions(a => a.kyc.clearKycStatus);
  useEffect(() => {
    if (!decodedToken) {
      clearKycStatus();
    }
  }, [decodedToken && decodedToken?.id]);
};

// Prevent user from browsing the home if they are KYC denied
export const useRedirectUserToKycDeniedPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const kycState = DataStore.useStoreState(state => state.kyc.kycStatus);

  useEffect(() => {
    if (
      location.pathname === APP_ROUTES.AUTH_PORTFOLIO &&
      kycState &&
      kycState.kycState === API.KycState.Denied
    ) {
      navigate(APP_ROUTES.AUTH_KYC_INDEX);
    }
  }, [location, kycState && kycState.kycState]);
};

export const useRefreshKycStatusUntilApproved = () => {
  const getKycStatus = DataStore.useStoreActions(i => i.kyc.getKycStatus);
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const isEmailVerified = DataStore.useStoreState(s => s.user.isEmailVerified);
  const kycTier = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.kycTier || 0
  );

  // every 5 seconds during development, otherwise every 30 seconds.
  const refreshRate =
    process.env.NODE_ENV === 'development' ? 5 * 1000 : 30 * 1000;

  // As long as it is not approved, refresh KYC status once in a while
  useEffect(() => {
    if (decodedToken && kycTier > 0) {
      return;
    }
    if (!isEmailVerified) {
      return;
    }

    const interval = window.setInterval(async () => {
      if (decodedToken && decodedToken.id) {
        try {
          await getKycStatus({
            isBackgroundXHR: true,
          });
        } catch (e) {
          // It's ok if this fails because the user hasn't validated his email yet.
          console.error(e);
        }
      }
    }, refreshRate);
    return () => window.clearInterval(interval);
  }, [decodedToken?.id, kycTier]);
};

// Cleanup previous state that might have been saved during a previous session of the wizard
export const useKycCleanupOnLoad = () => {
  const kycActions = DataStore.useStoreActions(actions => actions.kyc);

  useEffect(() => {
    kycActions.setAddress(null);
    kycActions.setDateOfBirth(null);
    kycActions.setWidgetToken(null);
    kycActions.setSubmissionStatus(null);
    kycActions.setLastSubmissionId(null);
    kycActions.setKycData(null);
  }, []);
};

export const useRefreshJwtWhenKycTierChanges = () => {
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const kycStatus = DataStore.useStoreState(i => i.kyc.kycStatus);
  const renewToken = useRenewedToken();
  const kycTier = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.kycTier || 0
  );

  useEffect(() => {
    const kycTier = kycStatus && kycStatus.currentKycTier;
    const kycLevel = decodedToken && kycTier;
    const isAuthRoute =
      externalRoutes
        .map(i => i.toString())
        .indexOf(document.location.pathname) === -1;
    if (kycTier != kycLevel && decodedToken && kycStatus && isAuthRoute) {
      // User was approved or downgraded. Refresh token to reflect this change.
      console.log(
        'Kyc tier has changed from ',
        kycLevel,
        'to',
        kycTier,
        'renewing token.'
      );
      renewToken();
    }
  }, [kycStatus && kycStatus.currentKycTier, decodedToken && kycTier]);
};

export const useRefreshedKycStatusOnLoad = ({
  redirectIfNecessary = false,
}: Props) => {
  const getKycStatus = DataStore.useStoreActions(
    state => state.kyc.getKycStatus
  );
  const kycState = DataStore.useStoreState(state => state.kyc.kycStatus);
  const renewToken = useRenewedToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const isDestroyed = useRef(false);

  useEffect(() => {
    // reload kyc status in the background in case it has changed
    const currentKycTier = kycState?.currentKycTier;
    getKycStatus({
      isBackgroundXHR: false,
    }).then((value: API.KycStatus | null) => {
      if (value?.currentKycTier !== currentKycTier) {
        renewToken();
      }
      if (!isDestroyed.current) {
        setLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      isDestroyed.current = true;
    };
  }, []);

  useEffectOnce(
    () => {
      if (!redirectIfNecessary) {
        return;
      }
      if (!kycState?.kycState) {
        return;
      }

      if (!loaded) {
        return;
      }

      if (
        kycState?.kycState === API.KycState.Denied ||
        kycState?.kycState === API.KycState.ManualReviewInProgress ||
        kycState?.kycState === API.KycState.WaitingForOnfido ||
        kycState?.kycState === API.KycState.TooManyFailedApplications
      ) {
        navigate(APP_ROUTES.AUTH_KYC_INDEX);
      }

      if (kycState?.currentKycTier && kycState?.currentKycTier >= 1) {
        navigate(APP_ROUTES.AUTH_PORTFOLIO);
      }

      if (
        kycState?.kycState === API.KycState.EnhancedDueDiligence &&
        location.pathname !== APP_ROUTES.AUTH_KYC_EDD
      ) {
        navigate(APP_ROUTES.AUTH_KYC_EDD);
      }

      if (
        kycState?.kycState !== API.KycState.EnhancedDueDiligence &&
        location.pathname === APP_ROUTES.AUTH_KYC_EDD
      ) {
        navigate(APP_ROUTES.AUTH_KYC_INDEX);
      }
    },
    () => loaded && !!kycState?.kycState,
    [kycState?.kycState, loaded]
  );

  return { loaded };
};
