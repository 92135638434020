import { AppIcon } from 'common';
import { FC } from 'react';
import { KycTitle } from '../../components/kyc/kyc-title';
import { KycWizardLayout } from '../../components/kyc/kyc-wizard-layout';
import { OnboardingLayout } from '../../components/layouts/onboarding';
import { DataStore } from '../../store';
import { KycSubtitle } from '../../components/kyc/kyc-subtitle';
import { Button } from '../../components/forms/button';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { SegmentTrackType, useTrackEvent } from 'common';
import { RequireAuth } from '~/components/auth/require-auth';

export const EmailVerified: FC = () => {
  /**
   * Store
   */
  const isKycVerified = DataStore.useStoreState(
    state => state.user.isKycVerified
  );
  const isAuthenticated = DataStore.useStoreState(a => a.user.isAuthenticated);
  const trackEvent = useTrackEvent();

  /**
   * DOM
   */
  return (
    <RequireAuth fullScreen>
      <OnboardingLayout userIcon={!!isAuthenticated}>
        <KycWizardLayout title={''} loading={false}>
          <div className="text-center flex flex-col items-center">
            <AppIcon size="lg" icon={'check'} cls="m-auto mb-4" />

            <KycTitle>
              <span data-testid="success">Your email is confirmed.</span>
              <br />
              {!isKycVerified && <>Next step is to get verified.</>}
            </KycTitle>

            {!isKycVerified && (
              <KycSubtitle muted>
                To start depositing on Stablehouse, we need to ask you to go
                through our KYC (Know Your Customer) process. For that, you’ll
                now be taken to our third-party verification service.
              </KycSubtitle>
            )}
            {!isKycVerified && isAuthenticated ? (
              <>
                <Button
                  as={Link}
                  to={APP_ROUTES.AUTH_KYC_WIZARD}
                  className="app-button-accent font-bold inline-block mt-3"
                >
                  Get Verified
                </Button>
                <div className="mt-5 font-bold mb-4">
                  <Link
                    to={APP_ROUTES.AUTH_PORTFOLIO}
                    onClick={() =>
                      trackEvent(SegmentTrackType.KycSkippedAndGoesToDashboard)
                    }
                  >
                    I’ll do it later
                  </Link>
                </div>
              </>
            ) : (
              <Button
                as={Link}
                to={
                  !isKycVerified
                    ? APP_ROUTES.NON_AUTH_HOME
                    : APP_ROUTES.AUTH_PORTFOLIO
                }
                className="app-button-accent font-bold inline-block mt-3"
              >
                {!isKycVerified ? `Sign in` : `Continue`}
              </Button>
            )}
          </div>
        </KycWizardLayout>
      </OnboardingLayout>
    </RequireAuth>
  );
};
