import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../routes'
import { OpenLayout } from '../layouts/open-layout'
import { FallbackCta } from '~/components/404/fallback-cta'

export const Unrestricted404: FC = () => {
	const navigate = useNavigate()
	return (
		<OpenLayout
			icon="search-off"
			title="We couldn’t find the page you are looking for"
			cta={
				<div className="flex flex-row gap-6">
					<FallbackCta
						onClick={() => navigate(-1)}
						label="Go to previous page"
						ctaCls="bg-white text-secondary"
					/>
					<FallbackCta
						href={APP_ROUTES.NON_AUTH_HOME}
						label="Go to home page"
					/>
				</div>
			}
		>
			The page you are looking for doesn’t exist or has been moved
		</OpenLayout>
	)
}
