import React, { FC } from 'react';
import cx from 'classnames';
import { AppIcon } from '../app-icon';

export interface FormHeaderProps {
  title?: string;
  accountInfo?: string;
  subTitle?: string | JSX.Element;
  onBack?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  cls?: string;
  titleCls?: string;
  marginCls?: string;
  subTitleCls?: string;
}

export const FormHeader: FC<FormHeaderProps> = ({
  title = '',
  subTitle,
  onBack,
  accountInfo = '',
  cls,
  // TODO(Hadrien): This is a "heading-6". Convert when using design system
  // TODO(Hadrien): Keep leading-6 instead of 5 to accomodate for alignment with close button,
  //  until Dialog header is refactored. Title should be injectable
  titleCls = 'text-base leading-6',
  subTitleCls = 'text-sm',
  // TODO(Hadrien): Is this supposed to be the standard for all forms/dialogs ?
  marginCls = 'mt-8 mb-5 mx-10',
}) => {
  return (
    <div className={cx('flex flex-row items-start gap-x-3', cls, marginCls)}>
      {onBack && (
        <button onClick={onBack} type="button" aria-label="go back">
          <AppIcon icon="arrow-back" bg="transparent" height={24} size={18} />
        </button>
      )}
      <div>
        <div className={cx('font-bold', titleCls)}>{title}</div>
        {!!accountInfo && (
          <div className={cx(subTitleCls, 'text-grey-darker font-normal')}>
            {accountInfo}
          </div>
        )}
        {subTitle && <div className="mt-1">{subTitle}</div>}
      </div>
    </div>
  );
};
