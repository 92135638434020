import * as React from 'react';
import cx from 'classnames';
import { BaseComponentProps } from 'common';

interface Props extends BaseComponentProps {
  className?: string;
  noMargin?: boolean;
  'data-testid'?: string;
}

export const KycTitle: React.FC<Props> = props => {
  return (
    <div
      className={cx(
        !props.noMargin && 'mb-5',
        'text-center typo-20 font-bold',
        props.className
      )}
      data-testid={props['data-testid']}
    >
      {props.children}
    </div>
  );
};
