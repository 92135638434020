import { API } from 'api';
import parseNumber from 'multi-number-parse';
import { SendDestinationType, SendFormikProps } from './types';
import { formatAmount } from '../../format-amount';
import { Enriched, EnrichedCurrencyInformation } from '../../../types';

export const getSendCurrencyCode = (
  formValues: SendFormikProps | null,
  simulation: Enriched.WithdrawalSimulation | Enriched.TransferSimulation | null
) => {
  if (simulation) {
    return simulation.currencyCode;
  }

  return formValues?.fromAsset?.currency.code || null;
};

export const getSendDestination = (
  formValues: SendFormikProps | null,
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (simulation?.isExternal) {
    return (
      formValues?.whitelistAddress?.address ??
      (simulation as Enriched.WithdrawalSimulation).destinationAddress
    );
  }

  return (
    formValues?.destination ??
    (simulation as Enriched.TransferSimulation).destinationTag ??
    ''
  );
};

export const getSendRemainingBalance = (
  ccyInfo: EnrichedCurrencyInformation | null,
  fundBalance: number | 0,
  formValues: SendFormikProps | null,
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (
    simulation?.isExternal &&
    (formValues?.destinationType === SendDestinationType.crypto ||
      formValues?.destinationType === SendDestinationType.book)
  ) {
    return formatAmount(
      Number(fundBalance) -
        (Number((simulation as Enriched.WithdrawalSimulation)?.sendingAmount) +
          Number((simulation as Enriched.WithdrawalSimulation)?.totalFee)),
      ccyInfo?.decimals
    );
  }
  return formatAmount(
    parseNumber(fundBalance || 0) - parseNumber(formValues?.amount || 0),
    ccyInfo?.decimals
  );
};

export const getSendAmountSent = (
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if(!simulation) return '';

  return simulation.formatted.sendingAmountWithCurrencyCode;
};

export const getSendAmountWithdrawn = (
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  return simulation?.formatted.amountWithCurrencyCodeAndSign ?? '';
};

export const getSendFees = (
  ccyCode: string | null | undefined,
  formValues: SendFormikProps | null,
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (formValues?.destinationType === SendDestinationType.transfer) {
    return `FREE`;
  }
  if (!simulation || !ccyCode) {
    return ``;
  }
  const sim = simulation as Enriched.WithdrawalSimulation;
  if (sim.feeMode === API.WithdrawalFeeChargeMode.ChargedImmediately) {
    return sim.formatted.totalFeeWithCurrencyCode;
  }

  return sim.formatted.invoicedFeeUsdWithCurrencyCode;
};
