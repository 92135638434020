import { FC, useCallback } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { NOOP } from '../../../constants';
import CheckBox from 'rc-checkbox';
import { DataStore } from '../../../store';
import { getAccountInfo } from '~/utils/get-account-details';
import { FormHeader } from 'common';
import { FormFooter } from '~/components/workflows/shared/form-footer';

type Props = Partial<StepWizardChildProps>;

export const Attention: FC<Props> = ({ goToStep, previousStep = NOOP }) => {
  // Store
  const confirmed = DataStore.useStoreState(s => s.receive.confirmed);
  const formValues = DataStore.useStoreState(s => s.receive.formValues);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);

  const { setConfirmed } = DataStore.useStoreActions(a => ({
    setConfirmed: a.receive.setConfirmed,
  }));

  const ccyInfo = formValues?.toAsset;
  /**
   * Methods
   */
  const handleChange = () => {
    setConfirmed(!confirmed);
  };

  // DOM
  const handleDismiss = useCallback(() => {
    setConfirmed(false);
    previousStep();
  }, []);

  return (
    <div data-testid="receive-attention" className="flex flex-col text-primary">
      <FormHeader
        cls=""
        title="Attention"
        onBack={handleDismiss}
        accountInfo={
          accountDetail
            ? getAccountInfo(accountDetail.account, 'To')
            : undefined
        }
      />
      <div className="px-10 mt-3">
        <div className="flex flex-col gap-2">
          <span className="text-base font-bold text-primary">
            Please read before continuing with your deposit
          </span>
          <span className="text-base text-grey-darker">
            Only send {ccyInfo?.displayCode} on the {ccyInfo?.network} network
            to this address. XBTO is not responsible for any losses incurred
            from deposits made to the wrong address or network.
          </span>
        </div>
        <label className="flex mt-8 gap-2">
          <CheckBox
            data-testid="receive-attention-checkbox"
            className="app-input-checkbox-wrap"
            checked={confirmed}
            onChange={handleChange}
          />
          <span className="text-grey-darker">
            I have read and confirmed the information above
          </span>
        </label>
      </div>

      <FormFooter
        submitText="Next"
        onCancel={handleDismiss}
        onSubmit={() => goToStep?.(1)}
        isSubmitDisabled={!confirmed}
      />
    </div>
  );
};
