import numbro from 'numbro';
import esES from 'numbro/languages/es-ES';
import frFR from 'numbro/languages/fr-FR';
import numberParse from 'multi-number-parse';
import { DEFAULTS } from '../constants';

numbro.registerLanguage(esES);
numbro.registerLanguage(frFR);

export function setNumbroLanguage(languageTag: string) {
  numbro.setLanguage(languageTag, 'en-US');
}

export const formatAmount = (
  amount: string | number | undefined | null,
  decimals?: number | undefined,
  trim?: boolean
) => {
  const value = numberParse(amount || 0);

  return numbro(value).format({
    thousandSeparated: true,
    mantissa: typeof decimals === 'number' ? decimals : DEFAULTS.DECIMAL_SCALE,
    trimMantissa: !!trim,
  });
};
