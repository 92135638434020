import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { NOOP } from '../../constants';
import { SettingsSecuritySteps } from './settings-security';
import { TwoFASetup } from '../two-fa-setup';

export const SettingsSecurity2faDisabled: FC<Partial<StepWizardChildProps>> = ({
  goToStep = NOOP,
}) => {
  const handleOnCompleted = () => goToStep(SettingsSecuritySteps.OVERVIEW);

  /**
   * DOM
   */
  return (
    <div>
      {/* heading  */}
      <>
        <div className="text-sm text-gray-400 mb-2">
          Two-factor authentication (2FA) is the industry standard for securing
          your online accounts. Once enabled, 2FA adds a second layer of
          protection to your Stablehouse account. When you sign in, you’ll be
          prompted to input your password and a verification code generated by
          your authenticator app.
        </div>
        <div className="text-sm text-gray-400">
          You can setup 2FA by using the{' '}
          <span className="text-primary">Google Authenticator</span> app,
          available on
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer mx-1 underline text-primary"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          >
            Android
          </a>
          and
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer mx-1 underline text-primary"
            href="https://itunes.apple.com/us/app/google-authenticator/id388497605"
          >
            iOS
          </a>
          mobile devices. Once enabled, you will only be able to log in to your
          Stablehouse account with a code generated by the app. This code
          regenerates every 30 seconds. We urge all Stablehouse account holders
          to enable 2FA on their accounts. Stablehouse is not responsible for
          any unauthorized users that may access your account.
        </div>
      </>
      {/* form  */}
      <TwoFASetup onCompleted={handleOnCompleted} />
    </div>
  );
};
