import { FC } from 'react';
import { APP_ROUTES } from '../../routes';
import { Link } from 'react-router-dom';
import { AppLogo } from '../app-logo';
import { useUrls } from 'common';
import { AppContainer } from '../app-container';
import { DataStore } from '~/store';
import { pdfDownloadMessage } from '~/utils/pdf-download-message';
import { API } from 'api';

export const StablehouseFooter: FC = () => {
  /**
   * Store
   */
  const getFees = DataStore.useStoreActions(a => a.settings.getFees);

  /**
   * Hooks
   */
  const { faqUrl, privacyUrl, termsUrl } = useUrls();
  const handleGetPdf = async () => {
    const { isSuccessful, result, errorMessage } = await getFees({
      type: API.OrganizationDocumentType.FeeSchedule,
    });

    pdfDownloadMessage(
      isSuccessful,
      result?.url,
      errorMessage,
      'view',
      errorMessage || 'Could not download Fees schedule'
    );
  };
  /**
   * DOM
   */
  return (
    <div
      data-testid="app-footer"
      className="relative border-t pt-10 pb-6 text-footer bg-footer"
    >
      <AppContainer>
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          <div className="flex-shrink-0">
            <Link
              to={APP_ROUTES.AUTH_PORTFOLIO}
              className="focus:ring-transparent focus:outline-none"
            >
              <AppLogo variant="light" cls="" />
            </Link>
          </div>
          <div className="flex flex-row flex-start gap-3 mt-5 md:mt-0 md:gap-6 text-lg sm:text-base items-center">
            {/* fees  */}
            <button
              onClick={handleGetPdf}
              className="flex flex-row hover:underline font-bold text-base "
            >
              Fees
            </button>
            {/* faq  */}
            <Link
              to={faqUrl}
              rel="help"
              target="_blank"
              className="flex flex-row hover:underline font-bold text-base"
            >
              Support &amp; FAQs
            </Link>
            {/* contact  */}
            <Link
              rel="noreferrer"
              to={APP_ROUTES.NON_AUTH_CONTACT_US}
              className="flex flex-row hover:underline font-bold text-base "
            >
              Contact us
            </Link>
          </div>
        </div>
      </AppContainer>
      <AppContainer cls="text-sm text-gray-400 mt-3">
        <div className="flex flex-col md:flex-row-reverse md:justify-between items-center">
          <div className="flex flex-row justify-between  text-sm">
            <Link
              to={termsUrl}
              className="mr-3 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Terms of use
            </Link>
            {`|`}
            <Link
              to={privacyUrl}
              className="ml-3 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </Link>
          </div>
        </div>
      </AppContainer>
    </div>
  );
};
