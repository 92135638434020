import { enumToArray } from '../utils/enum-to-array';

export enum SORT_ASSETS_BY {
  NAME = 'NAME',
  CODE = 'CODE',

  PRICE = 'PRICE',

  DISPLAY_CODE = 'DISPLAY_CODE',
  PRICE_CHANGE_1_DAY = 'PRICE_CHANGE_1_DAY',
  PRICE_CHANGE_1_WEEK = 'PRICE_CHANGE_1_WEEK',
  PRICE_CHANGE_1_MONTH = 'PRICE_CHANGE_1_MONTH',

  PERCENTAGE_CHANGE_1_DAY = 'PERCENTAGE_CHANGE_1_DAY',
  PERCENTAGE_CHANGE_1_WEEK = 'PERCENTAGE_CHANGE_1_WEEK',
  PERCENTAGE_CHANGE_1_MONTH = 'PERCENTAGE_CHANGE_1_MONTH',

  BALANCE = 'BALANCE',
  BALANCE_USD = 'BALANCE_USD',
  TOTAL_INTEREST_EARNED = 'TOTAL_INTEREST_EARNED',
  ACCRUED_INTEREST_WEEK = 'ACCRUED_INTEREST_WEEK',
  APY = 'APY',

  RATE_OF_RETURN_MTD = 'RATE_OF_RETURN_MTD',
  RATE_OF_RETURN_YTD = 'RATE_OF_RETURN_YTD',
  TOTAL_RETURN = 'TOTAL_RETURN',

  WITHDRAWABLE_USD = 'WITHDRAWABLE_USD',
  TRADEABLE_USD = 'TRADEABLE_USD',
}

export const SORT_ASSETS_FIELDS: Record<SORT_ASSETS_BY, string> = {
  [SORT_ASSETS_BY.NAME]: 'name',
  [SORT_ASSETS_BY.CODE]: 'code',
  [SORT_ASSETS_BY.PRICE]: 'fxRate.rate',
  [SORT_ASSETS_BY.DISPLAY_CODE]: 'displayCode',
  [SORT_ASSETS_BY.PRICE_CHANGE_1_DAY]: 'fxRate.rate24HoursAgoValueChange',
  [SORT_ASSETS_BY.PRICE_CHANGE_1_WEEK]: 'fxRate.rate7DaysAgoValueChange',
  [SORT_ASSETS_BY.PRICE_CHANGE_1_MONTH]: 'fxRate.rate1MonthAgoValueChange',
  [SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_DAY]:
    'fxRate.rate24HoursAgoPercentageChange',
  [SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_WEEK]:
    'fxRate.rate7DaysAgoPercentageChange',
  [SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_MONTH]:
    'fxRate.rate1MonthAgoPercentageChange',
  [SORT_ASSETS_BY.APY]: 'apy',
  [SORT_ASSETS_BY.TOTAL_INTEREST_EARNED]: 'earnedInterestTotalUsd',
  [SORT_ASSETS_BY.ACCRUED_INTEREST_WEEK]: 'earnedInterestThisWeek',
  [SORT_ASSETS_BY.BALANCE_USD]: 'balanceUsd',
  [SORT_ASSETS_BY.BALANCE]: 'balance',
  [SORT_ASSETS_BY.WITHDRAWABLE_USD]: 'withdrawableQuantityUsd',
  [SORT_ASSETS_BY.TRADEABLE_USD]: 'tradableQuantityUsd',
  [SORT_ASSETS_BY.RATE_OF_RETURN_MTD]: 'rateOfReturnPercentMTD',
  [SORT_ASSETS_BY.RATE_OF_RETURN_YTD]: 'rateOfReturnPercentYTD',
  [SORT_ASSETS_BY.TOTAL_RETURN]: 'totalReturn',
};

export enum FILTER_ASSETS_BY {
  ALL = 'all',
  CASH = 'cash',
  CRYPTO = 'crypto',
  STABLECOIN = 'stablecoins',
}

export const ASSET_FILTERS = [
  { value: FILTER_ASSETS_BY.ALL, name: 'All' },
  { value: FILTER_ASSETS_BY.CRYPTO, name: 'Crypto' },
  { value: FILTER_ASSETS_BY.CASH, name: 'Cash' },
  { value: FILTER_ASSETS_BY.STABLECOIN, name: 'Stablecoins' },
];

export const ALLOWED_FILTER_ASSETS_BY_LIST = enumToArray(FILTER_ASSETS_BY);

export enum SORT_DIR {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TimeRange {
  H24 = '24H',
  W1 = '1W',
  M1 = '1M',
}

const getOption = (v: string) => {
  return {
    label: v,
    value: v,
  };
};

export const getSortOptions = (options: SORT_ASSETS_BY[]) => {
  return options.map(getOption);
};

export const SORT_ASSETS_ITEMS = Object.values(SORT_ASSETS_BY).map(getOption);

export * from './env-config';
