import {
  AppIcon,
  REGEX,
  TIMERS,
  useComputedConfig,
  useRecaptcha,
  useSystemConfig,
  useUrls,
  randomString,
  ERROR_MESSAGES,
  useEnvironmentConfig,
} from 'common';
import { ReactComponent as GiftIcon } from 'common/dist/assets/icons/gift.svg';
import { Field, Formik, FormikHelpers } from 'formik';
import memoize from 'memoizee';
import _debounce from 'lodash/debounce';
import { parse } from 'query-string';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlreadyHaveAccount } from '../../components/common/already-have-account';
import { Error } from '../../components/common/error';
import { Button } from '../../components/forms/button';
import { DebounceField } from '../../components/forms/debounce-field';
import { TextField } from '../../components/forms/text-field';
import { ExternalSplashLayout } from '../../components/layouts/external-splash';
import { SignUpSplash } from '../../components/splash/sign-up-splash';
import { BaseForm } from '../../definitions/base-form';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';
import { clearFormErrors, handleApiException } from '../../utils/handle-errors';
import { storageHelper } from '../../utils/storage-helper';
import {
  usePasswordLabel,
  useTogglePassword,
} from '../../hooks/use-toggle-password';
import { useLogout } from '../../hooks/use-authentication-hooks';

export enum FormField {
  firstname = 'firstname',
  lastname = 'lastname',
  email = 'email',
  password = 'password',
  repeatPassword = 'repeatPassword',
  referralCode = 'referralCode',
  receiveMarketingUpdates = 'receiveMarketingUpdates',
}

export interface FormProps extends BaseForm {
  [FormField.firstname]: string;
  [FormField.lastname]: string;
  [FormField.email]: string;
  [FormField.password]: string;
  [FormField.repeatPassword]: string;
  [FormField.referralCode]: string | undefined;
  [FormField.receiveMarketingUpdates]: boolean;
}

const isMin2Characters = (
  prop: FormField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  if (!value) {
    errors[prop] = ERROR_MESSAGES.REQUIRED_VALUE;
    return;
  }

  if (value.length < 2) {
    errors[prop] = ERROR_MESSAGES.MIN_2_CHARACTERS;
    return;
  }
};

const isRequired = (
  prop: FormField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  if (!value) {
    errors[prop] = ERROR_MESSAGES.REQUIRED_VALUE;
    return;
  }
};

const isValidEmail = (
  prop: FormField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  if (!value) {
    errors[prop] = ERROR_MESSAGES.REQUIRED_VALUE;
    return;
  }

  if (!REGEX.EMAIL_ADDRESS.test(value)) {
    errors[prop] = ERROR_MESSAGES.INVALID_EMAIL;
    return;
  }
};

interface Props {
  disableRecaptcha?: boolean;
}

export const RegisterStep2UserForm: FC<Props> = ({ disableRecaptcha }) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(a => a.error);
  const register = DataStore.useStoreActions(a => a.user.register);
  const logout = useLogout();
  const setJwt = DataStore.useStoreActions(a => a.user.setJwt);
  const getPasswordEntropy = DataStore.useStoreActions(
    a => a.user.getPasswordEntropy
  );
  const validateReferralCodeAction = DataStore.useStoreActions(
    a => a.user.validateReferralCode
  );
  const setIsValidReferralCode = DataStore.useStoreActions(
    a => a.user.setIsValidReferralCode
  );
  const isValidReferralCodeState = DataStore.useStoreState(
    s => s.user.isValidReferralCode
  );
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);
  const pwdValidatedPwdRef = useRef('');
  const referralCodeValidatedPwdRef = useRef('');

  /**
   * Hooks
   */
  const { REACT_APP_SHOW_WAITING_LIST } = useEnvironmentConfig();
  const showWaitlist = !!(REACT_APP_SHOW_WAITING_LIST === 'true');
  const { recaptchaWebappPublicKey } = useSystemConfig();
  const { tenant } = useComputedConfig();
  const { joinTheWaitlistUrl, privacyUrl } = useUrls();
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = parse(search);
  const { getRecaptchaToken } = useRecaptcha({
    key: recaptchaWebappPublicKey,
  });
  const [showPassword, toggleShowPassword, passwordType] = useTogglePassword();
  useEffect(() => {
    // log out the current user if already logged in.
    if (decodedToken) {
      logout();
    }
  }, []);
  useEffect(() => {
    if (!parsed) {
      return;
    }
    const { invitationcode } = parsed;
    if (!invitationcode && showWaitlist) {
      document.location.href = joinTheWaitlistUrl;
    }
  }, [parsed, showWaitlist]);

  /**
   * Methods
   */
  const validateReferralCode = async (referralCode: string) => {
    await validateReferralCodeAction(referralCode);
  };
  const memoizedValidateReferralCode = memoize(validateReferralCode);

  const validatePassword = async (password: string) => {
    return await getPasswordEntropy({
      password,
    });
  };
  const memoizedValidatePassword = memoize(validatePassword);
  const validate = async (values: FormProps) => {
    const errors: Record<string, unknown> = {};
    isRequired(FormField.firstname, values.firstname, errors);
    isRequired(FormField.lastname, values.lastname, errors);
    isMin2Characters(FormField.email, values.email, errors);
    isValidEmail(FormField.email, values.email, errors);

    if (
      values.referralCode &&
      referralCodeValidatedPwdRef.current !== values.referralCode
    ) {
      await memoizedValidateReferralCode(values.referralCode);
    }
    if (!values.referralCode) {
      setIsValidReferralCode(null);
    }

    if (values.password && pwdValidatedPwdRef.current !== values.password) {
      // pwd check
      const response = await memoizedValidatePassword(values.password);
      if (response && !response.isValid) {
        errors[FormField.password] = ERROR_MESSAGES.INVALID_VALUE;
      }

      // repeat pwd check
      if (values.password && values.password !== values.repeatPassword) {
        errors[FormField.repeatPassword] = ERROR_MESSAGES.PASSWORD_MUST_MATCH;
      }
    }
    referralCodeValidatedPwdRef.current = values.referralCode || '';
    pwdValidatedPwdRef.current = values.password;

    return errors;
  };
  const onFormSubmit = async (
    values: FormProps,
    helper: FormikHelpers<FormProps>
  ) => {
    clearFormErrors(helper);

    try {
      const result = await register({
        email: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        password: values.password,
        recaptchaToken: !disableRecaptcha ? await getRecaptchaToken() : '',
        receiveMarketingUpdates: values.receiveMarketingUpdates,
        referralCode: values.referralCode || '',
        tenant: tenant,
      });
      if (!result || !result.token) {
        return;
      }
      setJwt(result.token);
      storageHelper.jwt.set(result.token);
      navigate(APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING);
    } catch (exception) {
      scrollEl.scrollTop = 0;

      handleApiException(exception, helper);
    }
  };

  const onFormValidate = async (values: FormProps) => {
    return validate(values).then((errors = {}) => {
      return errors;
    });
  };

  const onFormValidateDebounced = useMemo(
    () => _debounce(onFormValidate, TIMERS.INPUT_DEBOUNCE),
    [onFormValidate]
  );

  /**
   * DOM
   */
  // Need a DOM ref to scroll back up when displaying error(s).
  let scrollEl: HTMLDivElement;

  const handleScrollEl = useCallback((el: HTMLDivElement) => {
    scrollEl = el;
  }, []);

  const initialValues = {
    email: ((parsed.email as string) || '').replace(' ', '+'),
    password: '',
    repeatPassword: '',
    prefineryToken: showWaitlist ? (parsed.invitationcode as string) || '' : '',
    firstname: '',
    lastname: '',
    receiveMarketingUpdates: false,
    // Prefinery use the "r" query string parameter as the referral code
    referralCode: !showWaitlist ? (parsed.ref as string) || '' : '',
  };
  return (
    <>
      {showWaitlist && (
        <div className="sticky top-0 w-screen z-10 bg-accent text-primary">
          <section className="font-semibold flex flex-row place-items-center place-self-center justify-center py-2">
            <div className="flex place-items-center justify-self-center">
              <p className="pl-2 lg:p-0 text-sm">
                If you were referred, enter your referral code below!
                <a
                  href="https://help.stablehouse.com/hc/en-us/articles/4787961792658-How-to-ensure-you-earn-the-referral-reward-"
                  className="underline cursor-pointer ml-2 mr-4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Show me how
                </a>
              </p>
            </div>
          </section>
        </div>
      )}
      <ExternalSplashLayout
        title={`Open your personal account`}
        onScrollEl={handleScrollEl}
      >
        <Formik<FormProps>
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validate={onFormValidateDebounced}
          validateOnChange
        >
          {({
            values,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            /**
             * Form DOM
             */
            return (
              <form onSubmit={handleSubmit} className="my-6">
                {process.env.NODE_ENV === 'development' && (
                  <a
                    className="underline"
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setFieldValue(
                        FormField.email,
                        randomString(5) + '@stablehouse.com'
                      );
                      setFieldValue(FormField.firstname, randomString(5));
                      setFieldValue(FormField.lastname, randomString(5));
                      setFieldValue(FormField.password, 'Password1.;');
                      setFieldValue(FormField.repeatPassword, 'Password1.;');
                      handleSubmit();
                    }}
                  >
                    Random fill &amp; submit{' '}
                  </a>
                )}

                {/* api error  */}
                <Error message={values.errorMessage || error} />

                <div className="sm:max-w-md">
                  {/* email address  */}
                  <div className="flex flex-col">
                    <TextField
                      type="email"
                      name={FormField.email}
                      label="Email"
                      autoComplete="email"
                      data-testid="register-email"
                    />
                  </div>

                  {/* firstname  */}
                  <div className="flex flex-col mt-4">
                    <TextField
                      name={FormField.firstname}
                      label="First name"
                      autoComplete="firstname"
                      data-testid="register-firstname"
                    />
                  </div>

                  {/* last name */}
                  <div className="flex flex-col mt-4">
                    <TextField
                      name={FormField.lastname}
                      label="Last name"
                      autoComplete="lastname"
                      data-testid="register-lastname"
                    />
                  </div>

                  {/* password  */}
                  <div className="col-span-2 flex flex-col mt-4">
                    <DebounceField
                      name={FormField.password}
                      label={usePasswordLabel(
                        'Password',
                        showPassword,
                        toggleShowPassword
                      )}
                      data-testid="register-password"
                      autoComplete="off"
                      type={passwordType}
                      validator="password"
                      debounceTimeout={TIMERS.INPUT_DEBOUNCE}
                    />
                  </div>

                  {/* confirm password  */}
                  <div className="col-span-2 flex flex-col mt-4">
                    <TextField
                      name={FormField.repeatPassword}
                      type={passwordType}
                      label="Confirm password"
                      autoComplete="off"
                      data-testid="register-repeat-password"
                    />
                  </div>

                  {/* referral code */}
                  <div className="flex flex-col gap-y-4 mt-4 bg-note rounded-4 border border-grey-bright p-5">
                    <div className="flex gap-x-3 items-start">
                      <div className="mt-2">
                        <GiftIcon className="fill-w-h fill-current-color" />
                      </div>
                      <div className="text-sm">
                        <strong>Referral Code</strong>
                        <br />
                        If you have a referral code, enter it here:
                      </div>
                    </div>
                    <DebounceField
                      name={FormField.referralCode}
                      autoComplete="referralCode"
                      data-testid="register-referralCode"
                      placeholder="optional"
                    />
                    {values.referralCode &&
                      isValidReferralCodeState === false && (
                        <div className="flex items-start gap-x-3 text-failure text-sm font-bold">
                          <div className="flex-1 w-8 mt-1">
                            <AppIcon
                              icon="information-circle"
                              size={20}
                              cls="fill-current-color"
                            />
                          </div>
                          <div>
                            Uh oh! You have entered an invalid referral code.
                            You can request it from your referrer.{' '}
                            <a
                              className="underline"
                              href="https://help.stablehouse.com/hc/en-us/articles/4785521456658-Rewards-Program-FAQ-s"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Learn how
                            </a>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                {/* sep  */}
                <hr className="my-4" />

                {/* marketing conformance  */}
                <div>
                  <label>
                    <Field
                      className="app-input-checkbox"
                      type="checkbox"
                      name="receiveMarketingUpdates"
                    />
                    <span className="ml-2 text-sm">
                      I’d like to receive trends, insight and updates from
                      Stablehouse.
                    </span>
                  </label>
                  <div className="my-1 text-gray-400 text-xs">
                    If you choose to receive the latest information from
                    Stablehouse, rest assured that you can unsubscribe at any
                    time by simply following the related instructions found
                    within the communication you have received. Find out how
                    Stablehouse is committed to protecting your data in our
                    <a
                      href={privacyUrl}
                      className="ml-1 underline hover:text-primary"
                    >
                      Privacy policy
                    </a>
                  </div>
                </div>

                {/* actions  */}
                <div className="flex flex-col sm:flex-row sm:items-center gap-y-4 mt-6">
                  {/* TODO: formik isSubmitting should be a wired up to busy state  */}
                  <Button
                    data-testid="register-button"
                    type="submit"
                    disabled={isSubmitting || !(isValid && dirty)}
                    loading={isSubmitting}
                    className="app-button-accent"
                  >
                    Open account
                  </Button>
                  <AlreadyHaveAccount cls="ml-3" />
                </div>
              </form>
            );
          }}
        </Formik>
      </ExternalSplashLayout>
    </>
  );
};
