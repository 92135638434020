import { useMemo } from 'react'
import { AppIcon, useMediaQuery } from 'common'
import { APP_ROUTES, externalRoutes } from '~/routes'
import { useToggle } from 'react-use'
import { AppLogo } from '~/components/app-logo'
import { UserMenu } from '~/components/app-header'
import cx from 'classnames'


export const useLeftNav = () => {
	const { XL, XXL } = useMediaQuery()
	const routesWithNav = !externalRoutes.includes(location.pathname as APP_ROUTES) && location.pathname !== APP_ROUTES.AUTH_404
	const showLeftNav = (XL || XXL) && routesWithNav
	const [shouldCollapseNav, toggleCollapseNav] = useToggle(false)
	const collapseNav = shouldCollapseNav && XL

	return useMemo(() => {
		return {
			routesWithNav,
			showLeftNav,
			collapseNav,
			leftNavCls: cx(showLeftNav && 'ml-left-nav',  showLeftNav && collapseNav && 'collapsed'),
			Nav: showLeftNav && (
				<aside className={cx('fixed top-0 bottom-0 z-50 transition-all text-secondary overflow-hidden left-nav border-r bg-grey-brightest p-6', collapseNav && 'collapsed')}>
					<span onClick={toggleCollapseNav}>
						{collapseNav ?
							<AppIcon icon="xbto-logo-icon" bg="bg-transparent" size={34} cls="m-auto" />
							:
							<AppLogo variant="dark" logoType="header" />
						}
					</span>
					<UserMenu isLeftNav isLeftNavCollapsed={collapseNav} menuContainerCls="flex flex-col mt-10 gap-0.5"
										menuLinkCls="p-4 text-sm font-semibold hover:text-secondary hover:bg-white rounded-sm whitespace-nowrap"
										showIcon showDivider={false} />
				</aside>
			),
		}
	}, [showLeftNav, collapseNav])
}
