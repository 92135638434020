import { FC } from 'react';
import { AppTenant, BaseComponentProps, useComputedConfig } from 'common';
import productDashboardXBTO from 'common/dist/assets/product/xbto-cover.png';
import productDashboardStablehouse from 'common/dist/assets/product/stablehouse-cover.png';
import { AppLogo } from '../app-logo';
import cx from 'classnames';
import { AppFooterNonAuth } from '../app-footer/non-auth';

export const LayoutSplashWrapper: FC<BaseComponentProps> = ({ children }) => {
  /**
   * Methods
   */
  const getProductImage = (tenant: AppTenant) => {
    if (tenant === 'Apex') {
      return undefined;
    }
    if (tenant === 'XBTO') {
      return productDashboardXBTO;
    }
    return productDashboardStablehouse;
  };

  /**
   * Vars
   */
  const { tenant } = useComputedConfig();
  const productImg = getProductImage(tenant);

  /**
   * DOM
   */
  return (
    <div
      className="w-screen overflow-hidden h-screen flex flex-col align-center justify-center text-primary"
      style={{ backgroundImage: `url(${productImg})` }}
    >
      <div className="my-auto flex flex-col justify-center gap-8">
        {/* header  */}
        <AppLogo variant="light" withTitle />
        {/* content  */}
        {children}
      </div>
      {/* footer  */}
      <AppFooterNonAuth
        linkCls={cx({
          'text-white': tenant !== 'Apex',
          'text-primary': tenant === 'Apex',
        })}
      />
    </div>
  );
};
