import { useSystemConfig } from '../contexts';

export function getCountryFlagImageUrl(code: string) {
  return `https://stablehouse-assets.s3.eu-west-1.amazonaws.com/country-flags/${code}.svg`;
}

export function useUrls() {
  const { publicUrl, webAppUrl, proTradingUrl } = useSystemConfig();
  const _helpUrl = `https://help.stablehouse.com`;

  return {
    webSiteUrl: publicUrl,
    webAppUrl,
    kycUrl: `${webAppUrl}/kyc/splash`,
    ratesUrl: `${publicUrl}/rates`,
    joinTheWaitlistUrl: `${publicUrl}/waitlist`,
    helpUrl: _helpUrl,
    helpNewRequest: `${_helpUrl}/hc/en-us/requests/new`,
    registerUrl: `${webAppUrl}/register`,
    webappContactUsUrl: `${webAppUrl}/contact-us`,
    faqUrl: _helpUrl,
    zendeskHowEarningWorksUrl: `${_helpUrl}/hc/en-us/articles/4407149467026-How-earning-works-on-StableHouse`,
    zendeskInterestPaidOutUrl: `${_helpUrl}/hc/en-us/articles/4407098611218-How-often-is-the-interest-paid-out-`,
    zendeskHouseFullUrl: `${_helpUrl}/hc/en-us/articles/4417376434834`,
    termsUrl: `${publicUrl}/terms-of-use/terms-of-use`,
    privacyUrl: `${publicUrl}/privacy-policy`,
    googleStore2FAUrl:
      'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    appStore2FAUrl:
      'https://apps.apple.com/us/app/google-authenticator/id388497605',
    mobileAppUrl: {
      download: `${publicUrl}/download-app`,
      ios: 'https://apps.apple.com/us/app/stablehouse-bitcoin-crypto/id1542077275',
      android:
        'https://play.google.com/store/apps/details?id=com.mobile.stablehouse.env.stablehouse.io',
    },
    twitterUrl: 'https://x.com/xbtogroup',
    linkedInUrl: 'https://www.linkedin.com/company/xbto',
    proTradingUrl,
    getCountryFlagImageUrl,
  };
}
