import {
  CSSProperties,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AppIcon,
  AppIconType,
  ChartSeriesType,
  ChartTimeRange,
  PriceCell,
  TradingViewChartData,
} from 'common';
import cx from 'classnames';
import { AppLoader } from '../app-loader';
import tvChartHtml from 'common/src/utils/charting/tv-chart.html';
import { SegmentedControl } from '../lib/segemented-control';

interface ChartContainerProps extends PropsWithChildren {
  isErrored: boolean;
  errorMessage: string;
  containerHeight?: number;
}

const ChartContainer: FC<ChartContainerProps> = ({
  children,
  isErrored,
  errorMessage,
  containerHeight = 410,
}) => {
  /**
   * DOM
   */
  const containerStyles: CSSProperties = { height: containerHeight };
  return (
    <div className="relative overflow-hidden" style={containerStyles}>
      {isErrored ? (
        <div className="flex items-center justify-center h-full text-sm text-primary bg-gray-100">
          {errorMessage}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

type ChartInnerProps = Pick<Props, 'data'> & { title: string };

const ChartInner: FC<ChartInnerProps> = ({ data, title }) => {
  /**
   * State
   */
  const [key, setKey] = useState(0);

  /**
   * Vars
   */
  const ref = useRef<HTMLIFrameElement | null>(null);

  /**
   * Methods
   */
  const updateChartData = useCallback(() => {
    if (!ref || !ref.current || !ref.current.contentWindow) {
      return;
    }
    if (!data || data.errored) {
      return;
    }
    ref.current.contentWindow.postMessage(
      JSON.stringify(data),
      location.origin
    );
    setKey(key + 1);
    ref.current.contentWindow.focus();
  }, [data, ref, setKey]);

  /**
   * Hooks
   */
  useEffect(() => {
    updateChartData();
  }, [data]);

  /**
   * DOM
   */
  return (
    <iframe
      ref={ref}
      key={key} // this KEY is essential to re-render axes of the chart
      title={title}
      width={'100%'}
      height={'100%'}
      style={{ padding: 0, margin: 0 }}
      // Note: we will keep the web app reading from common instead of
      // URL - https://stablehouse-assets.s3.eu-west-1.amazonaws.com/charting/tv-chart.html
      // for 2 reasons:
      // 1. easy chart development reasons
      // 2. to not forget allowed origins and whitelist to be setup for app.XYZ domains to allow loading cross origin into iframes
      srcDoc={tvChartHtml}
      src="https://stablehouse-assets.s3.eu-west-1.amazonaws.com/charting/tv-chart.html"
      onLoad={updateChartData}
    />
  );
};

interface Props {
  disabled: boolean;
  percentageChange?: number;
  data: TradingViewChartData;
  timeRanges: ChartTimeRange[];
  seriesTypes: ChartSeriesType[];
  onChange: (
    timeRange: ChartTimeRange | undefined,
    seriesType: ChartSeriesType | undefined,
    currencyCode?: string
  ) => void;
  containerHeight?: number;
}

export const TradingViewChart: FC<Props> = ({
  disabled,
  onChange,
  data,
  timeRanges,
  seriesTypes,
  percentageChange,
  containerHeight,
}) => {
  /**
   * Methods
   */
  const onTimeRangeChanged = useCallback(
    (value: ChartTimeRange) => {
      if (!data || !value) {
        return;
      }
      if (data.timeRange === value) {
        return;
      }
      onChange(value, data.seriesType, data.currencyCode);
    },
    [onChange, data]
  );

  const onSeriesTypeChanged = useCallback(
    (value: ChartSeriesType) => {
      if (!data || !value) {
        return;
      }
      if (data.seriesType === value) {
        return;
      }
      onChange(data.timeRange, value, data.currencyCode);
    },
    [data, onChange]
  );

  /**
   * DOM
   */
  const hasSeries = !!(data?.seriesType && seriesTypes.length > 1);
  return (
    <>
      <ChartContainer
        isErrored={!!data?.errored}
        errorMessage={`No charting data available`}
        containerHeight={containerHeight}
      >
        {!!percentageChange && (
          <div className="px-4 md:px-8">
            <PriceCell
              prefix={
                data?.timeRange ? <>{data?.timeRange}:&nbsp;</> : undefined
              }
              value={Number(percentageChange)}
            />
          </div>
        )}
        <div
          className={cx('flex text-primary px-4 md:px-8 pt-3', {
            'justify-between': hasSeries,
            'justify-end': !hasSeries,
          })}
        >
          {data?.timeRange && (
            <SegmentedControl<ChartTimeRange>
              disabled={disabled}
              type="button"
              itemPadding="p-2"
              containerPadding="p-2"
              item={data?.timeRange}
              items={timeRanges}
              itemTemplate={item => (
                <span className="capitalize font-semibold text-xs">{item}</span>
              )}
              onSelected={onTimeRangeChanged}
            />
          )}
          {hasSeries && (
            <SegmentedControl<ChartSeriesType>
              type="button"
              itemPadding="p-0"
              disabled={disabled}
              item={data.seriesType as ChartSeriesType}
              items={seriesTypes}
              itemTemplate={item => {
                const icon = `graph-${item.toLowerCase()}` as AppIconType;
                return (
                  <AppIcon
                    size={24}
                    cls="rounded-4 px-1"
                    icon={icon}
                    bg={'bg-transparent'}
                  />
                );
              }}
              onSelected={onSeriesTypeChanged}
            />
          )}
        </div>
        <ChartInner data={data} title={`${data?.currencyCode} pricing data`} />
      </ChartContainer>
    </>
  );
};
