import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useComputedConfig, useRecaptcha, useSystemConfig } from 'common';
import { OnboardingLayout } from '../../components/layouts/onboarding';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';
import { apiClient } from '../../api/client';
import { Button } from '../../components/forms/button';
import { KycTitle } from '../../components/kyc/kyc-title';
import { KycSubtitle } from '../../components/kyc/kyc-subtitle';
import { KycWizardLayout } from '../../components/kyc/kyc-wizard-layout';
import { AppIcon } from 'common';
import { useRenewedToken } from '../../hooks/use-authentication-hooks';
import { RequireAuthVerifyEmail } from '~/components/auth/require-auth-verify-email';

interface Props {
  disableRecaptcha?: boolean;
}

export const EmailVerificationPending: FC<Props> = (props: Props) => {
  /**
   * Store
   */
  const renewToken = useRenewedToken();
  const decodedToken = DataStore.useStoreState(s => s.user.decodedToken);

  /**
   * State
   */
  const [recaptchaCounter, setRecaptchaCounter] = useState(1);

  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { recaptchaWebappPublicKey } = useSystemConfig();
  const { tenant } = useComputedConfig();
  const { getRecaptchaToken } = useRecaptcha({
    key: recaptchaWebappPublicKey,
  });
  useEffect(() => {
    const interval = setInterval(() => {
      renewToken().then(user => {
        if (!user) {
          navigate(APP_ROUTES.NON_AUTH_HOME);
          return;
        }
        if (!user.requiresEmailVerification) {
          navigate(APP_ROUTES.AUTH_EMAIL_VERIFICATION_SUCCESSFUL);
        }
      });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!decodedToken) {
    // happens during logout
    return <div></div>;
  }
  return (
    <RequireAuthVerifyEmail>
      <OnboardingLayout testid="email-verification-pending" userIcon={false}>
        <KycWizardLayout title={''} loading={false}>
          <div className="flex items-center justify-center mb-8">
            <AppIcon icon="email" size={'lg'} />
          </div>
          <KycTitle>Great work. You’re almost there.</KycTitle>
          <div className="max-w-432 m-auto">
            <KycSubtitle muted>
              All you need to do now is check your email, <br />
              and follow the instructions we’ve sent to you at:
              <div className="mt-4">
                <strong className="text-primary"> {decodedToken?.email}</strong>
              </div>
            </KycSubtitle>
            <div className="text-gray-400 text-center mt-8 flex flex-col items-center">
              {recaptchaCounter === 1 ? (
                <>
                  Didn&apos;t receive the email?
                  <Button
                    role="button"
                    type="button"
                    className="app-button-outline mt-5"
                    onClick={async () => {
                      let recaptchaToken = '';
                      if (!props.disableRecaptcha) {
                        recaptchaToken = await getRecaptchaToken();
                      }

                      apiClient.resendVerificationEmail({
                        emailAddress: decodedToken?.email,
                        recaptchaToken: recaptchaToken,
                        tenant: tenant,
                      });
                      setRecaptchaCounter(recaptchaCounter + 1);
                    }}
                  >
                    Send again
                  </Button>
                </>
              ) : (
                <div>Sent! Please check your inbox.</div>
              )}
            </div>
          </div>
        </KycWizardLayout>
      </OnboardingLayout>
    </RequireAuthVerifyEmail>
  );
};
